/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { FormikProvider, useFormik } from 'formik';
import { Form } from 'formik-antd';

import { Table } from 'antd';

import { OfertasProdutosContext } from '../../../contexts/OfertasProdutosContext';
import { CalculadoraContext } from '../../../contexts/CalculadoraContext';

import CardFormProduto from '../../../components/CardFormProduto';
import Modal from '../../../components/Modal';
import { Modal as ModalFormik, Button as ButtonFormik } from 'antd';

import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';

import { currencyFormat } from '../../../utils/numberFormatter';

import { validationSchema } from './validationSchema';

import FormLayout from './FormLayout';
import Refin from './Refin';

import './styles.scss';

const initialValues = {
  convenioId: null,
  tabelaJurosId: null,
  diaRecebimento: null,
  dataVencimento: null,
  tipoCalculo: 0,
  valorSolicitado: null,
  floatValorSolicitado: null,
  saldoDevedor: null,
  valorSacar: null,
  floatValorSacar: null,
  valorParcela: null,
  floatValorParcela: null,
  produtoId: null,
  valorDebitoConcorrente: null,
  orgaoId: null,
  tipoCalculoVencimento:null
};

function OfertasProdutosForm() {
  const history = useHistory();
  const [submitButton, setSubmitButton] = useState(false);
  const [limiteParcelas, setLimiteParcelas] = useState([]);

  const {
    produto,
    simulacaoProposta,
    propostaId,
    avancarProposta,
    renda,
    tipoRenda,
    loading,
    setLoading,
    setProduto,
    setSimulacaoProposta,
    contratoSelecionado,
    saldoDevedor,
    setContratoSelecionado,
    planoSelecionado,
    setPlanoSelecionado,
    menorValorLimite,
    menorValorLimiteParcela,
    maiorValorLimite,
    maiorValorLimiteParcela,
    carregarProdutos,
    produtos,
    valorAjusteParcela,
    valorMaxParcelaCia
  } = useContext(OfertasProdutosContext);

  const [visibleModalAlerta, setVisibleModalAlerta] = useState(false);
  const [consultaPlaca, setConsultaPlaca] = useState(false);
  const [tabelasJuros, setTabelasJuros] = useState([]);

  const { calcularPlanos } = useContext(CalculadoraContext);

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      setLoading(true);
      const temp = JSON.parse(JSON.stringify(data));

      // Improvemnt 13240 - alteração provisória
      if (
        produto?.nome === 'CP Auto' &&
        produto?.valorMinimoContingencia &&
        temp.floatValorSolicitado < produto?.valorMinimoContingencia &&
        temp.tipoCalculo === 0 &&
        maiorValorLimite > 0
      ) {
        setLoading(false);
        Modal(
          '',
          `O valor solicitado deve ser maior ou igual a ${currencyFormat(produto?.valorMinimoContingencia)}.`,
          'warning',
        );
        return;
      }

      temp.id = propostaId;
      temp.produtoId = produto.id;
      temp.plano = planoSelecionado.plano;

      if (temp.produtoId !== 10 && planoSelecionado.valor) {
        planoSelecionado.valor = planoSelecionado?.valor?.replace(/\D/g, '');
        temp.prestacao = Number(
          parseFloat(planoSelecionado?.valor / 100).toFixed(2),
        );
      } else if (temp.produtoId === 10 && planoSelecionado.valorSolicitado) {
        planoSelecionado.valorSolicitado =
          planoSelecionado?.valorSolicitado?.replace(/\D/g, '');
        temp.prestacao = Number(
          parseFloat(
            planoSelecionado.valorSolicitado.replace(/\D/g, '') / 100,
          ).toFixed(2),
        );
      }

      temp.renda = renda;
      temp.tipoRenda = tipoRenda;

      temp.vencimento = temp.dataVencimento;
      delete temp.dataVencimento;

      if (!temp.vencimento) {
        temp.vencimento = moment().format('YYYY-MM-DD');
      }

      if (temp.produtoId === 10) {
        if (temp.tipoCalculo === 0) {
          temp.valor = temp.floatValorSacar + saldoDevedor;
          temp.prestacao = Number(
            parseFloat(
              planoSelecionado?.valorParcelaOuSacar?.replace(/\D/g, '') / 100,
            ).toFixed(2),
          );
        } else {
          temp.valor = temp.prestacao;
          temp.prestacao = temp.floatValorParcela;
        }
      } else {
        if (temp.tipoCalculo === 0) {
          temp.valor = temp.floatValorSolicitado;
        } else {
          temp.valor = temp.prestacao;
          temp.prestacao = temp.floatValorSolicitado;
        }
      }

      temp.valorSolicitado = temp.floatValorSolicitado;
      delete temp.floatValorSolicitado;

      delete temp.produto;

      if (temp.produtoId === 10 && contratoSelecionado.length === 0) {
        setLoading(false);
        return Modal(
          'Atenção',
          `É necessário selecionar ao menos um contrato para prosseguir com a simulação de Refinanciamento.`,
          'warning',
        );
      }

      temp.adicionais?.map((item) => {
        if (item.tipo !== 4) {
          return item?.valor ? (item.valor = String(item.valor)) : null;
        } else {
          return item?.valor ? item?.valor : null;
        }
      });

      if (!submitButton) {
        if (produto?.limitarComprometimento || temp.produtoId === 10) {
          if (temp.tipoCalculo === 1) {
            if (
              temp.floatValorParcela >
              parseFloat(maiorValorLimiteParcela?.toFixed(2)) ||
              temp.valorSolicitado >
              parseFloat(maiorValorLimiteParcela?.toFixed(2))
            ) {
              await Modal(
                'Atenção',
                `O valor da parcela selecionada excede o valor limite de ${currencyFormat(
                  maiorValorLimiteParcela,
                )}.`,
                'warning',
              );
              limparValorSolicitado();
              setLoading(false);
              return;
            } else if (
              (temp.floatValorParcela !== null &&
                temp.floatValorParcela <
                parseFloat(menorValorLimiteParcela?.toFixed(2))) ||
              (temp.valorSolicitado !== null &&
                temp.valorSolicitado <
                parseFloat(menorValorLimiteParcela?.toFixed(2)))
            ) {
              setVisibleModalAlerta(true);
              limparValorSolicitado();
              setLoading(false);
              return;
            }
          } else {
            if (
              temp.floatValorSacar > parseFloat(maiorValorLimite?.toFixed(2)) ||
              temp.valorSolicitado > parseFloat(maiorValorLimite?.toFixed(2))
            ) {
              await Modal(
                'Atenção',
                `O valor ${temp.produtoId === 10 ? 'liberado' : 'solicitado'
                } excede o valor limite de ${currencyFormat(
                  maiorValorLimite,
                )}.`,
                'warning',
              );
              limparValorSolicitado();
              setLoading(false);
              return;
            } else if (
              (temp?.floatValorSacar !== null &&
                temp.floatValorSacar <
                parseFloat(menorValorLimite?.toFixed(2))) ||
              (temp.ValorSolicitado !== null &&
                temp.valorSolicitado < parseFloat(menorValorLimite?.toFixed(2)))
            ) {
              setVisibleModalAlerta(true);
              limparValorSolicitado();
              setLoading(false);
              return;
            }
          }
        }
        const payloadSimulacao = {
          id: temp.id,
          propostaId: parseInt(propostaId),
          produtoId: temp.produtoId,
          convenioId: temp.convenioId,
          tabelaJurosId: temp.tabelaJurosId,
          vencimento: temp.vencimento,
          renda,
          tipoCalculo: temp.tipoCalculo,
        };

        if (temp.produtoId === 10) {
          if (temp.tipoCalculo === 0) {
            payloadSimulacao.valorContrato =
              temp.floatValorSacar + saldoDevedor;
          } else {
            payloadSimulacao.valorPrestacao = temp.floatValorParcela;
          }
        } else {
          if (temp.tipoCalculo === 0) {
            payloadSimulacao.valorContrato = temp.valorSolicitado;
          } else {
            payloadSimulacao.valorPrestacao = temp.valorSolicitado;
          }
        }

        await calcularPlanos(
          payloadSimulacao,
          temp.tipoCalculo,
          saldoDevedor,
        ).then((value) => {
          // Improvemnt 13240 - alteração provisória
          if (produto.nome === 'CP Auto' && temp.tipoCalculo === 1) {
            const existePrestacaoNoRangeFinalEsperado = value.find(parcela => parcela.plano === limiteParcelas[0].rangeFinal);

            if ((value.length === 0 || !existePrestacaoNoRangeFinalEsperado) && maiorValorLimite > 0) {
              Modal(
                '',
                `O valor da parcela não atinge o valor de operação mínima (${currencyFormat(produto?.valorMinimoContingencia)}) definido pela regra.`,
                'warning',
              );
              setLoading(false);
              return;
            }
          }
          if (valorAjusteParcela && temp.tipoCalculo === 0) {
            setSimulacaoProposta(
              value.filter((item) => item.prestacao <= valorAjusteParcela),
            );
          }else if(valorMaxParcelaCia  && temp.tipoCalculo === 0){
            setSimulacaoProposta(
              value.filter((item) => item.prestacao <= valorMaxParcelaCia),
            );

          }else {
            setSimulacaoProposta(value);
          }
          setSubmitButton(true);
          setLoading(false);
        });

        setLoading(false);
      } else {
        if (temp.tipoCalculo === 1) {
          temp.valorSolicitado = temp.valor;
        }

        if (!planoSelecionado.valor && !planoSelecionado.valorSolicitado) {
          Modal(
            'Atenção',
            'É necessário selecionar um plano para continuar o processo.',
            'warning',
          );
          setLoading(false);
        } else if (
          temp.tipoCalculo &&
          temp.valorSolicitado - parseFloat(saldoDevedor?.toFixed(2)) < 0
        ) {
          Modal(
            'Atenção',
            'Não é possível cadastrar uma proposta com valor solicitado inferior ao saldo devedor total.',
            'warning',
          );
          setLoading(false);
        } else {
          delete temp.floatValorSolicitado;
          delete temp.saldoDevedor;
          delete temp.valorSacar;
          delete temp.floatValorSacar;
          delete temp.valorParcela;
          delete temp.floatValorParcela;

          if (contratoSelecionado.length !== 0) {
            temp.contratoSelecionado = [];
            temp.contratoSelecionado.push(contratoSelecionado);
          }

          temp.contratosRefin =
            contratoSelecionado.length !== 0 ? temp.contratoSelecionado : null;

          const response = await avancarProposta(temp);

          setLoading(false);
          if (response?.data?.RBM?.Aprovado === false) {
            Modal(
              '',
              response?.data?.RBM?.Mensagem.toString().replace(
                / *, */g,
                '\n\n',
              ),
              'error',
              () => history.push(`/acompanhamento-proposta`),
              undefined,
              false,
            );
          } else if (response.success) {
            Modal('', 'Proposta atualizada com sucesso!', 'success', () => {
              history.push(`/proposta-credito/${response.data.id}`, {
                id: response.data.id,
              });
              if (
                response?.data?.respostaCrivo &&
                response?.data?.respostaCrivo?.mensagem
              ) {
                Modal('', response?.data?.respostaCrivo?.mensagem, 'warning');
              }
            });
          } else if (!response.reprovadoCrivo) {
            Modal(
              'Ocorreu 1 ou mais erros:',
              response.messages.toString().replace(/ *, */g, '\n\n'),
              'error',
            );
          }
        }
      }
    },
  });

  let columns = [];

  if (produto?.id === 10) {
    // Produto REFIN com Cálculo
    columns = [
      {
        title: 'Prazo',
        dataIndex: 'plano',
      },
      {
        title: 'Valor Solicitado',
        dataIndex: 'valorSolicitado',
      },
      {
        title: formik.values.tipoCalculo === 0 ? 'Parcela' : 'Liberado',
        dataIndex: 'valorParcelaOuSacar',
      },
    ];
  } else {
    // Restante dos Produtos
    columns = [
      {
        title: 'Prazo',
        dataIndex: 'plano',
      },
      {
        title: formik.values.tipoCalculo === 0 ? 'Parcela' : 'Solicitado',
        dataIndex: 'valor',
      },
    ];
  }

  const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

  const dataSimulacao = () => {
    const { tipoCalculo } = formik.values;
    let filtroSimulacaoProposta = [];
    const temLimitesCrivo = !!(
      menorValorLimite &&
      maiorValorLimite &&
      menorValorLimiteParcela &&
      maiorValorLimiteParcela
    );
    if (simulacaoProposta) {

      filtroSimulacaoProposta = simulacaoProposta
        .filter((item) => {
          if (!produto?.limitarComprometimento) {
            return item;
          }
          if (tipoCalculo === 0) {
            const limite = limiteParcelas.find(
              (el) =>
                item.plano >= el.rangeInicial && item.plano <= el.rangeFinal,
            );
            if (
              item.valor <= parseFloat(maiorValorLimite?.toFixed(2)) &&
              item.prestacao <= parseFloat(limite?.limiteParcela?.toFixed(2))
            ) {
              if (temLimitesCrivo) {
                return (
                  item.prestacao >= menorValorLimiteParcela &&
                  item.prestacao <= maiorValorLimiteParcela
                );
              }
              return item;
            }
          } else {
            const limite = limiteParcelas.find(
              (el) =>
                item.plano >= el.rangeInicial && item.plano <= el.rangeFinal,
            );
            if (
              item.solicitado <= parseFloat(maiorValorLimite?.toFixed(2)) &&
              item.valor <= parseFloat(limite?.limiteParcela?.toFixed(2))
            ) {
              if (temLimitesCrivo) {
                return (
                  item.solicitado >= menorValorLimite &&
                  item.solicitado <= maiorValorLimite
                );
              }
              return item;
            }
          }
          return false;
        })
        .map((value, index) => {
          return {
            key: index,
            plano: value.plano,
            valor: numberFormat(
              formik.values.tipoCalculo === 0
                ? value.prestacao
                : value.solicitado,
            ),
            valorSolicitado: numberFormat(
              (formik.values.tipoCalculo === 0
                ? value.valor
                : value.solicitado) + saldoDevedor,
            ),
            valorParcelaOuSacar: numberFormat(
              formik.values.tipoCalculo === 0
                ? value.prestacao
                : value.solicitado,
            ),
          };
        });
    }
    return filtroSimulacaoProposta;
  };

  const limparValorSolicitado = () => {
    formik.setFieldValue('valorSolicitado', '');
    formik.setFieldTouched('valorSolicitado', false);
    formik.setFieldValue('valorSacar', '');
    formik.setFieldTouched('valorSacar', false);
    formik.setFieldValue('valorParcela', '');
    formik.setFieldTouched('valorParcela', false);
  };

  useEffect(() => {
    if (!produto) formik.resetForm();
    formik.setFieldValue('produtoId', produto?.id);
    formik.setFieldValue('tipoCalculoVencimento', produto?.tipoCalculoVencimento);
    setContratoSelecionado([]);
  }, [produto]);

  useEffect(() => {
    dataSimulacao().length === 0 && setSubmitButton(false);
  }, [simulacaoProposta]);

  useEffect(() => {
    if (propostaId) {
      carregarProdutos(propostaId, false);
    }
  }, [consultaPlaca]);

  useEffect(() => {
    if (produto && produto?.nome === 'CP Auto') {
      const cpAutoPosPUmeMeio = produtos.find((p) => p.nome === 'CP Auto');

      produto.valorContingencia = cpAutoPosPUmeMeio.valorContingencia;
      produto.valorMinimoContingencia =
        cpAutoPosPUmeMeio.valorMinimoContingencia;

      if (cpAutoPosPUmeMeio?.tabelaJuros) {
        setTabelasJuros(cpAutoPosPUmeMeio?.tabelaJuros);
        formik.setFieldValue(
          'tabelaJurosId',
          cpAutoPosPUmeMeio?.tabelaJuros[0]?.id,
        );
      } else {
        Modal('', 'Nenhuma tabela de juros encontrada', 'warning');
      }
    }
  }, [produtos]);

  const isConsultaPlacaOuNaoCPAuto =
    (produto?.nome === 'CP Auto' && consultaPlaca) ||
    produto?.nome !== 'CP Auto';

  return (
    produto && (
      <CardFormProduto
        action={formik.handleSubmit}
        submitButton={submitButton}
        loading={loading}
        produto={produto}
        setProduto={setProduto}
        consultaPlaca={consultaPlaca}
      >
        <ModalFormik
          visible={visibleModalAlerta}
          centered
          footer={null}
          width={452}
          maskClosable={false}
          className="modal-warning"
          onCancel={() => setVisibleModalAlerta(false)}
        >
          <div className="icon">
            <IconExclamationCircle width={75} />
          </div>
          <div className="text-modal">
            O valor{' '}
            {formik?.values?.tipoCalculo === 0 ? `solicitado` : `da parcela`} é
            menor que o valor mínimo de{' '}
            {formik?.values?.tipoCalculo === 0
              ? currencyFormat(menorValorLimite)
              : currencyFormat(menorValorLimiteParcela)}
          </div>
          <div className="btn-area">
            <ButtonFormik
              variant="blue"
              onClick={() => setVisibleModalAlerta(false)}
              className="btn-confirm"
            >
              OK
            </ButtonFormik>
          </div>
        </ModalFormik>
        <div className="row">
          <div className={produto.id === 10 ? 'col-lg-7 pr-0' : 'col-lg-8'}>
            <FormikProvider value={formik}>
              <Form layout="vertical" className="form-ofertas-produtos">
                {produto.id !== 10 ? (
                  <FormLayout
                    formik={formik}
                    setSubmitButton={setSubmitButton}
                    produto={produto}
                    setLimiteParcelas={setLimiteParcelas}
                    consultaPlaca={consultaPlaca}
                    setConsultaPlaca={setConsultaPlaca}
                    tabelasJuros={tabelasJuros}
                    setTabelasJuros={setTabelasJuros}
                  />
                ) : (
                  <Refin
                    formik={formik}
                    setSubmitButton={setSubmitButton}
                    planoSelecionado={planoSelecionado}
                    setLimiteParcelas={setLimiteParcelas}
                  />
                )}
              </Form>
            </FormikProvider>
          </div>
          <div className={produto.id === 10 ? 'col-lg-5' : 'col-lg-4'}>
            {simulacaoProposta?.length > 0 &&
              dataSimulacao().length > 0 &&
              isConsultaPlacaOuNaoCPAuto ? (
              <div className="table-responsive">
                <Table
                  rowSelection={{
                    type: 'radio',
                    onSelect: (record) => {
                      setPlanoSelecionado(record);
                    },
                  }}
                  size={'small'}
                  pagination={false}
                  columns={columns}
                  dataSource={dataSimulacao()}
                  className="table-form table-striped"
                />
              </div>
            ) : (
              simulacaoProposta &&
              dataSimulacao().length === 0 && (
                <p style={{ color: 'red' }}>
                  Não existe oferta de parcela disponível para esse produto.
                </p>
              )
            )}
          </div>
        </div>
      </CardFormProduto>
    )
  );
}

export default OfertasProdutosForm;
