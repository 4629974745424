/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';

import { Field } from 'formik';
import { Form, Input, Select } from 'formik-antd';

import locale from 'antd/es/date-picker/locale/pt_BR';

import { OfertasProdutosContext } from '../../../contexts/OfertasProdutosContext';
import { CalculadoraContext } from '../../../contexts/CalculadoraContext';

import InputMoney from '../../../components/InputMoney';
import DatePicker from '../../../components/DatePicker';
import ModalComponent from '../../../components/Modal';

import { currencyFormat } from '../../../utils/numberFormatter';

import './styles.scss';
import { blockNonNumericChars } from '../../../utils/masks';
import { TipoCalculoVencimento } from '../../../utils/identificadoresFrontBack';

import { Tooltip } from 'antd';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { putConsultarPlacaVeiculo } from '../../../services/propostaService';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';


const { Option } = Select;
const dayToAdd = 'day';

const TooltipInfo = ({ text }) => (
  <Tooltip
    placement="top"
    className="tooltip-info"
    overlayClassName="tooltip-selecao-oferta-form"
    color="#435361"
    title={text}
  >
    <BulletInfo className="bullet-info-icon" />
  </Tooltip>
);

const dateFormat = 'DD/MM/YYYY';
let objetoLimitesParcela;
let objetoLimitesProduto;

function FormLayout({
  formik,
  setSubmitButton,
  produto,
  setLimiteParcelas,
  consultaPlaca,
  setConsultaPlaca,
  tabelasJuros,
  setTabelasJuros,
}) {
  const { values, setFieldTouched, setFieldValue, validateField } = formik;

  const { convenioId, tabelaJurosId, dataVencimento, tipoCalculo } = values;

  const {
    setSimulacaoProposta,
    renda,
    rendaFinal,
    propostaId,
    maiorValorLimiteParcela,
    setMaiorValorLimiteParcela,
    setLoading,
    maiorValorLimite,
    setMaiorValorLimite,
    setPlanoSelecionado,
    diaRecebimento,
    setSaldoDevedor,
    setMenorValorLimite,
    setMenorValorLimiteParcela,
    consultarValoresCrivo,
    setValorAjusteParcela,
    setDiaRecebimento,
    redirecionaAcompanhamentoProposta,
    setValorMaxParcelaCia
  } = useContext(OfertasProdutosContext);

  const {
    calcularValores,
    calcularParcelas,
    calcularVencimentos,
    calcularLimitesParcelas,
    calcularValorLimite,
    calcularValoresLimitesPrestacao,
  } = useContext(CalculadoraContext);

  const { checarPermissao } = useContext(ControleAcessoContext);

  const [convenios, setConvenios] = useState([]);
  const [tabelaJuros, setTabelaJuros] = useState(null);
  const [dadosAdicionais, setDadosAdicionais] = useState([]);
  const [valores, setValores] = useState([]);
  const [parcelas, setParcelas] = useState([]);
  const [valoresOuParcelas, setValoresOuParcelas] = useState([]);
  const [calculoVencimento, setCalculoVencimento] = useState(null);
  const [valorDebitoConcorrente, setvalorDebitoConcorrente] = useState(0);
  const [consultarValores, setConsultarValores] = useState(false);
  const [disabledValorSolicitado, setDisabledValorSolicitado] = useState(true);
  const [firstRun, setFirstRun] = useState(true);
  const [convenioAtual, setConvenioAtual] = useState(null);
  const [minimoProduto, setMinimoProduto] = useState(0);
  const [maximoProduto, setMaximoProduto] = useState(0);
  const [maxObjetoLimiteParcela, setMaxObjetoLimiteParcela] = useState(null);
  const [minObjetoLimiteParcela, setMinObjetoLimiteParcela] = useState(null);
  const [parcelasIniciais, setParcelasIniciais] = useState([]);
  const [valoresIniciais, setValoresIniciais] = useState([]);
  const [valorLimiteInicial, setValorLimiteInicial] = useState(null);
  const [statusPlaca, setStatusPlaca] = useState('');
  const [quantidadeErrosDadosCia, setQuantidadeErrosDadosCia] = useState(0);
  const [quantidadeMaximaErrosDadosCia, setQuantidadeMaximaErrosDadosCia] =
    useState(0);
  const [maxParcelaCia, setMaxParcelaCia] = useState(values);
  const [minParcelaCia, setMinParcelaCia] = useState(values);
  const acessoConsultarPlaca = checarPermissao (
    'botao',
    'botao.credito.ofertaProdutos.consultar',
    'Editar',
  );
  const acessoConsultarValores = checarPermissao (
    'botao',
    'botao.credito.ofertaProdutos.consultarValores',
    'Editar',
  );
  


  const handleClickValorSolicitado = () => {
    if (disabledValorSolicitado) {
      setFieldValue('dataVencimento', null);
      setFieldTouched('dataVencimento', true);
    }
  };

  const handleConsultaPlaca = async () => {
    if (!values?.placaVeiculo) {
      setFieldTouched('placaVeiculo');
      setStatusPlaca('error');
      return;
    }

    setLoading(true);
    const response = await putConsultarPlacaVeiculo(
      propostaId,
      values?.placaVeiculo,
    );
    if (response?.bloqueio) {
      ModalComponent('', response?.bloqueio, 'warning', redirecionaAcompanhamentoProposta)
    }
    setLoading(false);

    if (response.respostaCrivo && !response.respostaCrivo?.mensagem) {
      setConsultaPlaca(true);
    } else if (!response.respostaCrivo.erro) {
      ModalComponent('', response.respostaCrivo?.mensagem, 'warning');
      setStatusPlaca('warning');

      // NextTick
      // Sincronização das mensagens de warning junto da modal
      await new Promise((resolve) => setTimeout(resolve, 0));

      validateField('placaVeiculo');
    } else {
      ModalComponent('', response.respostaCrivo?.mensagem, 'warning');
    }
  };

  const disabledDate = (current) => {
    return (
      tabelaJuros &&
      !(
        current >=
          moment().add(tabelaJuros.carenciaMinima, 'd').startOf('day') &&
        current < moment().add(tabelaJuros.carenciaMaxima, 'd').startOf('day')
      )
    );
  };

  const validaDadosAdicionais = () => {
    return values.adicionais.find(
      (adicional) =>
        // eslint-disable-next-line
        adicional.valor == null || adicional.valor == '',
    );
  };

  const consultaValores = () => {
    if (!validaDadosAdicionais()) {
      setLoading(true);
      let listadadosAdicionais = dadosAdicionais.map((adicional, id) => {
        return {
          convenioDadosId: adicional.convenioDadosId,
          valor: values.adicionais[id].valor,
          convenioId: adicional.convenioId,
        };
      });

      consultarValoresCrivo(propostaId, {
        id: propostaId,
        operacao: {
          produtoId: produto.id,
          diaRecebimento: values?.diaRecebimento,
          tabelaJurosId: tabelaJurosId,
          vencimento: dataVencimento,
          convenioId: convenioId,
          adicionais: listadadosAdicionais,
        },
      }).then(async (response) => {
        if (
          response?.respostaCrivo?.unidadeCorreta !== undefined &&
          quantidadeErrosDadosCia < quantidadeMaximaErrosDadosCia
        ) {
          if (
            response?.respostaCrivo?.unidadeCorreta === false
          ) {
          const dadoAdicionalUCIndex = dadosAdicionais.findIndex(v => v.flagChaveUc)
          const dadoAdicionalUC = dadosAdicionais[dadoAdicionalUCIndex]
            ModalComponent(
              '',
              `${dadoAdicionalUC.nome} inválido, insira os dados corretos`,
              'warning',
              () => {
                setFieldValue(
                  `adicionais.${dadoAdicionalUCIndex}.valor`,
                  undefined,
                );
              }
            );
            setQuantidadeErrosDadosCia(quantidadeErrosDadosCia + 1);
            setLoading(false)
            return;
          }
        }

        // Improvement 9962
        if (response?.respostaCrivo?.valorAjusteParcela) {
          if (produto.nome === 'CDC Energia' && objetoLimitesParcela) {

            const limiteAjusteParcela = Math.min(maiorValorLimiteParcela, maxParcelaCia);
            if (
              response?.respostaCrivo?.valorAjusteParcela <
              limiteAjusteParcela
            ) {
              if (
                minParcelaCia >
                response.respostaCrivo.valorAjusteParcela
              ) {
                ModalComponent(
                  '',
                  'O valor da parcela ultrapassa a margem disponível, de acordo com as regras do produto',
                  'warning',
                );
                var limite = 0;
              } else {
                // eslint-disable-next-line
                var limite = response.respostaCrivo.valorAjusteParcela;
              }
            }
            else{
              limite = limiteAjusteParcela
            }

            objetoLimitesParcela?.forEach((item) => {
              item.limiteParcela = limite;
              item.maximoParcelaCrivo = limite;

              setValorAjusteParcela(limite);
              setMaiorValorLimiteParcela(limite);
            });
            setLimiteParcelas(objetoLimitesParcela);

            await calcularValorLimite(
              produto?.id,
              propostaId,
              convenioId,
              tabelaJurosId,
              dataVencimento,
              produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA ? convenioAtual?.valorContingencia : produto?.valorContingencia,
              renda,
              objetoLimitesParcela,
              objetoLimitesProduto,
              null,
              null,
              false,
            ).then((value) => {
              setMaiorValorLimite(value);
              setLoading(false);
              setConsultarValores(true);
            });
          } else {
            if (tabelaJuros.tipo === 1) {
              const valorAjusteParcela =
                response?.respostaCrivo?.valorAjusteParcela > maxParcelaCia
                  ? maxParcelaCia
                  : response?.respostaCrivo?.valorAjusteParcela;

              setValorAjusteParcela(valorAjusteParcela);

              if (minParcelaCia > response.respostaCrivo.valorAjusteParcela || response.respostaCrivo.valorAjusteParcela < minimoProduto) {
                ModalComponent(
                  '',
                  'O valor da parcela ultrapassa a margem disponível, de acordo com as regras do produto',
                  'warning',
                );
              }

              await calcularParcelas(
                tabelaJuros,
                produto.id,
                convenioId,
                tabelaJurosId,
                null,
                valorAjusteParcela,
              ).then(async (value) => {
                setValores(value.valores);
                setParcelas(value.parcelas);
                setConsultarValores(true);
                setLoading(false);
              });
            }
          }
        }

        //Improvement 20135
        else if(maxParcelaCia && minParcelaCia && (produto?.nome === 'CDC Energia' || produto?.nome === 'Energia')){
          setValorAjusteParcela(null);

          if(produto?.nome === 'CDC Energia'){

            const limite = Math.min(maiorValorLimiteParcela, maxParcelaCia);

            objetoLimitesParcela?.forEach((item) => {
              item.limiteParcela = limite;
              item.maximoParcelaCrivo = limite;
              item.minimoParcelaCrivo = minParcelaCia

              setMaiorValorLimiteParcela(limite);
            });
            setLimiteParcelas(objetoLimitesParcela);

            await calcularValorLimite(
              produto?.id,
              propostaId,
              convenioId,
              tabelaJurosId,
              dataVencimento,
              produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA ? convenioAtual?.valorContingencia : produto?.valorContingencia,
              renda,
              objetoLimitesParcela,
              objetoLimitesProduto,
              null,
              null,
              false,
            ).then((value) => {
              setMaiorValorLimite(value);
              setConsultarValores(true);
              setLoading(false);
            });
          }
          if(produto?.nome === 'Energia'){
            let valoresCia = []
            let parcelasCia = []
            let parcelasCiaIniciais = parcelasIniciais?.filter(item => item.valor <= maxParcelaCia && item.valor >= minParcelaCia)

            parcelasCiaIniciais.map(el => {
              const existeValorSolicitado = valoresCia?.find(item => item?.valor === el.solicitado)
              const existeValorParcela = parcelasCia?.find(item => item?.valor === el.valor)

              if(!existeValorSolicitado) {
                valoresCia.push({
                  valor: el.solicitado,
                  texto: currencyFormat(el.solicitado),
                })
              }
              if(!existeValorParcela) {
                parcelasCia.push({
                  valor: el.valor,
                  texto: currencyFormat(el.valor),
                  solicitado: el.solicitado
                })
              }
            });
            valoresCia.sort((a, b) => (a.valor > b.valor ? -1 : 1));
            parcelasCia.sort((a, b) => (a.valor > b.valor ? -1 : 1));
            setParcelas(parcelasCia)
            setValores(valoresCia)
            setLoading(false);
            setConsultarValores(true);


          }
        } 
        else {
          setValores(valoresIniciais);
          setParcelas(parcelasIniciais);
          setMaiorValorLimite(valorLimiteInicial);
          setValorAjusteParcela(null);

          // eslint-disable-next-line
          var limite = maxObjetoLimiteParcela;

          objetoLimitesParcela?.forEach((item) => {
            item.limiteParcela = limite;
            item.maximoParcelaCrivo = limite;

            setValorAjusteParcela(limite);
            setMaiorValorLimiteParcela(limite);
          });
          setLoading(false);
          setConsultarValores(true);
        }
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setFieldTouched('convenioID');
      // eslint-disable-next-line
      dadosAdicionais.map((_, id) => {
        setFieldTouched(`adicionais.${id}.valor`);
      });
    }
  };

  const saoConsecutivos = (sequencia) => {
    if (sequencia.length === 1 || sequencia.length === 0) {
      return false;
    }

    let direta = true;
    let inversa = true;
    for (let i = 0; i < sequencia.length - 1; i++) {
      if (parseInt(sequencia[i]) + 1 !== parseInt(sequencia[i + 1])) {
        direta = false;
      }
      if (parseInt(sequencia[i]) - 1 !== parseInt(sequencia[i + 1])) {
        inversa = false;
      }
    }

    return direta || inversa;
  };

  function possuiMaisQueDez(sequencia) {
    return sequencia.length > 10;
  }

  function saoIguais(sequencia) {
    return /^(\d)\1+$/.test(sequencia);
  }

  useEffect(() => {
    if (!firstRun) {
      setConsultarValores(false);
      setSubmitButton(false);
      setSimulacaoProposta(null);
    }
  }, [values?.adicionais, convenioId]);

  const limpaCampos = (limpaPlaca = true) => {
    setSimulacaoProposta(null);
    setPlanoSelecionado({});
    setSubmitButton(false);

    setDisabledValorSolicitado(
      produto?.tipoCalculoVencimento !== TipoCalculoVencimento.ENERGIA,
    );

    setFieldValue('valorSolicitado', '');
    setFieldValue('floatValorSolicitado', '');
    setFieldValue('dataVencimento', null);
    setFieldValue('diaRecebimento', null);
    setFieldValue('valorDebitoConcorrente', 0);
    setFieldTouched('valorSolicitado', false);
    setFieldTouched('dataVencimento', false);
    setFieldTouched('diaRecebimento', false);

    setDadosAdicionais([]);
    setMaiorValorLimite(null);
    setLimiteParcelas([]);

    // Verifica se o produto não possui convênios. Caso positivos seleciona as tabelas do produto
    // Caso contrário, limpas as variáveis
    if (produto?.tabelaJuros && produto?.tipoCalculoVencimento !== TipoCalculoVencimento.ENERGIA) {
      setTabelasJuros(produto?.tabelaJuros);
      setFieldValue('tabelaJurosId', produto?.tabelaJuros[0]?.id);
      setFieldTouched('tabelaJurosId', false);
    } else if (produto?.convenio?.length) {
      setTabelasJuros([]);
      setFieldValue('tabelaJurosId', null);
      setFieldTouched('tabelaJurosId', false);
      setLoading(false);
    } else {
      ModalComponent('', 'Nenhuma tabela de juros encontrada', 'warning');
    }

    // Carrega os convênios específicos do produto
    setConvenios(produto.convenio);

    // Verifica se o produto possui convênio único e não permite seleção e seleciona o convênio, caso positivo
    // Caso contrário, limpa a seleção de convênio
    if (produto?.convenio?.length === 1) {
      setFieldValue('convenioId', produto.convenio[0].id);
    } else {
      setFieldValue('convenioId', null);
    }

    if (
      produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA ||
      produto?.tipoCalculoVencimento ===
        TipoCalculoVencimento.CONSIGNADO_PRIVADO
    ) {
      setFieldValue('diaRecebimento', -1);
    }

    if (produto?.nome === 'CP Auto' && limpaPlaca) {
      setFieldValue('placaVeiculo', '');
    }

    setSaldoDevedor(0);

    setConsultaPlaca(false)
  };

  useEffect(() => {
    setFirstRun(false)
    limpaCampos()
    setValorAjusteParcela(null)


    if(produto?.nome === 'Débito em Conta' && produto?.orgao?.length === 1){
      setFieldValue('orgaoId', produto?.orgao[0]?.id);
    }
  }, [produto]);

  useEffect(() => {
    if (!consultaPlaca) {
      limpaCampos(false);
    }
  }, [consultaPlaca]);

  useEffect(() => {
    // Encontra os dados do convênio pelo id,

    const tempTabelaJuros = tabelasJuros?.find((el) => el.id === tabelaJurosId);

    if (convenios?.length)
      var tempConvenio = convenios.find((el) => el.id === convenioId);
    setConvenioAtual(tempConvenio);
    setMaxParcelaCia(tempConvenio?.valorMaximoParcela)
    setMinParcelaCia(tempConvenio?.valorMinimoParcela)
    setValorMaxParcelaCia(tempConvenio?.valorMaximoParcela)

    if(tempConvenio) {
      setQuantidadeMaximaErrosDadosCia(tempConvenio?.quantidadeErroCias || 0);
    }


    // Verifica se o convênio possui uma única tabela e, caso positivo, seleciona a tabela
    if (tempTabelaJuros?.tipo !== 0 || produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA) {
      if (tempConvenio?.tabelaJuros?.length === 1) {
        setTabelasJuros(tempConvenio.tabelaJuros);
        setFieldValue('tabelaJurosId', tempConvenio.tabelaJuros[0].id);
        // Caso contrário, verifica se há tabelas vinculadas ao convênio, preenche a lista de seleção e limpa a tabela selecionada
      } else if (tempConvenio?.tabelaJuros) {
        setTabelasJuros(tempConvenio.tabelaJuros);
        setFieldValue('tabelaJurosId', null);
        setFieldTouched('tabelaJurosId', false);
      }
    }

    // Verifica se o convênio possui dados adicionais, caso contrário, limpa a lista de dados adicionais
    let tempDadosAdicionais = [];
    if (tempConvenio?.convenioDados) {
      tempDadosAdicionais = tempConvenio.convenioDados.map((dadoAdicional) => ({
        ...dadoAdicional,
        valor: null,
      }));
    }
    setDadosAdicionais(tempDadosAdicionais);
    setFieldValue('adicionais', tempDadosAdicionais);

    // Limpa demais controles
    setSubmitButton(false);
    setSimulacaoProposta(null);
    setPlanoSelecionado({});
    setFieldTouched('adicionais', []);
    if (
      // eslint-disable-next-line
      produto?.tipoCalculoVencimento == TipoCalculoVencimento.ENERGIA &&
      convenioAtual?.consultarValorCrivo
    ) {
      setConsultarValores(false);
      // eslint-disable-next-line
      dadosAdicionais.map((_, id) => {
        setFieldValue(`adicionais.${id}.valor`, '');
      });
    }
  }, [convenioId]);

  useEffect(async () => {

    let tempTabelaJuros = tabelasJuros?.find((el) => el.id === tabelaJurosId);


    if (tempTabelaJuros) {

      // 20135- situação para proposta com duas cias com tabelas de mesmo id
      if (
        convenioAtual && 
        convenioAtual?.tabelaJuros && 
        tempTabelaJuros.tipo === 1 &&
        convenioAtual.tabelaJuros[0].id === tempTabelaJuros.id
      ) {
        tempTabelaJuros.tabelaJurosValores = convenioAtual.tabelaJuros[0].tabelaJurosValores
        setTabelaJuros(tempTabelaJuros)
      } else {
        setTabelaJuros(tempTabelaJuros);
      }

      if (tempTabelaJuros.tipo === 1) {
        calcularValores(tempTabelaJuros, produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA ? convenioAtual?.valorContingencia : produto?.valorContingencia).then(
          (value) => {
            setValores(value);
            setValoresIniciais(value);
          },
        );

        calcularParcelas(
          tempTabelaJuros,
          produto.id,
          convenioId,
          tabelaJurosId,
        ).then((value) => {
          setParcelas(value);
          setParcelasIniciais(value);
          setMaximoProduto(value[0].valor);
          setMinimoProduto(value[value.length - 1].valor);
        });
      }

      if (
        produto?.tipoCalculoVencimento === TipoCalculoVencimento.CARNE ||
        produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA
      ) {
        setFieldValue(
          'dataVencimento',
          moment()
            .add(
              tempTabelaJuros?.carenciaMedia || tempTabelaJuros?.carenciaMinima,
              dayToAdd,
            )
            .startOf('day'),
        );
      }
      // Caso o tipo de calculo de vencimento seja opções fixas (3), carrega a lista de opções de vencimento
      if (produto?.tipoCalculoVencimento === TipoCalculoVencimento.BOLETO) {
        setFieldValue('dataVencimento', null);
        calcularVencimentos(
          produto?.id,
          propostaId,
          convenioId,
          tabelaJurosId,
        ).then((value) => setCalculoVencimento(value));
      }

      if (
        produto?.tipoCalculoVencimento ===
        TipoCalculoVencimento.CONSIGNADO_PRIVADO
      ) {
        setFieldValue('dataVencimento', null);
        calcularVencimentos(
          produto?.id,
          propostaId,
          convenioId,
          tabelaJurosId,
        ).then((value) => {
          setCalculoVencimento(value);
          setFieldValue('dataVencimento', value[0]);
        });
      }

      setFieldTouched('dataVencimento', false);
    }

    setSubmitButton(false);
    setSimulacaoProposta(null);
    setPlanoSelecionado({});

    if (
      // eslint-disable-next-line
      produto?.tipoCalculoVencimento == TipoCalculoVencimento.ENERGIA &&
      convenioAtual?.consultarValorCrivo
    ) {
      setConsultarValores(false);
      // eslint-disable-next-line
      dadosAdicionais.map((_, id) => {
        setFieldValue(`adicionais.${id}.valor`, '');
      });
    }
  }, [tabelaJurosId, convenioAtual]);

  useEffect(() => {
    if (produto?.tipoCalculoVencimento === 5) {
      setFieldValue('dataVencimento', null);
      calcularVencimentos(
        produto?.id,
        propostaId,
        convenioId,
        tabelaJurosId,
        values?.diaRecebimento,
      ).then((data) => {
        if (data !== undefined) {
          setFieldValue('dataVencimento', data[0]);
        }
      });

      setFieldTouched('dataVencimento', false);
    }
  }, [values?.diaRecebimento]);

  useEffect(() => {
    if(values.orgaoId){
      let arrayDiaRecebimento = produto?.orgao?.filter(item =>item.id === values.orgaoId)[0]?.diaRecebimento
      setDiaRecebimento(arrayDiaRecebimento)
    }
  }, [values?.orgaoId]);

  useEffect(() => {
    setValoresOuParcelas(tipoCalculo ? parcelas : valores);

    setFieldValue('valorSolicitado', '');
    setFieldValue('floatValorSolicitado', '');
    setFieldTouched('valorSolicitado', false);

    setSubmitButton(false);
    setSimulacaoProposta(null);
    setPlanoSelecionado({});
  }, [valores, parcelas, tipoCalculo]);

  useEffect(async () => {
    setSubmitButton(false);
    setSimulacaoProposta(null);
    setPlanoSelecionado({});
    setLoading(true);

    const abateSomenteLimiteParcela =
      produto?.tipoCalculoVencimento === TipoCalculoVencimento.DEBITO_EM_CONTA;

    if (dataVencimento && tabelaJuros?.tipo !== 1) {

      await calcularValoresLimitesPrestacao(
        produto?.id,
        propostaId,
        convenioId,
        tabelaJurosId,
        dataVencimento,
        produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA ? convenioAtual?.valorContingencia : produto?.valorContingencia,
        renda,
      )
        .then((value) => {
          objetoLimitesParcela = [];
          objetoLimitesProduto = value;
        })
        .catch(() => {
          setLoading(false);
        });

      await calcularLimitesParcelas(
        renda,
        produto?.id,
        propostaId,
        tabelaJurosId,
        dataVencimento,
        objetoLimitesProduto,
        null,
        null,
        produto?.valorRendaComprometida,
        valorDebitoConcorrente,
        abateSomenteLimiteParcela,
        produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA  && maxParcelaCia
      )
        .then((response) => {
          objetoLimitesParcela = response.objetoLimitesParcela;
          setMaxObjetoLimiteParcela(response.maiorValorLimiteParcela);

          setMinObjetoLimiteParcela(
            Math?.min(
              response?.objetoLimitesParcela?.map(
                (item) => item?.minimoParcelaCrivo,
              ),
            ),
          );

          let tempTabelaJurosValores = tabelaJuros?.tabelaJurosValores;

          if (
            objetoLimitesParcela[1]?.limiteParcela === 0 ||
            objetoLimitesParcela[1] === undefined
          ) {
            // se só possui um bloco de range com limite de parcela retornado pelo Crivo
            // retorna o menor entre o range final do Crivo e o maior plano possivel da tabela de juros selecionada
            objetoLimitesParcela[0].rangeFinal = Math.min(
              objetoLimitesParcela[0].rangeFinal,
              tempTabelaJurosValores.reduce((prev, current) => {
                return prev.plano > current.plano ? prev : current;
              }).plano,
            );
          }

          if (objetoLimitesParcela[0]?.minimoParcelaCrivo) {
            setMenorValorLimiteParcela(
              objetoLimitesParcela[0].minimoParcelaCrivo,
            );
          }

          setLimiteParcelas(objetoLimitesParcela);

          if (response.maiorValorLimiteParcela >= 0) {
            setMaiorValorLimiteParcela(response.maiorValorLimiteParcela);
          }
        })
        .catch(() => {
          setLoading(false);
        });

      await calcularValorLimite(
        produto?.id,
        propostaId,
        convenioId,
        tabelaJurosId,
        dataVencimento,
        produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA ? convenioAtual?.valorContingencia : produto?.valorContingencia,
        renda,
        objetoLimitesParcela,
        objetoLimitesProduto,
        null,
      )
        .then((value) => {
          setMaiorValorLimite(value);
          // Improvemnt 13240 - alteração provisória
          if (value === 0 && produto.nome === 'CP Auto') {
            objetoLimitesParcela[0].limiteParcela = 0;
          }

          setValorLimiteInicial(value);
        })
        .catch(() => {
          setLoading(false);
        });

      if (
        objetoLimitesParcela[0].minimoParcelaCrivo &&
        objetoLimitesParcela[0].maximoParcelaCrivo
      ) {
        await calcularValorLimite(
          produto?.id,
          propostaId,
          convenioId,
          tabelaJurosId,
          dataVencimento,
          produto?.tipoCalculoVencimento === TipoCalculoVencimento.ENERGIA ? convenioAtual?.valorContingencia : produto?.valorContingencia,
          renda,
          objetoLimitesParcela,
          objetoLimitesProduto,
          null,
          null,
          true,
        )
          .then((value) => {
            setMenorValorLimite(value);
          })
          .catch(() => {
            setLoading(false);
          });

        const adicionalPercentualParcela =
          produto?.adicionalPercentualParcela / 100 + 1 ?? 1;

        objetoLimitesParcela?.forEach((item) => {
          if (
            item.minimoParcelaCrivo === item.limiteParcela &&
            adicionalPercentualParcela
          ) {
            item.limiteParcela *= adicionalPercentualParcela;
          }
        });

        if (objetoLimitesParcela) {
          const maiorValorMaximoParcela = Math?.max(
            ...objetoLimitesParcela?.map((item) => item?.limiteParcela),
          );

          // Improvemnt 13240 - alteração provisória
          if (maiorValorMaximoParcela === 0 && produto.nome === 'CP Auto') {
            objetoLimitesParcela[0].limiteParcela = 0;
          }

          setMaiorValorLimiteParcela(maiorValorMaximoParcela);
        }
      }

     
    }

    setDisabledValorSolicitado(!dataVencimento);

    setLoading(false);
    setFieldValue('valorSolicitado', '');
    setFieldValue('floatValorSolicitado', '');
    setFieldTouched('valorSolicitado', false);
  }, [dataVencimento, rendaFinal, valorDebitoConcorrente]);

  useEffect(() => {

    setFieldValue('valorSolicitado', '');
    setFieldValue('floatValorSolicitado', '');
    setFieldTouched('valorSolicitado', false);

  }, [consultarValores]);

  const isConsultaPlacaOuNaoCPAuto =
    produto?.nome !== 'CP Auto' ||
    (produto?.nome === 'CP Auto' && consultaPlaca);

  return (
    <div className="row">
      <div className="col-lg-6">
        {produto?.convenioTipo?.permiteSelecao && (
          <div className="form-row">
            <div className="col-lg-12">
              <Form.Item name="convenioId" label="Empresa Conveniada">
                <Select
                  id="convenioId"
                  name="convenioId"
                  placeholder="Empresa Conveniada"
                  disabled={!produto?.convenioTipo?.permiteSelecao}
                >
                  {convenios.map((convenio) => (
                    <Option key={convenio.id} value={convenio.id}>
                      {convenio.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        )}
        {produto?.nome === 'CP Auto' && (
          <div className="form-row">
            <div className="col-lg-12">
              <Form.Item
                name="placaVeiculo"
                label="Placa"
                validate={(value) => {
                  let msg = '';

                  if (!value) {
                    msg = 'Placa é obrigatório';
                  }

                  if (value && statusPlaca === 'warning') {
                    msg = 'Placa inválida';
                  }

                  return msg;
                }}
                validateStatus={statusPlaca}
              >
                <Input
                  value={values?.placaVeiculo}
                  className="ant-input"
                  onChange={(e) => {
                    if (e.target.value.length <= 7) {
                      setFieldValue('placaVeiculo', e.target.value);
                    }

                    if (values?.placaVeiculo !== e.target.value) {
                      setConsultaPlaca(false);
                    }
                    setStatusPlaca('');

                    setFieldTouched('placaVeiculo', false);
                  }}
                  onBlur={async () => {
                    validateField('placaVeiculo');
                    setFieldTouched('placaVeiculo');

                    if (!values?.placaVeiculo) {
                      setStatusPlaca('error');
                    }
                  }}
                />
              </Form.Item>
            </div>
          </div>
        )}

        {checarPermissao (
          'botao',
          'botao.credito.ofertaProdutos.consultar',
          'Visualizar',
        ) && produto?.nome === 'CP Auto' && !consultaPlaca && (
          <div className="form-row">
            <div className="col-lg-12">
              <Button
                type="button"
                variant="blue"
                block
                onClick={handleConsultaPlaca}
                disabled={!acessoConsultarPlaca}
              >
                <Icon variant="search" />
                <span className="ml-2">Consultar</span>
              </Button>
            </div>
          </div>
        )}

        {!produto?.convenioTipo?.permiteSelecao &&
          convenios.length === 1 &&
          produto?.tipoCalculoVencimento !==
            TipoCalculoVencimento.DEBITO_EM_CONTA && (
            <div className="form-row">
              <div className="col-lg-12">
                <Form.Item name="convenioId" label="Empresa Conveniada">
                  <Input
                    value={convenios[0].nome}
                    className="ant-input"
                    disabled={true}
                  />
                </Form.Item>
              </div>
            </div>
          )}

        {tabelasJuros?.length > 1 && isConsultaPlacaOuNaoCPAuto && (
          <div className="form-row">
            <div className="col-lg-12">
              <Form.Item name="tabelaJurosId" label="Tabela">
                <Select
                  name="tabelaJurosId"
                  placeholder="Tabela de Juros"
                  onChange={() => {
                    setValoresOuParcelas([]);
                    setMaiorValorLimite(null);
                  }}
                >
                  {tabelasJuros.map((tabela) => (
                    <Option key={tabela.id} value={tabela.id}>
                      {tabela.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        )}

        {dadosAdicionais?.length > 0 &&
          dadosAdicionais?.map((dadoAdicional, idx) => (
            <div
              key={dadoAdicional.convenioDadosId}
              className="form-row ml-1"
              style={{
                borderLeft: '3px solid #263659',
                paddingLeft: 6,
              }}
            >
              {idx === 0 && (
                <p className="info-limite-parcela">
                  * Informações da Fatura de Energia
                </p>
              )}
              <div className="col-lg-12">
                <Form.Item
                  name={`adicionais.${idx}.valor`}
                  label={dadoAdicional?.nome}
                  hasFeedback
                >
                  {dadoAdicional?.tipo === 1 ? (
                    <Field
                      name={`adicionais.${idx}.valor`}
                      validate={(value) => {
                        // Caso haja a validacao de UCs
                        // não deve ser levado em consideracao a validacao
                        // de valor Falsy
                        if (
                          (typeof value == 'string' &&
                          !RegExp(dadoAdicional?.formato).test(value))
                        ) {
                          return dadoAdicional?.mensagem;
                        }
                      }}
                    >
                      {({ field }) => (
                        <Input
                          {...field}
                          defaultValue={dadoAdicional?.valor}
                          className="ant-input"
                          type="number"
                          onChange={(e) => {
                            setFieldValue(
                              `adicionais.${idx}.valor`,
                              e.target.value,
                            );
                          }}
                          onBlur={(e) => {
                            const valorPreenchido = e.target.value;
                            if (
                              convenioAtual?.consultarValorCrivo &&
                              dadoAdicional.flagChaveUc &&
                              quantidadeErrosDadosCia <
                              quantidadeMaximaErrosDadosCia
                            ) {
                              if (
                                saoConsecutivos(valorPreenchido)
                              ) {
                                ModalComponent(
                                  '',
                                  'Números consecutivos são considerados inválidos, insira os dados corretos.',
                                  'warning',
                                  () => {
                                    setFieldValue(
                                      `adicionais.${idx}.valor`,
                                      undefined,
                                    );
                                  }
                                );

                                setQuantidadeErrosDadosCia(
                                  quantidadeErrosDadosCia + 1,
                                );
                                return;
                              } else if (saoIguais(valorPreenchido)) {
                                ModalComponent(
                                  '',
                                  'Números repetidos são considerados inválidos, insira os dados corretos.',
                                  'warning',
                                  () => {
                                    setFieldValue(
                                      `adicionais.${idx}.valor`,
                                      undefined,
                                    );
                                  }
                                );

                                setQuantidadeErrosDadosCia(
                                  quantidadeErrosDadosCia + 1,
                                );
                                return;
                              } else if (possuiMaisQueDez(valorPreenchido) && convenioAtual?.flagVerificacaoUC) {
                                ModalComponent(
                                  '',
                                  'Dado inválido, quantidade de números superior a 10. Insira os dados corretos.',
                                  'warning',
                                  () => {
                                    setFieldValue(
                                      `adicionais.${idx}.valor`,
                                      undefined,
                                    );
                                  }
                                );

                                setQuantidadeErrosDadosCia(
                                  quantidadeErrosDadosCia + 1,
                                );
                                return;
                              }
                            }

                            if (
                              !RegExp(dadoAdicional?.formato).test(
                                valorPreenchido,
                              )
                            ) {
                              setFieldValue(`adicionais.${idx}.valor`, '');
                            }
                          }}
                          onKeyDown={blockNonNumericChars}
                        />
                      )}
                    </Field>
                  ) : dadoAdicional?.tipo !== 4 ? (
                    <Field
                      name={`adicionais.${idx}.valor`}
                      validate={(value) => {
                        if (!RegExp(dadoAdicional?.formato).test(value)) {
                          return dadoAdicional?.mensagem;
                        }
                      }}
                    >
                      {({ field }) => (
                        <Input
                          {...field}
                          defaultValue={dadoAdicional?.valor}
                          className="ant-input"
                          onBlur={(e) => {
                            const valorPreenchido = e.target.value;
                            if (
                              convenioAtual?.consultarValorCrivo &&
                              dadoAdicional.flagChaveUc &&
                              quantidadeErrosDadosCia <
                              quantidadeMaximaErrosDadosCia
                            ) {
                              if (
                                saoConsecutivos(valorPreenchido)
                              ) {
                                ModalComponent(
                                  '',
                                  'Números consecutivos são considerados inválidos, insira os dados corretos.',
                                  'warning',
                                );
                                setQuantidadeErrosDadosCia(
                                  quantidadeErrosDadosCia + 1,
                                );
                                setFieldValue(
                                  `adicionais.${idx}.valor`,
                                  undefined,
                                );

                                return;
                              } else if (saoIguais(valorPreenchido)) {
                                ModalComponent(
                                  '',
                                  'Números repetidos são considerados inválidos, insira os dados corretos.',
                                  'warning',
                                );
                                setQuantidadeErrosDadosCia(
                                  quantidadeErrosDadosCia + 1,
                                );
                                setFieldValue(
                                  `adicionais.${idx}.valor`,
                                  undefined,
                                );

                                return;
                              } else if (possuiMaisQueDez(valorPreenchido) && convenioAtual?.flagVerificacaoUC) {
                                ModalComponent(
                                  '',
                                  'Dado inválido, quantidade de números superior a 10. Insira os dados corretos.',
                                  'warning',
                                );
                                setQuantidadeErrosDadosCia(
                                  quantidadeErrosDadosCia + 1,
                                );
                                setFieldValue(
                                  `adicionais.${idx}.valor`,
                                  undefined,
                                );

                                return;
                              }
                            }

                            if (
                              !RegExp(dadoAdicional?.formato).test(
                                valorPreenchido,
                              )
                            ) {
                              setFieldValue(`adicionais.${idx}.valor`, '');
                            }
                          }}
                        />
                      )}
                    </Field>
                  ) : (
                    <Field
                      name={`adicionais.${idx}.valor`}
                      validate={(value) => {
                        if (value == null) return dadoAdicional?.mensagem;
                      }}
                    >
                      {({ field }) => (
                        <DatePicker
                          {...field}
                          onChange={(val) => {
                            setFieldValue(`adicionais.${idx}.valor`, val);
                          }}
                          format="DD/MM/YYYY"
                        />
                      )}
                    </Field>
                  )}
                </Form.Item>
              </div>
            </div>
          ))}

        {checarPermissao (
          'botao',
          'botao.credito.ofertaProdutos.consultarValores',
          'Visualizar',
        ) &&
          // eslint-disable-next-line
          produto?.tipoCalculoVencimento == TipoCalculoVencimento.ENERGIA &&
            convenioAtual?.consultarValorCrivo && (
              <div>
                <Button
                  variant="blue"
                  className="btn-consultar-valores"
                  onClick={() => {
                    consultaValores();
                  }}
                  disabled={!acessoConsultarValores}
                >
                  Consultar valores
                </Button>
              </div>
            )
        }
        {(produto?.tipoCalculoVencimento === TipoCalculoVencimento.CARNE ||
          (produto?.tipoCalculoVencimento ===
            TipoCalculoVencimento.CONSIGNADO_PRIVADO &&
            calculoVencimento) ||
          (produto?.tipoCalculoVencimento === TipoCalculoVencimento.BOLETO &&
            produto?.nome !== 'CP Auto' &&
            calculoVencimento) ||
          (produto?.nome === 'CP Auto' &&
            consultaPlaca &&
            calculoVencimento)) && (
          <div className="form-row">
            <div className="col-lg-12">
              <Form.Item name="dataVencimento" label="Data de Vencimento">
                {produto?.tipoCalculoVencimento ===
                  TipoCalculoVencimento.CARNE && (
                  <DatePicker
                    locale={locale}
                    name="dataVencimento"
                    format={dateFormat}
                    style={{ textAlign: 'right' }}
                    disabledDate={disabledDate}
                    defaultPickerValue={
                      tabelaJuros &&
                      moment()
                        .add(tabelaJuros?.carenciaMinima, 'd')
                        .startOf('day')
                    }
                  />
                )}
                {produto?.tipoCalculoVencimento ===
                  TipoCalculoVencimento.CONSIGNADO_PRIVADO &&
                  calculoVencimento && (
                    <DatePicker
                      locale={locale}
                      name="dataVencimento"
                      format={dateFormat}
                      disabledDate={disabledDate}
                      disabled={true}
                      className="dataVencimento"
                      defaultPickerValue={
                        tabelaJuros &&
                        moment()
                          .add(tabelaJuros?.carenciaMinima, 'd')
                          .startOf('day')
                      }
                    />
                  )}

                  {produto?.tipoCalculoVencimento ===
                    TipoCalculoVencimento.BOLETO &&
                    calculoVencimento && (
                      <Select name="dataVencimento" className="text-right">
                        {calculoVencimento
                          ?.filter(
                            (item) =>
                              typeof item !== 'undefined' && item !== null,
                          )
                          ?.map((item) => (
                            <Option key={item} value={item}>
                              <span className="text-right col-12">
                                {moment(item).format('DD/MM/YYYY')}
                              </span>
                            </Option>
                          ))}
                      </Select>
                    )}
                </Form.Item>
              </div>
            </div>
          )}


          {produto?.tipoCalculoVencimento === TipoCalculoVencimento.DEBITO_EM_CONTA && produto?.orgao?.length > 1 && (
            <div className="form-row">
              <div className="col-lg-12">
                <Form.Item name="orgaoId" label="Orgão">
                  <Select
                    name="orgaoId"
                    className="text-right"
                  >
                    {produto?.orgao?.map((item) => (
                      <Option key={item.id} value={item.id} >
                        <span style={{ textAlign: 'left' }} sclassName="text-right col-12">{item.nome}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}



        {produto?.tipoCalculoVencimento !== TipoCalculoVencimento.ENERGIA &&
          produto?.tipoCalculoVencimento !==
            TipoCalculoVencimento.CONSIGNADO_PRIVADO &&
          isConsultaPlacaOuNaoCPAuto && (
            <div className="form-row">
              <div className="col-lg-12">
                <Form.Item name="diaRecebimento" label="Dia Recebimento">
                  <Select
                    name="diaRecebimento"
                    className="text-right"
                    showSearch
                    disabled={produto?.tipoCalculoVencimento ===
                      TipoCalculoVencimento.DEBITO_EM_CONTA && produto?.orgao?.length > 1 && !values.orgaoId}
                  >
                    {diaRecebimento?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        <span className="text-right col-12">{item.nome}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}

        {produto?.tipoCalculoVencimento ===
          TipoCalculoVencimento.DEBITO_EM_CONTA && (
          <div className="form-row">
            <div className="col-lg-12">
              <Form.Item name="dataVencimento" label="Data Vencimento">
                <Input
                  className="input-debito-concorrente"
                  name="dataVencimento"
                  data-cy="vencimento"
                  disabled
                  value={
                    dataVencimento
                      ? moment(dataVencimento).format('DD/MM/YYYY')
                      : ''
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-12">
              <Form.Item
                name="valorDebitoConcorrente"
                label={
                  <>
                    Débitos Concorrentes
                    <TooltipInfo text="Serão considerados como débitos concorrentes todos os débitos automáticos no dia do pagamento." />
                  </>
                }
              >
                <InputMoney
                  onBlur={(e) => {
                    let formatDebitos = parseFloat(
                      e.target.value
                        .substring(3, e.target.value.length)
                        .replace(/\./g, '')
                        .replace(',', '.'),
                    );
                    setvalorDebitoConcorrente(formatDebitos);
                    setFieldValue('valorDebitoConcorrente', formatDebitos);
                  }}
                  name="valorDebitoConcorrente"
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={12}
                  value={valorDebitoConcorrente}
                />
              </Form.Item>
            </div>
          </div>
        )}
      </div>

      {(produto?.tipoCalculoVencimento !== TipoCalculoVencimento.ENERGIA ||
        (convenioAtual?.consultarValorCrivo && !convenioAtual?.consultarValorCrivo) ||
        consultarValores) &&
        isConsultaPlacaOuNaoCPAuto && (
          <div className="col-lg-6">
            <div className="form-row">
              <div className="col-lg-12 col-valor-solicitado">
                <Form.Item
                  name="valorSolicitado"
                  className={
                    disabledValorSolicitado &&
                    produto?.id !== 6 &&
                    'disabled-valor-solicitado'
                  }
                  label={
                    <SelectParcela
                      disabled={disabledValorSolicitado && produto?.id !== 6}
                    />
                  }
                  onClick={handleClickValorSolicitado}
                >
                  {tabelaJuros?.tipo === 0 && (
                    <Field name="valorSolicitado">
                      {({ field }) => (
                        <InputMoney
                          {...field}
                          className="ant-input input-valor-solicitado-oferta"
                          prefix="R$ "
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          maxLength={12}
                          disabled={
                            disabledValorSolicitado && produto?.id !== 6
                          }
                          onValueChange={(values) => {
                            setFieldValue(
                              'floatValorSolicitado',
                              values.floatValue,
                            );
                            setSubmitButton(false);
                            setSimulacaoProposta(null);
                            setPlanoSelecionado({});
                          }}
                        />
                      )}
                    </Field>
                  )}

                  {tabelaJuros?.tipo === 1 && (
                    <Select
                      className="select-valor-solicitado-oferta"
                      name="valorSolicitado"
                      disabled={disabledValorSolicitado && produto?.id !== 6}
                      placeholder="Valor"
                      style={{ textAlign: 'right' }}
                      onChange={(value) => {
                        setFieldValue(
                          'floatValorSolicitado',
                          parseFloat(value),
                        );
                        setSubmitButton(false);
                        setSimulacaoProposta(null);
                        setPlanoSelecionado({});
                      }}
                    >
                      {valoresOuParcelas?.map((item) => (
                        <Option
                          key={item.valor}
                          value={item.valor}
                          style={{ textAlign: 'right' }}
                        >
                          <span className="text-right col-12">
                            {item.texto}
                          </span>
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                {produto?.limitarComprometimento && tipoCalculo === 1 ? (
                  maiorValorLimiteParcela &&
                  objetoLimitesParcela?.length > 0 ? (
                    <p className="info-limite-parcela">
                      * Limites Parcelas:
                      <br />
                      {objetoLimitesParcela[0]?.limiteParcela > 0 ? (
                        <>
                          Até {objetoLimitesParcela[0]?.rangeFinal}X{' '}
                          {currencyFormat(
                            objetoLimitesParcela[0]?.limiteParcela,
                          )}{' '}
                        </>
                      ) : (
                        <>R$ 0,00</>
                      )}
                      <br />
                      {objetoLimitesParcela[1]?.limiteParcela > 0 && (
                        <>
                          De {objetoLimitesParcela[1]?.rangeInicial}X a{' '}
                          {objetoLimitesParcela[1]?.rangeFinal}X{' '}
                          {currencyFormat(
                            objetoLimitesParcela[1]?.limiteParcela,
                          )}
                        </>
                      )}
                      <br />
                    </p>
                  ) : (
                    <p className="info-limite-parcela">
                      * Limites Parcelas:
                      <br />
                      R$ 0,00
                    </p>
                  )
                ) : (
                  maiorValorLimite !== null && (
                    <p className="info-limite-parcela">
                      * Limite Valor {currencyFormat(maiorValorLimite)}
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default FormLayout;

const SelectParcela = ({ onChange, disabled }) => (
  <Select
    name="tipoCalculo"
    size="small"
    disabled={disabled}
    onChange={onChange}
    className="filled"
    style={{ fontWeight: 'bold', width: '150px', backgroundColor: '#cccccc' }}
    onClick={(e) => {
      e.preventDefault();
    }}
  >
    <Option value={0}>Valor solicitado</Option>
    <Option value={1}>Valor da parcela</Option>
  </Select>
);
