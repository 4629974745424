import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { Form, Field, Select } from 'formik-antd';
import { Checkbox, Modal, Popconfirm } from 'antd';
import InputMask from 'react-text-mask';

import { ReactComponent as RemoveIcon } from '../../../assets/images/icons/remove.svg';
import { ReactComponent as CaretDownFilled } from '../../../assets/images/icons/arrow-down-select.svg';

import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';

import { ReactComponent as IconCheck } from '../../../assets/images/icons/confirm.svg';
import { ReactComponent as IconNegative } from '../../../assets/images/icons/negative.svg';

import InputFormat from 'react-number-format';

import DatePicker from '../../../components/DatePicker';
import Button from '../../../components/CustomButton';
import InputMoney from '../../../components/InputMoney';
import SelectSearch from '../../../components/SearchSelect';

import { codigoBarrasMask } from '../../../utils/masks';
import { currencyFormat } from '../../../utils/numberFormatter';

import { getLinkImagem } from '../../../services/imagemService';

import './styles.scss';
import { convertBase64ToBlob } from '../../../utils/convertBase64toBlob';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import { isString } from 'lodash';

function HistoricoCiaFormDetails({
  row,
  contexto,
  removerFatura,
  validarClienteElegivel,
  editableField,
  values,
  debitosConveniada,
}) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileType, setFileType] = useState('application/pdf');
  const [situacao, setSituacao] = useState(row.situacao);
  const [tipo, setTipo] = useState(row.tipo);
  const [blobFile, setBlobFile] = useState(null);
  const [check, setCheck] = useState(false);
  const { Option } = Select;

  const {
    validaCompanhiaCampoLimiteParcela
  } = useContext(AnaliseMesaContext)

  const rightToLeftFormatter = (value) => {
    if (!Number(value)) return '';

    let valor = (parseInt(value) / 100).toFixed(2);

    return currencyFormat(valor);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    setBlobFile(null);
    setPreviewImage(null);
  };

  const handlePreview = async (file) => {
    if (file.existeComprovante) {
      if (file.link) {
        const anexo = await getLinkImagem(file.link);
        const { conteudo } = anexo;

        if (conteudo) {
          file.type = conteudo.match(
            /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
          )[1];

          setFileType(file.type);
          setPreviewImage(conteudo);
          setPreviewVisible(true);
        }
      } else if (file.url) {
        file.type = file.url.match(
          /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
        )[1];

        setFileType(file.type);
        setPreviewImage(file.url);
        setPreviewVisible(true);
      }
    } else {
      setPreviewVisible(true);
    }
  };

  const handleCheck = () => {
    if (!check) {
      const usuarioId = localStorage.getItem('userId');
      const login = localStorage.getItem('login');
      row.loginAnalista = login;
      row.analistaId = parseInt(usuarioId);
    } else {
      row.loginAnalista = null;
      row.analistaId = null;
    }
    atualizaFaturaValidada(row.id, 'analistaId');
    setCheck(!check);
  };

  useEffect(() => {
    // Caso o PDF tenha + de 1MB, será convertido para Blob, devido a limitação de certos browsers com base64
    const fileSizeInBytes = previewImage ? previewImage?.length * (3 / 4) : 0;
    const MAX_BROWSER_BASE64_SIZE = 1000000;
    if (
      fileType === 'application/pdf' &&
      previewImage &&
      fileSizeInBytes > MAX_BROWSER_BASE64_SIZE
    ) {
      const blobURL = convertBase64ToBlob(previewImage, fileType);
      setBlobFile(blobURL);
    } else {
      setBlobFile(null);
    }
  }, [previewImage, fileType]);

  useEffect(() => {
    setCheck(row.analistaId && row.loginAnalista ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const atualizaFaturaValidada = (id, key, isDate) => {
    if (!isString(id)){
      const debitoConveniada = contexto?.debitosConveniada?.find(
        (d) => d.id === row.id
      );

      if (!debitoConveniada.faturaValidada) return;

      if (
        debitoConveniada.faturaValidada &&
        ((isDate && row[key]?.format("YYYY-MM-DD") !== debitoConveniada[key]) ||
          (!isDate && row[key] !== debitoConveniada[key]))
      ) {
        row.faturaValidada = false;
      } else {
        row.faturaValidada = true;
      }
    } else {
      row.faturaValidada = false;
    }
  }

  return (
    <>
      <div className="row topo-aba">
        <div className="col-md-6 text-left">
          <Select
            value={row.participa}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => {
              row.participa = value;
              atualizaFaturaValidada(row.id, 'participa');
            }}
            dropdownMatchSelectWidth="true"
            className="select-status"
            disabled={
              !contexto?.bloqueiaAcessoSimultaneo
                ? !editableField('debitosConveniada.debito')
                : true
            }
          >
            <Option key={true} value={true}>
              <h5 className="info-status elegivel">
                <IconCheck /> Débito Considerado
              </h5>
            </Option>
            <Option key={false} value={false}>
              <h5 className="info-status nao-elegivel">
                <IconNegative /> Débito Ignorado
              </h5>
            </Option>
          </Select>
        </div>

        <div className="col-md-6">
          <Popconfirm
            title="Tem certeza?"
            onConfirm={() => {
              removerFatura(row);
            }}
          >
            <Button
              className="btn-remover-fatura"
              disabled={
                !contexto?.bloqueiaAcessoSimultaneo
                  ? !editableField('debitosConveniada.removerFatura')
                  : true
              }
            >
              <RemoveIcon />
              REMOVER FATURA
            </Button>
          </Popconfirm>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9">
          <div className="form-row d-flex align-items-end">
            <div className="col-lg-4">
              <Form.Item
                name="mesAnoReferencia"
                label="Mês/Ano de referência"
                hasFeedback
              >
                <DatePicker
                  className="datepicker-data-referencia-fatura"
                  name="mesAnoReferencia"
                  format="MM/YYYY"
                  placeholder="00/0000"
                  picker="month"
                  value={row.dataReferencia ? moment(row.dataReferencia, 'MM/YYYY') : null}
                  onChange={(_, stringDate) =>
                    {
                      row.dataReferencia = stringDate
                      atualizaFaturaValidada(row.id, 'dataReferencia');
                    }
                  }
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('debitosConveniada.referencia')
                      : true
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item name="vencimento" label="Data de vencimento">
                <DatePicker
                  className="datepicker-vencimento-fatura"
                  name="vencimento"
                  format="DD/MM/YYYY"
                  placeholder="00/00/0000"
                  value={moment(row.dataVencimento)}
                  onChange={(value) => {
                    row.dataVencimento = value;
                    atualizaFaturaValidada(row.id, 'dataVencimento', true);
                  }}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('debitosConveniada.vencimento')
                      : true
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item name="diasAtraso" label="Dias Atraso">
                <InputFormat
                  disabled={true}
                  className="ant-input"
                  decimalScale={2}
                  fixedDecimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                  maxLength={10}
                  style={{ textAlign: 'right', color: '#686B6D' }}
                  value={
                    Math.round(
                      moment
                        .duration(
                          moment().startOf('day') -
                          moment(row.dataVencimento).startOf('day'),
                        )
                        .asDays(),
                    ) > 0
                      ? Math.round(
                        moment
                          .duration(
                            moment().startOf('day') -
                            moment(row.dataVencimento).startOf('day'),
                          )
                          .asDays(),
                      )
                      : 0
                  }
                />
              </Form.Item>
            </div>
          </div>

          <div className="form-row d-flex align-items-end">
            <div className="col-lg-4">
              <Form.Item name="valorFatura" label="Valor">
                <Field name="valorFatura">
                  {({ field }) => (
                    <InputMoney
                      {...field}
                      className="ant-input"
                      prefix="R$ "
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      maxLength={12}
                      format={rightToLeftFormatter}
                      value={row.valor.toFixed(2)}
                      placeholder="R$ 0,00"
                      onValueChange={(values) => {
                        let valor = (values.floatValue || 0) / 100;
                        row.valor = valor;
                        atualizaFaturaValidada(row.id, 'valor');
                      }}
                      disabled={
                        !contexto?.bloqueiaAcessoSimultaneo
                          ? !editableField('debitosConveniada.valor')
                          : true
                      }
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item name="tipo" label="Tipo">
                <SelectSearch
                  name="tipo"
                  placeholder="Selecione"
                  showArrow={true}
                  suffixIcon={<CaretDownFilled />}
                  value={tipo}
                  onChange={(value) => {
                    row.tipo = value;
                    setTipo(value);
                    atualizaFaturaValidada(row.id, 'tipo');
                  }}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('debitosConveniada.tipo')
                      : true
                  }
                >
                  {contexto?.tipoDebito.map((tipo) => (
                    <Option value={tipo.id} key={tipo.id}>
                      {tipo.nome}
                    </Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item name="situacao" label="Situação">
                <SelectSearch
                  name="situacao"
                  placeholder="Selecione"
                  showArrow={true}
                  suffixIcon={<CaretDownFilled />}
                  value={situacao}
                  onChange={(value) => {
                    row.situacao = value;
                    setSituacao(value);
                    atualizaFaturaValidada(row.id, 'situacao');
                  }}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('debitosConveniada.situacao')
                      : true
                  }
                >
                  {contexto?.situacaoDebito.map((tipo) => (
                    <Option value={tipo.id} key={tipo.id}>
                      {tipo.nome}
                    </Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </div>
          </div>

          <div className="form-row d-flex align-items-end">
            <div className="col-lg-4">
              <Form.Item name="consumo" label="Consumo (kwh)">
                <Field name="consumo">
                  {({ field }) => (
                    <InputFormat
                      {...field}
                      className="ant-input"
                      decimalScale={2}
                      fixedDecimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      maxLength={10}
                      style={{ textAlign: 'right' }}
                      value={row.consumo}
                      onValueChange={(values) => {
                        row.consumo = values.floatValue;
                        atualizaFaturaValidada(row.id, 'consumo');
                      }
                      }
                      disabled={
                        !contexto?.bloqueiaAcessoSimultaneo
                          ? !editableField('debitosConveniada.consumo')
                          : true
                      }
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
            {validaCompanhiaCampoLimiteParcela &&
              <div className="col-lg-4">
                <Form.Item name="valorCalculo" label="V. Cálculo">
                  <Field name="valorCalculo">
                    {({ field }) => {
                      const existeDebitoComValorCalculo = !!debitosConveniada?.find(debito => debito.valorCalculo);

                      return <InputMoney
                        {...field}
                        className="ant-input"
                        prefix="R$ "
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        maxLength={12}
                        format={rightToLeftFormatter}
                        value={row.valorCalculo?.toFixed(2)}
                        placeholder="R$ 0,00"
                        onValueChange={(values) => {
                          let valor = (values.floatValue || 0) / 100;
                          row.valorCalculo = valor;
                          atualizaFaturaValidada(row.id, 'valorCalculo');
                        }}
                        disabled={
                          (!row.valorCalculo && existeDebitoComValorCalculo) || contexto?.bloqueiaAcessoSimultaneo ? true : !editableField('debitosConveniada.situacao')
                        }
                      />
                    }}
                  </Field>
                </Form.Item>
              </div>
            }
          </div>
        </div>

        <div className="col-lg-3 comprovante-pagamento">
          <label>Comprovante pagamento</label>
          <div className="box">
            <div className="picture-card">
              {row.existeComprovante ? (
                <>
                  <div
                    className="thumbnail icon-document ant-upload-list-item-info"
                    onClick={() => {
                      handlePreview(row)
                    }}
                  >
                    <a
                      class="ant-upload-list-item-thumbnail ant-upload-list-item-file"
                      href=" "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span
                        role="img"
                        aria-label="file"
                        class="anticon anticon-file"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="file"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            d="M534 352V136H232v752h560V394H576a42 42 0 01-42-42z"
                            fill="#e6f7ff"
                          ></path>
                          <path
                            d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM602 137.8L790.2 326H602V137.8zM792 888H232V136h302v216a42 42 0 0042 42h216v494z"
                            fill="#1890ff"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>

                  <Modal
                    visible={previewVisible}
                    footer={null}
                    className={
                      'modal-preview-upload ' +
                      fileType.replace('/', '') +
                      ' preview-analise-mesa-credito'
                    }
                    onCancel={handleCancel}
                  >
                    <div>
                      {fileType === 'application/pdf' && (
                        <div>
                          <object
                            data={(blobFile ?? previewImage) + '#toolbar=0'}
                            type={fileType}
                            width="100%"
                            height="100%"
                            aria-label="Upload File"
                          ></object>
                        </div>
                      )}
                      {fileType !== 'application/pdf' && (
                        <div style={{ height: '90%' }}>
                          <img src={previewImage} alt="Documento"></img>
                        </div>
                      )}
                    </div>
                  </Modal>
                </>
              ) : (
                <div className="clique-anexar">
                  <IconExclamationCircle width={39} />
                  <br />
                  Sem comprovante
                </div>
              )}
            </div>
          </div>

          <div className="checkbox-validado">
            <Checkbox
              checked={check}
              name="validado"
              disabled={
                !row.existeComprovante ||
                !editableField('historico.dadosHistoricoCia')
              }
              onChange={handleCheck}
            >
              Validado {check && `por: ${row.loginAnalista}`}
            </Checkbox>
          </div>
        </div>
      </div>
      <div className="form-row d-flex align-items-end">
        <div className="col-lg-12">
          <Form.Item name="codigoBarras" label="Código de barras">
            <Field name="codigoBarras">
              {({ field }) => (
                <InputMask
                  {...field}
                  mask={codigoBarrasMask}
                  placeholder="Ex.: 00000000000 0 00000000000 0 00000000000 0 00000000000 0"
                  className="ant-input"
                  value={row.codigoBarras}
                  onChange={(e) => {
                    row.codigoBarras = e.target.value;
                    atualizaFaturaValidada(row.id, 'codigoBarras');
                  }}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('debitosConveniada.codigoBarras')
                      : true
                  }
                />
              )}
            </Field>
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default HistoricoCiaFormDetails;
