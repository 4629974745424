import React, { useContext, useState, useEffect,useRef } from 'react';

import { Form, Field, Select, Checkbox } from 'formik-antd';

import InputMoney from '../../../components/InputMoney';
import InputPhone from '../../../components/InputPhone';
import TooltipAlterados from '../../../components/TooltipAlterados';
import SelectSearch from '../../../components/SearchSelect';
import Input from '../../../components/SimpleInput';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import validaPhone from '../../../utils/validaPhone';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import InputMask from 'react-text-mask';
import { numeroBeneficioMask } from '../../../utils/masks';
import TooltipComponent from '../../../components/tooltip';

import './styles.scss';

const { Option } = Select;

function ProfissionalForm({ values }) {
  const {
    contexto,
    editableField,
    validationField,
    highlightField,
    campoChecar,
    handleChangeChecados,
    getChecado,
    formik,
  } = useContext(AnaliseMesaContext);

  const [showTooltipEmpregoAtual, setShowTooltipEmpregoAtual] = useState(false);
  const empregoAtualInputRef = useRef();
  const { setFieldValue } = formik;

  const isOverflowing = () => {
    if (empregoAtualInputRef.current) {
      const width = empregoAtualInputRef.current.offsetWidth;
      const contentWidth = empregoAtualInputRef.current.scrollWidth;
      return contentWidth > width;
    }
  };

  useEffect(() => {
    const handleShowTooltip = () => {
      if (isOverflowing()) {
        setShowTooltipEmpregoAtual(true);
      } else {
        setShowTooltipEmpregoAtual(false);
      }
    }
    window.addEventListener('resize', handleShowTooltip);

    return () => window.removeEventListener('resize', handleShowTooltip);
  }, [values?.profissional]);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-4">
          <Form.Item
            name="profissional.empresa"
            label={
              <>
                <label>Empresa ou Órgão</label>
                {campoChecar('profissional.empresa') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('profissional.empresa')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('profissional.empresa')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('profissional.empresa') && <TooltipAlterados />}
              </>
            }
            className={
              highlightField('profissional.empresa') && `campo-alterado`
            }
            validate={(value) =>
              validationField(value, 'profissional.empresa', 'Empresa ou Órgão')
            }
            hasFeedback
          >
            <Input
              name="profissional.empresa"
              placeholder="Crefaz"
              disabled={!editableField('profissional.empresa')}
              value={values?.profissional?.empresa}
              onBlur={(e) =>
                setFieldValue('profissional.empresa', e.target.value)
              }
            />
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name="profissional.ocupacaoId"
            label={
              <>
                <label>Classificação Profissional</label>
                {campoChecar('profissional.ocupacaoId') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('profissional.ocupacaoId')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('profissional.ocupacaoId')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('profissional.ocupacaoId') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('profissional.ocupacaoId') && `campo-alterado`
            }
            validate={(value) =>
              validationField(
                value,
                'profissional.ocupacaoId',
                'Classificação Profissional',
              )
            }
            hasFeedback
          >
            <SelectSearch
              name="profissional.ocupacaoId"
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={!editableField('profissional.ocupacaoId')}
              optionFilterProp="children"
              suffixIcon={<IconArrowSelect />}
              onChange={(value) =>
                setFieldValue('profissional.ocupacaoId', value)
              }
              value={values?.profissional?.ocupacaoId}
              fillDefault={editableField(`profissional.ocupacaoId`)}
            >
              {contexto?.ocupacoes &&
                contexto?.ocupacoes.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name="profissional.profissaoId"
            label={
              <>
                <label>Profissão</label>
                {campoChecar('profissional.profissaoId') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('profissional.profissaoId')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('profissional.profissaoId')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('profissional.profissaoId') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('profissional.profissaoId') && `campo-alterado`
            }
            validate={(value) =>
              validationField(value, 'profissional.profissaoId', 'Profissão')
            }
            hasFeedback
          >
            <SelectSearch
              name="profissional.profissaoId"
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={!editableField('profissional.profissaoId')}
              optionFilterProp="children"
              suffixIcon={<IconArrowSelect />}
              showSearch
              onChange={(value) =>
                setFieldValue('profissional.profissaoId', value)
              }
              value={values?.profissional?.profissaoId}
              fillDefault={editableField(`profissional.profissaoId`)}
            >
              {contexto?.profissoes &&
                contexto?.profissoes.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name={values?.operacao?.produtoNome === "Consignado Privado" ? 'profissional.tempoEmpregoAtualCalculado' :'profissional.tempoEmpregoAtual'}
            label={
              <>
                <label>Tempo de emprego atual</label>
                {campoChecar('profissional.tempoEmpregoAtual') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('profissional.tempoEmpregoAtual')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('profissional.tempoEmpregoAtual')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('profissional.tempoEmpregoAtual') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('profissional.tempoEmpregoAtual') &&
              `campo-alterado`
            }
            validate={() => {
              const isConsignadoPrivado = values?.operacao?.produtoNome === "Consignado Privado";
              const fieldValue = isConsignadoPrivado 
                ? values?.profissional?.tempoEmpregoAtualCalculado 
                : values?.profissional?.tempoEmpregoAtual;
              
              return fieldValue ? undefined : "Obrigatório informar tempo de emprego atual";
            }}
            hasFeedback
          >
                {values?.operacao?.produtoNome === "Consignado Privado" ?
                
                <TooltipComponent
                    title={showTooltipEmpregoAtual && values?.profissional?.tempoEmpregoAtualCalculado}
                    placement="bottom"
                    className="tooltip-info rezise"
                    overlayClassName="tooltip-info"
                    color="#435361"
                  >
                    <div>
                      <input
                        ref={empregoAtualInputRef}
                        className={'ant-input'}
                        name="profissional.tempoEmpregoAtualCalculado"
                        disabled={true}
                        value={values?.profissional?.tempoEmpregoAtualCalculado}
                        onBlur={(e) => {
                          setFieldValue('profissional.tempoEmpregoAtualCalculado', e.target.value);
                        }}
                      />
                    </div>
                  </TooltipComponent>

                :

                 <SelectSearch
                    name="profissional.tempoEmpregoAtual"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabled={!editableField('profissional.tempoEmpregoAtual')}
                    optionFilterProp="children"
                    suffixIcon={<IconArrowSelect />}
                    showSearch
                    onChange={(value) =>
                      setFieldValue('profissional.tempoEmpregoAtual', value)
                    }
                    value={values?.profissional?.tempoEmpregoAtual}
                    fillDefault={editableField(`profissional.tempoEmpregoAtual`)}
                  >
                    {contexto?.tempoEmpregoAtual &&
                      contexto?.tempoEmpregoAtual.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                  </SelectSearch>
              }
          </Form.Item>
        </div>

        <div className="col-lg-4">
          <Form.Item
            name="profissional.telefoneRH"
            label={
              <>
                <label>Telefone RH da empresa</label>
                {campoChecar('profissional.telefoneRH') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('profissional.telefoneRH')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('profissional.telefoneRH')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('profissional.telefoneRH') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('profissional.telefoneRH') && `campo-alterado`
            }
            validate={(value) => {
              let msgError = '';

              msgError = validationField(
                value,
                'profissional.telefoneRH',
                'Telefone RH da Empresa',
              );

              if (value && !validaPhone(value)) {
                msgError = 'Telefone inválido';
              }

              return msgError;
            }}
            hasFeedback
          >
            <Field name="profissional.telefoneRH">
              {({ field }) => (
                <InputPhone
                  {...field}
                  placeholder="(00) 0000-0000"
                  disabled={!editableField('profissional.telefoneRH')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name="profissional.pisPasep"
            label={
              <>
                <label>PIS/PASEP</label>
                {campoChecar('profissional.pisPasep') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('profissional.pisPasep')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('profissional.pisPasep')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('profissional.pisPasep') && (
                  <TooltipAlterados />
                )}
              </>
            }
            validate={(value) =>
              validationField(value, 'profissional.pisPasep', 'PIS/PASEP')
            }
            className={
              highlightField('profissional.pisPasep') && `campo-alterado`
            }
            hasFeedback
          >
            <Input
              name="profissional.pisPasep"
              disabled={!editableField('profissional.pisPasep')}
              value={values?.profissional?.pisPasep}
              onBlur={(e) =>
                setFieldValue('profissional.pisPasep', e.target.value)
              }
              type="number"
            />
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name="profissional.renda"
            label={
              <>
                <label>Renda</label>
                {campoChecar('profissional.renda') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('profissional.renda')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('profissional.renda')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('profissional.renda') && <TooltipAlterados />}
              </>
            }
            className={highlightField('profissional.renda') && `campo-alterado`}
            validate={(value) =>
              validationField(value, 'profissional.renda', 'Renda')
            }
            hasFeedback
          >
            <Field name="profissional.renda">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  maxLength={12}
                  style={{ textAlign: 'right' }}
                  disabled={!editableField('profissional.renda')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name="profissional.outrasRendas"
            label={
              <>
                <label>Outras rendas</label>
                {campoChecar('profissional.outrasRendas') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('profissional.outrasRendas')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('profissional.outrasRendas')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('profissional.outrasRendas') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('profissional.outrasRendas') && `campo-alterado`
            }
            validate={(value) =>
              validationField(
                value,
                'profissional.outrasRendas',
                'Outras Rendas',
              )
            }
            hasFeedback
          >
            <Field name="profissional.outrasRendas">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  maxLength={12}
                  style={{ textAlign: 'right' }}
                  disabled={!editableField('profissional.outrasRendas')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name="profissional.tipoOutrasRendas"
            label={
              <>
                <label>Tipo outras rendas</label>
                {campoChecar('profissional.tipoOutrasRendas') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('profissional.tipoOutrasRendas')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('profissional.tipoOutrasRendas')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('profissional.tipoOutrasRendas') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('profissional.tipoOutrasRendas') &&
              `campo-alterado`
            }
            validate={(value) =>
              validationField(
                value,
                'profissional.tipoOutrasRendas',
                'Tipos Outras Rendas',
              )
            }
            hasFeedback
          >
            <Input
              name="profissional.tipoOutrasRendas"
              disabled={!editableField('profissional.tipoOutrasRendas')}
              value={values?.profissional?.tipoOutrasRendas}
              onBlur={(e) =>
                setFieldValue('profissional.tipoOutrasRendas', e.target.value)
              }
            />
          </Form.Item>
        </div>
        {contexto?.dadosOrgao?.length > 0 &&
          values?.profissional?.orgaoDados.length > 0 &&
          (contexto?.dadosOrgao).map((dadoAdicional, i) => (
            <div
              className={
                dadoAdicional?.tipo === 1
                  ? `col-md-4 col-lg-4 col-xl-8`
                  : `col-md-3 col-lg-3 col-xl-4`
              }
              key={i}
            >
              <Form.Item
                name={`profissional.orgaoDados.${i}.valor`}
                label={dadoAdicional?.nome}
                hasFeedback
              >
                {dadoAdicional?.tipo === 1 ? (
                  <Field
                    name={`profissional.orgaoDados.${i}.valor`}
                    validate={(value) => {
                      if (value == null) return dadoAdicional?.mensagem;
                    }}
                  >
                    {({ field }) => (
                      <SelectSearch
                        {...field}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        dropdownAlign={{
                          overflow: { adjustY: 0 },
                        }}
                        className="select-dados-convenio"
                        optionFilterProp="children"
                        suffixIcon={<IconArrowSelect />}
                        placeholder="Selecione"
                        showSearch
                        disabled={!editableField('profissional.orgaoDados')}
                        style={{ textAlign: 'left' }}
                        value={parseInt(
                          values?.profissional?.orgaoDados[i]?.valor,
                        )}
                        onChange={(value) => {
                          setFieldValue(
                            `profissional.orgaoDados.${i}.valor`,
                            value,
                          );
                          setFieldValue(
                            `profissional.orgaoDados.${i}.orgaoDadosId`,
                            dadoAdicional.id,
                          );
                          setFieldValue(
                            `profissional.orgaoDados.${i}.id`,
                            values?.profissional?.orgaoDados[i]?.id,
                          );
                        }}
                      >
                        {dadoAdicional?.contexto?.map((item) => (
                          <Option key={item.id} value={item.id}>
                              {item.nome}
                          </Option>
                        ))}
                      </SelectSearch>
                    )}
                  </Field>
                ) : (
                  <Field
                    name={`profissional.orgaoDados.${i}.valor`}
                    validate={(value) => {
                      if (!RegExp(dadoAdicional?.formato).test(value))
                        return dadoAdicional?.mensagem;
                    }}
                  >
                    {({ field }) => (
                      <InputMask
                        {...field}
                        placeholder=""
                        className="ant-input select-dados-convenio"
                        mask={numeroBeneficioMask}
                        disabled={!editableField('profissional.orgaoDados')}
                        value={values?.profissional?.orgaoDados[i]?.valor}
                        onChange={(value) => {
                          setFieldValue(
                            `profissional.orgaoDados.${i}.valor`,
                            value.target.value,
                          );
                          setFieldValue(
                            `profissional.orgaoDados.${i}.orgaoDadosId`,
                            dadoAdicional.id,
                          );
                          setFieldValue(
                            `profissional.orgaoDados.${i}.id`,
                            values?.profissional?.orgaoDados[i]?.id,
                          );
                        }}
                      ></InputMask>
                    )}
                  </Field>
                )}
              </Form.Item>
            </div>
          ))}
      </div>
    </>
  );
}

export default ProfissionalForm;
