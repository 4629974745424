import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';

import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  getPropostaMesaCredito,
  gravarOperatividade,
  getBlocklistMesaCredito,
  getPropostaMensagens,
  getPropostaMensagensParcial,
  sendPropostaMensagem,
  atualizarMesaCredito,
  sendPropostaAnotacao,
  getProcessamentoCrivo,
  getProcessamentoCrivoCompleto,
  listagemMotivosDecisao,
  listagemMotivosEtapas,
  listaAnalista,
  reenviarSMS,
  buscarHistoricoAcoesProposta,
  buscarHistoricoProposta,
  postSimulacaoLimiteParcela,
} from '../services/propostaService';

import { carregarImagem, getImagem } from '../services/imagemService';

import {
  getAbaContato,
  getHistoricoReanalise,
  postHistoricoProposta,
} from '../services/analiseMesaService';

import {
  listarPaises,
  listarCidades,
  listarUfs,
  buscarEndereco,
} from '../services/enderecoService';

import { fetchHistoricoChamada } from '../services/analiseMesaService';

import Modal from '../components/Modal';

import validaPhone from '../utils/validaPhone';

import validaCPF from '../utils/validaCpf';

import moment from 'moment';
import ModalHtmlComponent from '../components/ModalHtml';
import { validationSchema } from '../pages/AnaliseMesaCredito/validationSchema';
import { CalculadoraContext } from './CalculadoraContext';
import { isString } from 'lodash';
import { currencyFormat } from '../utils/numberFormatter';

export const AnaliseMesaContext = createContext({
  proposta: {},
  contexto: {},
  formik: {},
  estados: [],
  cidades: [],
  disableDecisao: false,
  getPropostaMesaCredito: async (f) => f,
  carregarImagem: async (f) => f,
  setProposta: (f) => f,
  setDisableDecisao: (f) => f,
});

let objetoLimitesParcela;
let objetoLimitesProduto;

function AnaliseMesaProvider({ children }) {
  const {
    calcularLimitesParcelas,
    calcularValorLimite,
    calcularValoresLimitesPrestacao,
  } = useContext(CalculadoraContext);

  const [proposta, setProposta] = useState(null);
  const [showModalDigitro, setShowModalDigitro] = useState(false);
  const [showModalAgitel, setShowModalAgitel] = useState(false);
  const [telefone, setTelefone] = useState(null);
  const [tipoTelefone, setTipoTelefone] = useState(null);
  const [cepInvalido, setCepInvalido] = useState(false);

  const [operatividade, setOperatividade] = useState({});
  const [isOnline, setOnline] = useState(null);

  const [chamadaId, setChamadaId] = useState(null);
  const [timer, setTimer] = useState(0);
  const [reset, setReset] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const increment = useRef(null);
  const [contexto, setContexto] = useState({});
  const [disableDecisao, setDisableDecisao] = useState(false);
  const [status, setStatus] = useState('conexao');
  const [isModalBlacklistVisible, setIsModalBlacklistVisible] = useState(false);
  const [isModalMotivosVisible, setIsModalMotivosVisible] = useState(false);
  const [dadosModalBlocklist, setDadosModalBlocklist] = useState([]);
  const [mensagens, setMensagens] = useState([]);
  const [anotacoes, setAnotacoes] = useState([]);
  const [refreshCentralComunicacao, setRefreshCentralComunicacao] = useState(5);
  const [ultimaMensagem, setUltimaMensagem] = useState(0);
  const [arquivos, setArquivos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [cidadesNaturalidade, setCidadesNaturalidade] = useState([]);
  const [nacionalidades, setNacionalidades] = useState([]);
  const [enderecoUfId, setEnderecoUfId] = useState(0);
  const [naturalidadeUfId, setNaturalidadeUfId] = useState(0);
  const [nacionalidadeId, setNacionalidadeId] = useState(0);
  const [planos, setPlanos] = useState([]);
  const [telefoneExtra, setTelefoneExtra] = useState([]);
  const [referencia, setReferencia] = useState([]);
  const [processamentoCrivo, setProcessamentoCrivo] = useState([]);
  const [decisaoSelecionada, setDecisaoSelecionada] = useState(null);
  const [motivosSelecionados, setMotivosSelecionados] = useState([]);
  const [observacao, setObservacao] = useState([]);
  const [loadingProposta, setLoadingProposta] = useState(false);
  const [situacaoId, setSituacaoId] = useState(null);
  const [situacaoPendencia, setSituacaoPendencia] = useState(null);
  const [checados, setChecados] = useState([]);
  const [chamada, setChamada] = useState(null);
  const [historicoChamada, setHistoricoChamada] = useState([]);
  const [clienteElegivel, setClienteElegivel] = useState(-1);
  const [isDecisao, setIsDecisao] = useState(false);
  const [listaModal, setListaModal] = useState([]);
  const [debitosConveniada, setDebitosConveniada] = useState([]);
  const [limiteCliente, setLimiteCliente] = useState(0);
  const [menorValorLimiteParcela, setMenorValorLimiteParcela] = useState(0);
  const [
    menorValorLimiteParcelaOutroProduto,
    setMenorValorLimiteParcelaOutroProduto,
  ] = useState(0);
  const [valorAjusteParcelaProposta, setValorAjusteParcelaProposta] =
    useState(0);
  // eslint-disable-next-line
  const [valorMaximoParcela, setValorMaximoParcela] = useState(0);
  const [validaClienteSemMargem, setValidaClienteSemMargem] = useState(false);
  const [
    validaClienteSemMargemOutroProduto,
    setValidaClienteSemMargemOutroProduto,
  ] = useState(false);
  const [validaLimiteNaoPermitido, setValidaLimiteNaoPermitido] =
    useState(false);
  const [
    validaCompanhiaCampoLimiteParcela,
    setValidaCompanhiaCampoLimiteParcela,
  ] = useState(false);
  const [
    tentativaMudancaSituacaoAprovado,
    setTentativaMudancaSituacaoAprovado,
  ] = useState(false);
  const [reloadSituacaoAprovado, setReloadSituacaoAprovado] = useState(false);

  const [errorDigitalTelefoneCelular, setErrorDigitalTelefoneCelular] =
    useState(false);

  const [historicoProposta, setHistoricoProposta] = useState([]);
  const [historicoPropostaFiltrado, setHistoricoPropostaFiltrado] = useState(
    [],
  );
  const [produtoNome, setProdutoNome] = useState('');
  const [validaDadoAdicionalUc, setValidaDadoAdicionalUc] = useState(false);
  const [dadoAdicionalUcIndex, setDadoAdicionalUcIndex] = useState();
  const [
    alertaValidacaoDadoAdicionalUcNull,
    setAlertaValidacaoDadoAdicionalUcNull,
  ] = useState(true);
  const [etapaAtualProposta, setEtapaAtualProposta] = useState('');
  const [responseAbaContato, setResponseAbaContato] = useState();

  const saveOperatividade = async (value) => {
    await gravarOperatividade(value);
  };

  const params = useParams();
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const { propostaId } = params;

  const relatorioEsteiraCredito =
    // eslint-disable-next-line
    query.get('origem') == 650 ? '?esteiraCredito=true' : '';

  const telaAnterior =
    // eslint-disable-next-line
    query.get('origem') == 650
      ? '/relatorio-esteira-credito'
      : '/analise-proposta';

  const formik = useFormik({
    validationSchema,
  });

  useEffect(() => {
    if (contexto?.abasAcessiveis) {
      Object.keys(validationSchema.fields).forEach((item) => {
        if (!contexto?.abasAcessiveis?.find((el) => el.Tag === item)) {
          delete validationSchema.fields[item];
        }
      });
    }
  }, [contexto?.abasAcessiveis]);

  useEffect(() => {
    if (contexto?.itens) {
      Object.keys(validationSchema.fields).forEach((grupo) => {
        Object.keys(validationSchema.fields[grupo].fields).forEach((campo) => {
          let tag = `${grupo}.${campo}`;
          if (editableField(tag)?.obrigatorio === false) {
            delete validationSchema.fields[grupo].fields[campo];
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contexto?.itens]);

  useEffect(() => {
    formik.setTouched(formik?.errors, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors]);

  useEffect(() => {
    if (tentativaMudancaSituacaoAprovado) {
      handleEnvioFormulario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tentativaMudancaSituacaoAprovado, reloadSituacaoAprovado]);

  const redirecionarTela = ({ redirecionaAnaliseMesaCredito = false } = {}) => {
    // IMPROVEMENT 4346
    // IMPROVEMENT 7734 - Adição da flag redirecionaAnaliseMesaCredito
    // redirecionaAnaliseMesaCredito -> em análise para ligando
    // se for true, permanece na tela 47

    localStorage.setItem('paginaAberta', false);

    const changedStatus = contexto.situacaoId !== situacaoId;
    const allowedStatus = [5, 8, 12];
    // se a proposta se mantiver nesses status, permanece na tela 47
    // id - status
    //  5 - em análise
    //  8 - ligando
    // 12 - análise validação

    let redirect = false;

    if (telaAnterior === '/analise-proposta') {
      redirect = true;
      if (
        redirecionaAnaliseMesaCredito ||
        (!changedStatus && !isDecisao && allowedStatus.includes(situacaoId))
      ) {
        redirect = false;
      }
    }

    if (redirect) {
      history.push(telaAnterior);
      return;
    }

    window.location.reload();
  };

  const handleEnvioFormulario = async () => {
    const errors = await formik
      .validateForm()
      .then((errors) => formik.setTouched({ ...errors }, true));

    formik.setTouched(errors, true);

    const changedDataDadosAdicionais = JSON.parse(
      JSON.stringify(formik.values),
    );

    const isDadosAdicionaisSemPreencher =
      changedDataDadosAdicionais.operacao.dadosAdicionais?.find(
        (item) => !item.valor,
      );
    if (
      editableField('historico.dadosHistoricoCia') &&
      isDadosAdicionaisSemPreencher &&
      tentativaMudancaSituacaoAprovado
    ) {
      setLoadingProposta(false);
      setDisableDecisao(false);
      Modal(
        '',
        `É necessário informar ${isDadosAdicionaisSemPreencher?.nome} para este convênio`,
        'warning',
      );
      return;
    }

    if (tentativaMudancaSituacaoAprovado) {
      setLoadingProposta(false);
      setDisableDecisao(false);
      Modal(
        '',
        'Proposta não pode ser aprovada sem a validação dos campos obrigatórios.',
        'warning',
      );
      return;
    }

    if (
      // 1 = Solicitar Comprovante, 0 = Luz em dia.
      // Se estiver nessas condições deverá ser validado
      // se alguma fatura de débito considerado está zerada.
      [1, 0].includes(clienteElegivel) &&
      debitosConveniada.find(
        // eslint-disable-next-line
        (item) => item.valor == 0 && item.participa == true,
      )
    ) {
      Modal(
        'Atenção',
        'Fatura de débito considerado com valor zerado',
        'warning',
      );
    } else if (cepInvalido) {
      Modal('Atenção', 'CEP inválido ou não encontrado.', 'warning');
      formik.setFieldError('endereco.cep', 'CEP inválido ou não encontrado');
    } else if (!Object.values(errors).length) {
      setDisableDecisao(true);
      setLoadingProposta(true);
      const changedData = JSON.parse(JSON.stringify(formik.values));
      let telefoneCelular = 0;
      changedData.historicoCia.debitosConveniada = debitosConveniada;
      changedData.elegivelLuzEmDia = clienteElegivel;
      changedData.historicoChamada = historicoChamada;

      if (changedData.operacao.tipoModalidade === 2) {
        setErrorDigitalTelefoneCelular(false);

        changedData.contatos.contato.tipoTelefone === 1 && telefoneCelular++;

        if (changedData.contatos?.contato?.telefoneExtra?.length) {
          changedData.contatos.contato.telefoneExtra.forEach((contato) => {
            contato.tipoTelefone === 1 && telefoneCelular++;
          });
        }

        if (telefoneCelular === 0) {
          setLoadingProposta(false);
          setErrorDigitalTelefoneCelular(true);
          Modal(
            '',
            'Telefone celular obrigatório para a modalidade digital',
            'warning',
          );
        }
      }

      const arquivosObrigatoriosPendentes = contexto?.tipoAnexos?.filter(
        (arquivo) => {
          const existeArquivoEmAnexo = arquivos.find(
            (item) => item.documentoId === arquivo.id,
          );
          if (
            (arquivo.tipoModalidade === changedData?.operacao?.tipoModalidade ||
              !arquivo.tipoModalidade) &&
            (arquivo.tipoRenda === changedData?.operacao?.tipoRenda ||
              !arquivo.tipoRenda) &&
            arquivo.obrigatorio &&
            !existeArquivoEmAnexo
          ) {
            return arquivo;
          } else {
            return null;
          }
        },
      );

      const PROPOSTA_PENDENTE = 6;
      const CONTATO_PENDENTE = 9;
      const VALIDACAO_PENDENTE = 13;

      const isSituacaoPendente =
        [PROPOSTA_PENDENTE, CONTATO_PENDENTE, VALIDACAO_PENDENTE].findIndex(
          (item) => item === situacaoId,
        ) !== -1;

      if (arquivosObrigatoriosPendentes.length > 0 && isSituacaoPendente) {
        Modal(
          '',
          `Não é possível avançar com a proposta. O(s) documento(s) ${arquivosObrigatoriosPendentes
            .map((item) => item.nome)
            .join(
              ', ',
            )} é(são) necessário(s) para avançar com a proposta. Você pode rever a relação de documentos, ou solicitar para que seja anexado.`,
          'warning',
        );
        setLoadingProposta(false);
        setDisableDecisao(false);
        return;
      }

      if (changedData.operacao.tipoModalidade !== 2 || telefoneCelular !== 0) {
        changedData.contatos.contato.telefone =
          changedData?.contatos?.contato?.telefone?.replace(/\D/g, '');

        if (changedData?.contatos?.contato?.telefoneExtra?.length > 0) {
          changedData.contatos.contato.telefoneExtra =
            changedData?.contatos?.contato?.telefoneExtra?.map(
              (telefoneExtra) => ({
                ...telefoneExtra,
                telefone: telefoneExtra?.telefone?.replace(/\D/g, ''),
              }),
            );
        } else {
          changedData.contatos.contato.telefoneExtra = null;
        }

        if (changedData?.contatos?.referencia?.length > 0) {
          changedData.contatos.referencia =
            changedData?.contatos?.referencia?.map((referencia) => ({
              ...referencia,
              telefone: referencia?.telefone?.replace(/\D/g, ''),
            }));
        }

        if (changedData?.unidade?.celularVendedor) {
          changedData.unidade.celularVendedor =
            changedData?.unidade?.celularVendedor?.replace(/\D/g, '');
        }

        changedData.endereco.cep = changedData?.endereco?.cep?.replace(
          /\D/g,
          '',
        );

        if (
          changedData?.profissional?.renda &&
          isNaN(changedData?.profissional?.renda)
        ) {
          changedData.profissional.renda = Number(
            parseFloat(
              changedData?.profissional?.renda.replace(/\D/g, '') / 100,
            ).toFixed(2),
          );
        }
        if (
          changedData?.profissional?.outrasRendas &&
          isNaN(changedData?.profissional?.outrasRendas)
        ) {
          changedData.profissional.outrasRendas = Number(
            parseFloat(
              changedData?.profissional?.outrasRendas?.replace(/\D/g, '') / 100,
            ).toFixed(2),
          );
        }

        if (
          changedData?.operacao?.operacao &&
          isNaN(changedData?.operacao?.operacao)
        ) {
          changedData.operacao.operacao = Number(
            parseFloat(
              changedData?.operacao?.operacao?.replace(/\D/g, '') / 100,
            ).toFixed(2),
          );
        }

        changedData.situacao = {
          etapaId: situacaoId,
          decisaoId: isDecisao ? decisaoSelecionada : null,
          motivos: motivosSelecionados,
          observacao: observacao,
        };

        if (
          changedData?.operacao?.valorContratado &&
          isNaN(changedData?.operacao?.valorContratado)
        ) {
          changedData.operacao.valorContratado = Number(
            parseFloat(
              changedData?.operacao?.valorContratado?.replace(/\D/g, '') / 100,
            ).toFixed(2),
          );
        }

        if (
          changedData?.operacao?.valorLiberado &&
          isNaN(changedData?.operacao?.valorLiberado)
        ) {
          changedData.operacao.valorLiberado = Number(
            parseFloat(
              changedData?.operacao?.valorLiberado?.replace(/\D/g, '') / 100,
            ).toFixed(2),
          );
        }

        if (
          changedData?.operacao?.prestacao &&
          isNaN(changedData?.operacao?.prestacao)
        ) {
          changedData.operacao.prestacao = Number(
            parseFloat(
              changedData?.operacao?.prestacao?.replace(/\D/g, '') / 100,
            ).toFixed(2),
          );
        }

        if (
          changedData?.operacao?.saldoDevedor &&
          isNaN(changedData?.operacao?.saldoDevedor)
        ) {
          changedData.operacao.saldoDevedor = Number(
            parseFloat(
              changedData?.operacao?.saldoDevedor?.replace(/\D/g, '') / 100,
            ).toFixed(2),
          );
        }

        changedData.checados = checados;

        delete changedData.contatos.contato.validaTelefone;
        delete changedData.contatos.contato.tooltip;

        if (changedData?.contatos?.contato?.telefoneExtra) {
          changedData?.contatos?.contato?.telefoneExtra?.forEach(
            (telefone, index) => {
              delete changedData.contatos.contato.telefoneExtra[index]
                .validaTelefone;
              delete changedData.contatos.contato.telefoneExtra[index].tooltip;
            },
          );
        }
        if (changedData?.contatos?.referencia) {
          changedData?.contatos?.referencia?.forEach((telefone, index) => {
            delete changedData.contatos.referencia[index].validaTelefone;
            delete changedData.contatos.referencia[index].tooltip;
          });
        }

        if (changedData.historicoCia.debitosConveniada.length) {
          delete changedData.mesAnoReferencia;
          delete changedData.vencimento;
          delete changedData.valorFatura;
          delete changedData.tipo;
          delete changedData.consumo;
          delete changedData.comprovante;
          delete changedData.codigoBarras;
        }

        changedData.historicoCia.debitosConveniada =
          changedData.historicoCia.debitosConveniada.map((fatura) => {
            if (isString(fatura.id)) {
              delete fatura.id;
            }
            return fatura;
          });

        changedData.operacao.dadosAdicionais?.map((item) => {
          return item.valor ? (item.valor = String(item.valor)) : item.valor;
        });

        const data = await atualizarMesaCredito(changedData).catch(() =>
          setLoadingProposta(false),
        );

        if (data) {
          const redirecionaAnaliseMesaCredito =
            !!data?.redirecionaAnaliseMesaCredito;
          setLoadingProposta(false);
          Modal('', 'Parecer enviado com sucesso.', 'success', () =>
            redirecionarTela({ redirecionaAnaliseMesaCredito }),
          );
        }
      }
    } else {
      Modal(
        '',
        'Existem campos obrigatórios que ainda não foram preenchidos. Favor verificar e tentar novamente.',
        'warning',
      );
    }
    setDisableDecisao(false);
  };

  const fetchEstados = async () => {
    const response = await listarUfs();
    setEstados(response);
  };

  const fetchPaises = async () => {
    const response = await listarPaises();
    setNacionalidades(response);
    return response;
  };

  const fetchCidades = async (ufId) => {
    const response = await listarCidades(ufId);
    if (response) {
      setCidades(response);
    }
  };

  const fetchListaCidadesNaturalidade = async (id) => {
    const response = await listarCidades(id);
    if (response) {
      setCidadesNaturalidade(response);
    }
  };

  const fetchEndereco = async (cep) => {
    const response = await buscarEndereco(cep);
    if (response.success) {
      return response.data;
    }
  };

  const fetchMensagens = useCallback(async (id) => {
    const response = await getPropostaMensagens(id);
    if (response) {
      setRefreshCentralComunicacao(response?.refreshCentralComunicacao);
      setMensagens(response?.centralComunicacao);
      setUltimaMensagem(
        Math.max.apply(
          Math,
          response?.centralComunicacao?.map((mensagem) => {
            return mensagem.id;
          }),
        ),
      );
    }
  }, []);

  const fetchListagemMotivosEtapas = async (situacaoId) => {
    let situacaoComQueryString = situacaoId;

    if (proposta?.operacao?.produtoId)
      situacaoComQueryString += `?produtoId=${proposta.operacao.produtoId}`;
    const response = await listagemMotivosEtapas(situacaoComQueryString);
    return response;
  };

  const fetchMensagensParcial = useCallback(
    async (id) => {
      if (ultimaMensagem > 0) {
        const response = await getPropostaMensagensParcial(id, ultimaMensagem);
        if (response) {
          setMensagens(mensagens.concat(response.centralComunicacao));
          if (response?.centralComunicacao?.length > 0) {
            setUltimaMensagem(
              Math.max.apply(
                Math,
                response.centralComunicacao.map((mensagem) => {
                  return mensagem.id;
                }),
              ),
            );
          }
        }
      } else {
        fetchMensagens(id);
      }
    },
    [fetchMensagens, mensagens, ultimaMensagem],
  );

  const sendMensagem = async (mensagem) => {
    const response = await sendPropostaMensagem(mensagem);
    if (response) {
      setMensagens(mensagens.concat(response));
      setUltimaMensagem(response[0].id);
    }
  };

  const sendAnotacao = async (anotacao) => {
    const response = await sendPropostaAnotacao(anotacao);
    if (response) {
      setAnotacoes(anotacoes.concat(response));
    }
  };

  const modalBlocklist = useCallback(async (idPropostaBlocklist) => {
    if (idPropostaBlocklist) {
      const data = await getBlocklistMesaCredito(idPropostaBlocklist);

      if (data) {
        setDadosModalBlocklist(data?.data[0]);
      }
    }
  }, []);

  const validationField = (value, name, label, type) => {
    let msgError = null;

    if (editableField(name)) {
      const campoObrigatorio = contexto?.itens?.find(
        (el) => el.tag === name && el.obrigatorio,
      );

      if (!String(value) && campoObrigatorio) {
        msgError = 'Obrigatório informar ' + label;
      }

      if (value) {
        switch (type) {
          case 'email':
            if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
              msgError = 'Deve ser informado um e-mail válido';
            }
            break;
          case 'telefone':
            if (!validaPhone(value)) {
              msgError = 'Telefone Inválido';
            }
            break;
          case 'cpf':
            if (!validaCPF(value?.replace(/\D/g, ''))) {
              msgError = 'CPF Inválido';
            }
            break;
          case 'nome':
            if (!value.match(/(\w.+\s).+/)) {
              msgError = 'Nome Incompleto';
            }

            if (value.match(/\d+/)) {
              msgError = 'Nome Inválido';
            }
            break;
          default:
        }
      }
      return msgError;
    }
  };

  const editableField = (name) => {
    const campoEditavel = contexto?.itens?.find((el) => el.tag === name);

    return campoEditavel;
  };

  const highlightField = (field, subfield = null, indice = null) => {
    const obj =
      indice !== null
        ? // eslint-disable-next-line
        eval('proposta.' + field)[indice]?.logAlterados
        : proposta?.logAlterados;
    const localField = subfield ? field + '.' + subfield : field;
    const alterados = obj?.filter((alterado) => localField === alterado);

    return !!alterados?.length > 0;
  };

  const highlightTelefoneExistente = () => {
    let validaTelefone = proposta?.contatos?.contato?.validaTelefone;
    let contato = {
      validaTelefone,
      telefone: proposta?.contatos?.contato?.telefone,
      cpf: proposta?.cliente?.cpf,
    };
    if (
      contato?.validaTelefone?.blocklist ||
      !!contato?.validaTelefone?.propostas
    ) {
      return contato;
    }

    return null;
  };

  const highlightTelefoneExtraExistente = (indice) => {
    if (proposta?.contatos?.contato?.telefoneExtra?.length > 0) {
      let validaTelefone =
        proposta?.contatos?.contato?.telefoneExtra[indice]?.validaTelefone;

      let contato = {
        validaTelefone,
        telefone: proposta?.contatos?.contato?.telefoneExtra[indice]?.telefone,
        cpf: proposta?.cliente?.cpf,
      };
      if (
        contato?.validaTelefone?.blocklist ||
        !!contato?.validaTelefone?.propostas
      ) {
        return contato;
      }
    }
    return null;
  };

  const highlightTelefoneReferenciaExistente = (indice) => {
    let validaTelefone = proposta?.contatos?.referencia[indice]?.validaTelefone;
    let contato = {
      validaTelefone,
      telefone: proposta?.contatos?.referencia[indice]?.telefone,
      cpf: proposta?.cliente?.cpf,
    };
    if (
      contato?.validaTelefone?.blocklist ||
      !!contato?.validaTelefone?.propostas
    ) {
      return contato;
    }
    return null;
  };

  const highlightDadoAdicional = (item) => {
    if (
      item?.validaDadosAdicionaisExistente?.blocklist ||
      !!item?.validaDadosAdicionaisExistente?.propostas
    ) {
      return true;
    }
    return false;
  };

  const highlightTab = (tab) => {
    const alterados = proposta?.logAlterados.filter(
      (alterado) => alterado.split('.')[0] === tab,
    );

    const telefonesExtraAlterado =
      proposta?.contatos?.contato?.telefoneExtra?.map(
        (telefoneExtra) =>
          telefoneExtra.logAlterados.filter(
            (alterado) => alterado.split('.')[0] === tab,
          ).length,
      );

    return (
      !!alterados?.length > 0 || !!telefonesExtraAlterado?.find((el) => el > 0)
    );
  };

  const campoChecar = (name) => {
    const check = proposta?.checar?.find((el) => el.tag === name);

    return check;
  };

  const getChecado = (id) =>
    checados.length > 0 && checados?.find((el) => el === id);

  const handleChangeChecados = (id) => {
    const field = getChecado(id);

    if (!field) {
      setChecados((oldChecados) => [...oldChecados, id]);
    } else {
      setChecados((oldChecados) => oldChecados.filter((el) => el !== id));
    }
  };

  const filtroHistoricoProposta = (valorPesquisado = '') => {
    const historicoFiltrado = historicoProposta.filter((obj) => {
      const values = Object.values(obj);
      for (let i = 0; i < values.length; i++) {
        let strValue;

        if (
          !(values[i] instanceof String) &&
          !(values[i] instanceof Number) &&
          values[i].descricao
        ) {
          strValue = String(values[i].descricao).toLowerCase();
        } else {
          strValue = String(values[i]).toLowerCase();
        }

        if (strValue.includes(valorPesquisado.toLowerCase())) {
          return true;
        }
      }
      return false;
    });

    setHistoricoPropostaFiltrado(historicoFiltrado);
  };

  useEffect(() => {
    if (isModalBlacklistVisible) {
      modalBlocklist(propostaId);
    }
  }, [isModalBlacklistVisible, propostaId, modalBlocklist]);

  useEffect(() => {
    fetchEstados();
    fetchPaises();
  }, []);

  useEffect(() => {
    if (enderecoUfId) {
      fetchCidades(enderecoUfId);
    }
  }, [enderecoUfId]);

  useEffect(() => {
    if (naturalidadeUfId) {
      fetchListaCidadesNaturalidade(naturalidadeUfId);
    }
  }, [naturalidadeUfId]);

  useEffect(() => {
    async function loadPropostaMesa() {
      setLoadingProposta(true);
      const data = await getPropostaMesaCredito(
        `${propostaId}${relatorioEsteiraCredito}`,
      ).catch(() => setLoadingProposta(false));

      setContexto({
        abasAcessiveis: data?.abasAcessiveis,
        arquivosObrigatorios: data?.arquivosObrigatorios,
        arquivosProduto: data?.arquivosProduto,
        bancos: data?.bancos,
        conta: data?.conta,
        dadosBemGarantia: data?.dadosBemGarantia,
        debitosConveniada: data?.proposta?.historicoCia?.debitosConveniada,
        decisoes: data?.decisao,
        estadoCivil: data?.estadoCivil,
        etapasProposta: data?.etapasProposta,
        grauInstrucao: data?.grauInstrucao,
        itens: data?.itens,
        listaStatusChamada:
          data?.listaStatusChamada || data?.proposta?.listaStatusChamada,
        ocupacoes: data?.ocupacoes,
        processamentoCrivo: data?.processamentoCrivo,
        profissoes: data?.profissoes,
        propostaHistorico: data?.propostaHistorico,
        sexo: data?.sexo,
        opcoesModalidade: data?.opcoesModalidade,
        situacaoDebito: data?.situacaoDebito,
        tabelaJuros: data?.tabelaJuros,
        tempoConta: data?.tempoConta,
        tipoConta: data?.tipoConta,
        tipoDebito: data?.tipoDebito,
        tipoOperacao: data?.tipoOperacao,
        tipoReferencia: data?.tipoReferencia,
        tipoTelefone: data?.tipoTelefone,
        tempoEmpregoAtual: data?.tempoEmpregoAtual,
        tipoAnexos: data?.tipoAnexos,
        bloqueiaAcessoSimultaneo: !!data?.proposta?.bloqueiaAcessoSimultaneo,
        dadosOrgao:
          data?.orgao &&
          data?.orgao?.filter(
            (item) => item.id === data?.proposta?.operacao?.orgaoId,
          )[0]?.orgaoDados,
        diaRecebimento:
          data?.orgao &&
          data?.orgao?.filter(
            (item) => item.id === data?.proposta?.operacao?.orgaoId,
          )[0]?.diaRecebimento,
        situacaoId: data?.proposta?.situacaoId,
      });

      const referencias = data?.proposta?.contatos?.referencia
        ? data?.proposta?.contatos?.referencia
        : [];
      const camposMinimos = referencias.length > 2 ? referencias.length : 2;
      for (let i = referencias.length; i < camposMinimos; i++) {
        referencias.push({
          nome: '',
          telefone: '',
          grau: 0,
        });
      }
      setReferencia(referencias);

      const tabelaJurosSelecionada = data?.tabelaJuros?.filter(
        (tabela) => tabela.id === data?.proposta?.operacao.tabelaJurosId,
      )[0];
      setPlanos(tabelaJurosSelecionada?.tabelaJurosValores);

      if (data?.proposta?.cliente) {
        data.proposta.cliente.nome = String(
          data?.proposta?.cliente?.nome,
        ).toUpperCase();
      }

      setEtapaAtualProposta(data?.proposta?.situacaoDescricao)
      setProdutoNome(data?.proposta?.operacao.produtoNome);
      setProposta(data?.proposta);
      setChecados(data?.proposta?.checados || []);
      setNacionalidadeId(data?.proposta?.cliente.nacionalidadeId);
      setNaturalidadeUfId(data?.proposta?.cliente.naturalidadeUfId);
      setEnderecoUfId(data?.proposta?.endereco.ufId);
      setTelefoneExtra(data?.proposta?.contatos.contato.telefoneExtra);
      setArquivos(
        data?.proposta?.anexo.map((anexo) => ({
          ...anexo,
          uid: anexo.id,
          type: 'doc',
        })),
      );

      setOperatividade(data?.operatividade);
      setAnotacoes(data?.anotacao);
      setSituacaoId(data?.proposta?.situacaoId);
      fetchMensagens(propostaId);
      setLoadingProposta(false);
    }

    if (propostaId) {
      loadPropostaMesa();
      getAbaContato(propostaId).then((response) => {
        setResponseAbaContato(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMensagens, propostaId]);

  const ajustaEstruturaContatosComValidaTelefone = () => {
    const propostaAtualizada = JSON.parse(JSON.stringify(proposta));

    if (formik?.values?.contatos) {
      propostaAtualizada.contatos = formik?.values?.contatos;
    }

    propostaAtualizada.contatos.contato.validaTelefone =
      responseAbaContato?.telefones?.find(
        (resTel) => resTel.telefone === propostaAtualizada?.contatos?.contato?.telefone,
      )?.validaTelefone;
    propostaAtualizada?.contatos?.contato?.telefoneExtra?.forEach((tel) => {
      const responseTelefoneExtra =
        responseAbaContato?.telefones?.find(
          (resTel) => resTel.telefone === tel.telefone,
        );
      tel.validaTelefone = responseTelefoneExtra?.validaTelefone;
    });
    propostaAtualizada?.contatos?.referencia?.forEach((ref) => {
      const responseReferencia =
        responseAbaContato?.telefones?.find(
          (resRef) => resRef.telefone === ref.telefone,
        );
      ref.validaTelefone = responseReferencia?.validaTelefone;
    });

    setProposta(propostaAtualizada);
    setTelefoneExtra(propostaAtualizada?.contatos.contato.telefoneExtra);
    setReferencia(
      propostaAtualizada?.contatos?.referencia
        ? propostaAtualizada?.contatos?.referencia
        : [],
    );
    setResponseAbaContato(undefined);
  }

  useEffect(() => {
    if (proposta && responseAbaContato) {
      ajustaEstruturaContatosComValidaTelefone();
    }

  }, [proposta, responseAbaContato]);

  useEffect(() => {
    const refreshMessages = setInterval(() => {
      fetchMensagensParcial(propostaId);
    }, refreshCentralComunicacao * 1000);

    return () => clearInterval(refreshMessages);
    // eslint-disable-next-line
  }, [ultimaMensagem]);

  useEffect(() => {
    async function loadProcessamentoCrivo() {
      const data = await getProcessamentoCrivo(propostaId);
      setProcessamentoCrivo(data);
    }

    if (propostaId) {
      loadProcessamentoCrivo();
    }
  }, [propostaId]);

  useEffect(() => {
    const ENERGIA = 6;
    const LUZ_EM_DIA = 12;
    const CDC_ENERGIA = 13;
    async function calcularLimites() {
      const tabelaJuros = contexto?.tabelaJuros?.find(
        (tabela) => tabela.id === proposta?.operacao?.tabelaJurosId,
      );
      if (
        !objetoLimitesParcela &&
        proposta?.operacao?.renda &&
        proposta?.operacao?.tabelaJurosId &&
        proposta &&
        tabelaJuros?.tipo === 0
      ) {
        await calcularValoresLimitesPrestacao(
          proposta?.operacao?.produtoId,
          proposta?.id,
          proposta?.operacao?.convenioId,
          proposta?.operacao?.tabelaJurosId,
          proposta?.operacao?.vencimento,
          (proposta?.operacao?.produtoNome === "CDC Energia" || proposta?.operacao?.produtoNome === "Energia" || proposta?.operacao?.produtoNome === "Luz em Dia")  ? proposta?.resultadoCrivo?.valorContingenciaConvenio : proposta?.operacao?.valorContingencia,
          proposta?.operacao?.renda,
          proposta?.operacao?.recalculo,
        ).then((value) => {
          objetoLimitesParcela = [];
          objetoLimitesProduto = value;
        });

        await calcularLimitesParcelas(
          proposta?.operacao?.renda,
          proposta?.operacao?.produtoId,
          proposta?.id,
          proposta?.operacao?.tabelaJurosId,
          proposta?.operacao?.vencimento,
          objetoLimitesProduto,
          proposta?.refin?.valorPrestacaoRefin,
          proposta?.operacao?.recalculo,
          proposta?.operacao?.valorRendaComprometida,
          proposta?.operacao?.valorDebitoConcorrente,
          null, 
          (proposta?.operacao?.produtoNome === "CDC Energia" || proposta?.operacao?.produtoNome === "Energia" || proposta?.operacao?.produtoNome === "Luz em Dia") && proposta?.resultadoCrivo?.valorMaximoParcelaConvenio
        ).then((response) => {
          const tempTabelaJuros = contexto?.tabelaJuros?.find(
            (el) => el.id === proposta?.operacao?.tabelaJurosId,
          );
          objetoLimitesParcela = response.objetoLimitesParcela;
          if (
            objetoLimitesParcela[1]?.limiteParcela === 0 ||
            objetoLimitesParcela[1] === undefined
          ) {
            // se só possui um bloco de Range com limite de parcela retornado pelo Crivo
            // retorna o menor entre o range final do Crivo e o maior plano possivel da tabela de juros selecionada
            objetoLimitesParcela[0].rangeFinal = Math.min(
              objetoLimitesParcela[0].rangeFinal,
              tempTabelaJuros.planoMaximo,
            );
          }
        });

        await calcularValorLimite(
          proposta?.operacao?.produtoId,
          proposta?.id,
          proposta?.operacao?.convenioId,
          proposta?.operacao?.tabelaJurosId,
          proposta?.operacao?.vencimento,
          (proposta?.operacao?.produtoNome === "CDC Energia" || proposta?.operacao?.produtoNome === "Energia" || proposta?.operacao?.produtoNome === "Luz em Dia")  ? proposta?.resultadoCrivo?.valorContingenciaConvenio : proposta?.operacao?.valorContingencia,
          proposta?.operacao?.renda,
          objetoLimitesParcela,
          objetoLimitesProduto,
          null, // valorSaldoDevedorRefin,
          proposta?.operacao?.recalculo,
        ).then((value) => {
          setLimiteCliente(value);
          setMenorValorLimiteParcelaOutroProduto(
            objetoLimitesParcela[0]?.minimoParcelaCrivo,
          );
        });
      }
      if (
        proposta?.operacao?.produtoId === CDC_ENERGIA &&
        proposta?.resultadoCrivo?.limite
      ) {
        setLimiteCliente(proposta?.resultadoCrivo?.limite);
      }
      // IMPROVEMENT 5739
      // Para exibir "Limite do Cliente" para Energia e Luz em Dia foi feito uma
      // validação para quando a tabela de Juros for de Valores Fixos, consumir os limites retornados pelo crivo
      else if (
        proposta?.operacao?.produtoId === ENERGIA &&
        proposta?.resultadoCrivo?.limite
      ) {
        setLimiteCliente(proposta?.resultadoCrivo?.limite);
      } else if (
        proposta?.operacao?.produtoId === LUZ_EM_DIA &&
        proposta?.resultadoCrivo?.valorLuzEmDia
      ) {
        setLimiteCliente(proposta?.resultadoCrivo?.valorLuzEmDia);
      }
    }
    setValidaCompanhiaCampoLimiteParcela(proposta?.consultarValorCrivo);

    calcularLimites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposta?.operacao?.renda, proposta]);

  useEffect(() => {
    if (propostaId) {
      async function fetchData() {
        await fetchHistoricoChamada(propostaId).then((response) => {
          setHistoricoChamada(response || []);
        });
      }
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chamada]);

  useEffect(() => {
    if (proposta?.historicoCia?.debitosConveniada) {
      setDebitosConveniada(proposta.historicoCia.debitosConveniada);
    }
    setClienteElegivel(proposta?.elegivelLuzEmDia);

    const cpfCliente = proposta?.cliente?.cpf;
    if (propostaId && cpfCliente) {
      postHistoricoProposta(cpfCliente, parseInt(propostaId)).then((data) => {
        const changedResponse = data?.map((item) => {
          item.situacao = { ...item.situacao, propostaId: item.propostaId };
          item.ultimaAlteracao = moment(item.ultimaAlteracao).format(
            'DD/MM/YYYY hh:mm',
          );
          item.valorContratado = currencyFormat(item.valorContratado);

          return item;
        });

        setHistoricoProposta(changedResponse || []);
        setHistoricoPropostaFiltrado(changedResponse || []);
      });
    }

    const valorMinimoProposta = async () => {
      const response = await postSimulacaoLimiteParcela({
        propostaId: proposta?.id,
        produtoId: proposta?.operacao?.produtoId,
        convenioId: proposta?.operacao?.convenioId,
        tabelaJurosId: proposta?.operacao?.tabelaJurosId,
        vencimento: proposta?.operacao?.vencimento,
        recalculo: proposta?.operacao?.recalculo,
        renda: proposta?.operacao?.renda,
      });
      setValorMaximoParcela(response?.valorMaximoParcela);
      if (
        response &&
        (produtoNome === 'Energia' || produtoNome === 'Luz em Dia')
      ) {
        setMenorValorLimiteParcela( Math.max(response?.valorMinimoParcela, proposta?.resultadoCrivo?.valorMinimoParcelaConvenio));
      }
    };
    if (proposta && produtoNome) {
      valorMinimoProposta();
    }

    const dadoAdicionalUcIndexLocal =
      proposta?.operacao?.dadosAdicionais.findIndex((dado) => dado.flagChaveUc);
    setDadoAdicionalUcIndex(dadoAdicionalUcIndexLocal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposta, produtoNome]);

  useEffect(() => {
    if (
      proposta?.validaCarencia?.status === 1 &&
      (moment(proposta?.operacao?.vencimento) <
        moment()
          .add(contexto?.tabelaJuros?.carenciaMinima, 'd')
          .startOf('day') ||
        moment(proposta?.operacao?.vencimento) >
        moment()
          .add(contexto?.tabelaJuros?.carenciaMaxima, 'd')
          .startOf('day'))
    ) {
      setListaModal((prev) => {
        return [...prev, proposta?.validaCarencia?.mensagem];
      });
    }
    if (proposta?.validaContratoRefin?.status === 1) {
      setListaModal((prev) => {
        return [...prev, proposta?.validaContratoRefin?.mensagem];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposta?.validaCarencia, proposta?.validaContratoRefin]);

  useEffect(() => {
    if (proposta?.bloqueiaAcessoSimultaneo) {
      setListaModal((prev) => {
        return [proposta?.bloqueiaAcessoSimultaneo, ...prev];
      });
    }
  }, [proposta?.bloqueiaAcessoSimultaneo]);

  useEffect(() => {
    if (listaModal.length) {
      setTimeout(() => {
        if (listaModal.length > 1) {
          let message =
            '<textarea cols="40" rows="10" readonly style="font-size: 15px; padding: 5px; resize: none;">';
          listaModal.forEach((item) => {
            message += '  • ' + item + '\n\n';
          });
          message += '</textarea>';
          ModalHtmlComponent('', message, 'warning');
        } else if (listaModal.length === 1) {
          Modal('', listaModal[0], 'warning');
        }
      }, 1500);
    }
  }, [listaModal]);

  useEffect(() => {
    const valorLimiteParcela = proposta?.resultadoCrivo?.valorAjusteParcela;
    if (
      proposta?.operacao?.produtoNome === 'Energia' ||
      proposta?.operacao?.produtoNome === 'Luz em Dia'
    ) {
      setValorAjusteParcelaProposta(
        valorLimiteParcela ? Math.min(proposta?.resultadoCrivo?.valorMaximoParcelaConvenio,valorLimiteParcela, valorMaximoParcela) : Math.min(proposta?.resultadoCrivo?.valorMaximoParcelaConvenio,valorMaximoParcela)
      );
    } else if (proposta?.id && objetoLimitesParcela) {
      const valorMaximoParcelaLocal = objetoLimitesParcela[0]?.limiteParcela;
      setValorAjusteParcelaProposta(
           valorLimiteParcela ? Math.min(proposta?.resultadoCrivo?.valorMaximoParcelaConvenio,valorLimiteParcela, valorMaximoParcelaLocal) : Math.min(proposta?.resultadoCrivo?.valorMaximoParcelaConvenio,valorMaximoParcelaLocal)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setValorAjusteParcelaProposta,
    valorMaximoParcela,
    proposta,
    objetoLimitesParcela,
  ]);

  const permiteSalvar = () => {
    const NEGADA = 2;
    
    const etapaProposta = contexto?.etapasProposta?.find(
      (etapa) => etapa.id === situacaoId,
    );

    const isEtapaAvancaEsteira =
      etapaProposta?.nome === 'Ligando' ||
      etapaProposta?.nome === 'Fila Contato' ||
      etapaProposta?.nome === 'Aprovado';

    const isEmAnalise = proposta?.situacaoDescricao === 'Em An\u00e1lise';

    if (
      proposta?.operacao?.produtoNome !== 'Energia' &&
      proposta?.operacao?.produtoNome !== 'CDC Energia' &&
      proposta?.operacao?.produtoNome !== 'Luz em Dia'
    ) {
      return true;
    }

    const existeFaturasAlteradas = debitosConveniada?.some(debito => !debito.faturaValidada);

    const isEtapaPropostaPendente = etapaProposta?.nome === "Proposta Pendente"

    const precisaAcionarP3_5 =
      proposta?.historicoCia?.validaBotaoP3_5 &&
      existeFaturasAlteradas &&
      (isEtapaAvancaEsteira || isEtapaPropostaPendente) &&
      proposta?.situacaoId !== situacaoId;

    if (precisaAcionarP3_5) {
      Modal(
        '',
        `Clique no botão ${
          proposta?.operacao?.permiteLuzEmDia
            ? '"Validar Luz em dia"'
            : '"Validar Histórico Cia"'
        } para alterar o status da proposta`,
        'warning',
      );

      return false;
    }

    const existeAlgumaFatura = debitosConveniada?.length > 0;

    const precisaIncluirFatura =
      proposta?.historicoCia?.validaBotaoP3_5 &&
      !existeAlgumaFatura &&
      isEtapaAvancaEsteira &&
      proposta?.situacaoId !== situacaoId;

    if (precisaIncluirFatura) {
      Modal("", "É necessário cadastrar o histórico de faturas.", "warning");
      return false;
    }
    
    if (
      (validaClienteSemMargem === true && decisaoSelecionada !== NEGADA) ||
      (validaClienteSemMargemOutroProduto === true &&
        decisaoSelecionada !== NEGADA)
    ) {
      Modal(
        '',
        'Limite de prestação é invalido e não atende a regra do produto',
        'warning',
      );
      return false;
    }

    if (isEtapaAvancaEsteira && validaLimiteNaoPermitido === true) {
      Modal(
        '',
        'Limite de prestação é invalido, é necessário ajustar o valor da parcela',
        'warning',
      );
      return false;
    }

    

    if (
      isEtapaAvancaEsteira &&
      isEmAnalise &&
      proposta?.resultadoCrivo?.unidadeCorreta
    ) {
      return true;
    }

    if (
      isEtapaAvancaEsteira &&
      alertaValidacaoDadoAdicionalUcNull &&
      isEmAnalise &&
      proposta?.resultadoCrivo?.unidadeCorreta === null
    ) {
      Modal(
        '',
        `${proposta?.operacao?.dadosAdicionais[0]?.nome} inválido. Confira o valor do campo antes de seguir com a proposta!`,
        'warning',
      );
      setAlertaValidacaoDadoAdicionalUcNull(false);
      return false;
    }

    const foiAlteradoDadoAdicionalUc =
      ((proposta?.operacao?.dadosAdicionais[dadoAdicionalUcIndex]?.valor !==
        formik?.values?.operacao?.dadosAdicionais[dadoAdicionalUcIndex]?.valor) ||
        proposta?.historicoCia?.dadoAdicionalAnalistaId
      ) && formik?.values?.operacao?.dadosAdicionais[dadoAdicionalUcIndex]?.valor;
    
    if (
      isEtapaAvancaEsteira &&
      !foiAlteradoDadoAdicionalUc &&
      isEmAnalise &&
      proposta?.resultadoCrivo?.unidadeCorreta === false
    ) {
      Modal(
        '',
        `${proposta?.operacao?.dadosAdicionais[0]?.nome} inválido, insira os dados corretos`,
        'warning',
      );
      setValidaDadoAdicionalUc(true);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (formik.values?.resultadoCrivo?.valorAjusteParcela) {
      if (
        menorValorLimiteParcela &&
        (proposta?.operacao?.produtoNome === 'Energia' ||
          proposta?.operacao?.produtoNome === 'Luz em Dia') &&
        formik.values?.resultadoCrivo?.valorAjusteParcela <
        menorValorLimiteParcela
      ) {
        setValidaClienteSemMargem(true);
      } else if (
        proposta &&
        objetoLimitesParcela &&
        proposta?.operacao?.produtoNome === 'CDC Energia' &&
        (objetoLimitesParcela[0]?.minimoParcelaCrivo
          ? formik.values?.resultadoCrivo?.valorAjusteParcela <
          objetoLimitesParcela[0]?.minimoParcelaCrivo
          : false)
      ) {
        setValidaClienteSemMargemOutroProduto(true);
      } else if (
        proposta &&
        formik.values?.resultadoCrivo?.valorAjusteParcela <
        proposta?.operacao?.prestacao
      ) {
        setValidaLimiteNaoPermitido(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    menorValorLimiteParcela,
    formik.values?.resultadoCrivo,
    formik.values?.proposta,
    objetoLimitesParcela,
  ]);

  return (
    <AnaliseMesaContext.Provider
      value={{
        proposta,
        propostaId,
        setProposta,
        contexto,
        showModalDigitro,
        setShowModalDigitro,
        showModalAgitel,
        setShowModalAgitel,
        telefone,
        setTelefone,
        tipoTelefone,
        setTipoTelefone,
        chamadaId,
        setChamadaId,
        timer,
        setTimer,
        reset,
        setReset,
        isActive,
        setIsActive,
        isPaused,
        setIsPaused,
        status,
        setStatus,
        increment,
        formik,
        disableDecisao,
        getPropostaMesaCredito,
        carregarImagem,
        setDisableDecisao,
        operatividade,
        isOnline,
        setOperatividade,
        setOnline,
        saveOperatividade,
        isModalBlacklistVisible,
        setIsModalBlacklistVisible,
        isModalMotivosVisible,
        setIsModalMotivosVisible,
        dadosModalBlocklist,
        mensagens,
        anotacoes,
        sendMensagem,
        sendAnotacao,
        atualizarMesaCredito,
        arquivos,
        setArquivos,
        validationField,
        editableField,
        fetchEndereco,
        estados,
        cidades,
        cidadesNaturalidade,
        nacionalidades,
        nacionalidadeId,
        setNacionalidadeId,
        setNaturalidadeUfId,
        setEnderecoUfId,
        planos,
        setPlanos,
        telefoneExtra,
        setTelefoneExtra,
        referencia,
        setReferencia,
        processamentoCrivo,
        decisaoSelecionada,
        setDecisaoSelecionada,
        getProcessamentoCrivoCompleto,
        getImagem,
        motivosSelecionados,
        setMotivosSelecionados,
        highlightTab,
        highlightField,
        campoChecar,
        handleChangeChecados,
        getChecado,
        listagemMotivosDecisao,
        fetchListagemMotivosEtapas,
        loadingProposta,
        situacaoId,
        setSituacaoId,
        situacaoPendencia,
        setSituacaoPendencia,
        checados,
        chamada,
        setChamada,
        listaAnalista,
        errorDigitalTelefoneCelular,
        setErrorDigitalTelefoneCelular,
        highlightTelefoneExistente,
        highlightTelefoneReferenciaExistente,
        highlightTelefoneExtraExistente,
        debitosConveniada,
        setDebitosConveniada,
        observacao,
        setObservacao,
        historicoChamada,
        setHistoricoChamada,
        clienteElegivel,
        setClienteElegivel,
        cepInvalido,
        setCepInvalido,
        reenviarSMS,
        highlightDadoAdicional,
        setIsDecisao,
        buscarHistoricoAcoesProposta,
        buscarHistoricoProposta,
        getHistoricoReanalise,
        postSimulacaoLimiteParcela,
        handleEnvioFormulario,
        validaLimiteNaoPermitido,
        validaClienteSemMargem,
        validaClienteSemMargemOutroProduto,
        limiteCliente,
        menorValorLimiteParcela,
        valorMaximoParcela,
        validaCompanhiaCampoLimiteParcela,
        setValidaCompanhiaCampoLimiteParcela,
        setLimiteCliente,
        tentativaMudancaSituacaoAprovado,
        setTentativaMudancaSituacaoAprovado,
        setReloadSituacaoAprovado,
        reloadSituacaoAprovado,
        historicoPropostaFiltrado,
        filtroHistoricoProposta,
        permiteSalvar,
        valorAjusteParcelaProposta,
        menorValorLimiteParcelaOutroProduto,
        setValorAjusteParcelaProposta,
        setValidaLimiteNaoPermitido,
        setValidaClienteSemMargem,
        setValidaClienteSemMargemOutroProduto,
        setMenorValorLimiteParcela,
        setMenorValorLimiteParcelaOutroProduto,
        etapaAtualProposta,
        objetoLimitesParcela,
        validaDadoAdicionalUc,
        setValidaDadoAdicionalUc,
        dadoAdicionalUcIndex,
        responseAbaContato
      }}
    >
      {children}
    </AnaliseMesaContext.Provider>
  );
}

export default AnaliseMesaProvider;
