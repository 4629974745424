import React, { createContext, useEffect, useState, useCallback, useContext } from 'react';

import moment from 'moment';

import validaPhone from '../utils/validaPhone';

import validaCPF from '../utils/validaCpf';

import { currencyFormat } from '../utils/numberFormatter';
import { CalculadoraContext } from '../contexts/CalculadoraContext';
import {
  listarPaises,
  listarCidades,
  listarUfs,
  buscarEndereco,
} from '../services/enderecoService';

import {
  getPropostaEntradaMesa,
  calculoPrestacao,
  atualizarPropostaCredito,
  getPropostaMensagens,
  getPropostaMensagensParcial,
  sendPropostaMensagem,
  cancelamentoProposta,
  reanaliseProposta,
  aprovacaoPromotora,
  simulacaoValorSolicitado,
  simulacaoValorParcela,
  reenviarSMS,
  postAcertPixDocOCR,
  postConsultaDocOCR
} from '../services/propostaService';

import { carregarImagem } from '../services/imagemService';

import ModalHtml from '../components/ModalHtml';
import ModalComponent from '../components/Modal';

export const valuesReferencia = {
  nome: '',
  telefone: '',
  grau: 0,
};


export const PropostaCreditoContext = createContext({
  proposta: {},
  bancos: [],
  contas: [],
  operacoes: [],
  tempoConta: [],
  tipoConta: [],
  tipoReferencia: [],
  tipoDebito: [],
  estadoCivil: [],
  sexo: [],
  tabelaJuros: [],
  ocupacoes: [],
  profissoes: [],
  grauInstrucao: [],
  tipoTelefone: [],
  estados: [],
  cidades: [],
  cidadesNacionalidade: [],
  telefoneExtra: [],
  nacionalidades: [],
  mensagens: [],
  showOverlay: false,
  nacionalidadeId: 0,
  referencia: [],
  planos: [],
  etapa: {},
  ultimaMensagem: 0,
  avisoArquivosPendentes: [],
  setNacionalidadeId: (f) => f,
  setNaturalidadeUfId: (f) => f,
  setEnderecoUfId: (f) => f,
  setTelefoneExtra: (f) => f,
  setReferencia: (f) => f,
  calcularPrestacao: async (f) => f,
  fetchCidades: async (f) => f,
  fetchEndereco: async (f) => f,
  salvarPropostaCredito: async (f) => f,
  fetchProposta: async (f) => f,
  fetchMensagens: async (f) => f,
  fetchMensagensParcial: async (f) => f,
  sendMensagem: async (f) => f,
  cancelamentoProposta: async (f) => f,
  setAvisoArquivosPendentes: (f) => f,
  setShowOverlay: (f) => f,
});

function PropostaCreditoProvider({ children }) {
  const [proposta, setProposta] = useState({});
  const [bancos, setBancos] = useState([]);
  const [contas, setContas] = useState([]);
  const [operacoes, setOperacoes] = useState([]);
  const [tempoConta, setTempoConta] = useState([]);
  const [tempoEmpregoAtual, setTempoEmpregoAtual] = useState([]);
  const [tipoConta, setTipoConta] = useState([]);
  const [tipoReferencia, setTipoReferencia] = useState([]);
  const [tipoTelefone, setTipoTelefone] = useState([]);
  const [tipoDebito, setTipoDebito] = useState([]);
  const [sexo, setSexo] = useState([]);
  const [estadoCivil, setEstadoCivil] = useState([]);
  const [tabelaJuros, setTabelaJuros] = useState([]);
  const [ocupacoes, setOcupacoes] = useState([]);
  const [profissoes, setProfissoes] = useState([]);
  const [grauInstrucao, setGrauInstrucao] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [cidadesNacionalidade, setCidadesNacionalidade] = useState([]);
  const [nacionalidades, setNacionalidades] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [nacionalidadeId, setNacionalidadeId] = useState(0);
  const [naturalidadeUfId, setNaturalidadeUfId] = useState(0);
  const [telefoneExtra, setTelefoneExtra] = useState([]);
  const [referencia, setReferencia] = useState([]);
  const [planos, setPlanos] = useState([]);
  const [etapa, setEtapa] = useState({});
  const [mensagens, setMensagens] = useState([]);
  const [camposProposta, setCamposProposta] = useState([]);
  const [ultimaMensagem, setUltimaMensagem] = useState(0);
  const [arquivos, setArquivos] = useState([]);
  const [arquivosDados, setArquivosDados] = useState([]);
  const [avisoArquivosPendentes, setAvisoArquivosPendentes] = useState([]);
  const [enderecoUfId, setEnderecoUfId] = useState(0);
  const [refreshCentralComunicacao, setRefreshCentralComunicacao] = useState(5);
  const [modalidadeContrato, setModalidadeContrato] = useState(0);
  const [maiorValorLimiteParcela, setMaiorValorLimiteParcela] = useState(null);
  const [maiorValorLimite, setMaiorValorLimite] = useState(null);
  const [menorQtndParcelas, setMenorQtndParcelas] = useState(null);
  const [maiorQtndParcelas, setMaiorQtndParcelas] = useState(null);
  const [tabelaSelecionada, setTabelaSelecionada] = useState(null);
  const [blocosVisiveis, setBlocosVisiveis] = useState([]);
  const [dadosOrgao, setDadosOrgao] = useState([]);
  const [tipoAnexos, setTipoAnexos] = useState([]);
  const [totalDebitosNaoComprovados, setTotalDebitosNaoComprovados] =
    useState(0);
  const [calculado, setCalculado] = useState(false);
  const [liberaTelefoneExtraPreencher, setLiberaTelefoneExtraPreencher] =
    useState(false);
  const [cepInvalido, setCepInvalido] = useState(false);
  const [valorSolicitado, setValorSolicitado] = useState();
  const [faturasDescontar, setFaturasDescontar] = useState([]);
  const [listaModal, setListaModal] = useState([]);
  const [dataRecalculo, setDataRecalculo] = useState(null);
  const [diaRecebimento, setDiaRecebimento] = useState();
  const [menorValorLimite, setMenorValorLimite] = useState();
  const [menorValorLimiteParcela, setMenorValorLimiteParcela] = useState();
  const [valores, setValores] = useState([]);
  const [parcelas, setParcelas] = useState([]);
  const [limparValoresLuzEmDia, setLimparValoresLuzEmDia] = useState(false);
  /* Controla se o botão "Enviar Documentos"
   já foi pressionado alguma vez, durante a vida da proposta. */
  const [isSendButtonClickedFirstTime, setIsSendButtonClickedFirstTime] = useState(false);
  const [isSendButtonClicked, setIsSendButtonClicked] = useState(false);
  const [isLoadingDocumentValidation, setIsLoadingDocumentValidation] = useState(false)
  const [responseAcertPix, setResponseAcertPix] = useState({})
  const [reloadUploadForm, setReloadUploadForm] = useState(true);

  const {
    calcularParcelas,
  } = useContext(CalculadoraContext);

  const fetchProposta = useCallback(async (idProposta) => {
    setShowOverlay(true);
    const response = await getPropostaEntradaMesa(idProposta);
    if (response) {
      if (response?.proposta) {
        if (response?.proposta?.cliente?.nacionalidadeId === null) {
          response.proposta.cliente.nacionalidadeId = 1;
        }

        if (!response?.proposta?.operacao?.renda) {
          response.proposta.operacao.renda = 0;
        }

        if (!response?.proposta?.endereco?.cep) {
          response.proposta.endereco.cep = '';
        }

        if (response?.proposta?.bancario) {
          response.proposta.bancario.tipoOperacao = '12';
        }
        if (
          response?.proposta?.operacao?.prestacao &&
          response?.proposta?.operacao?.prazo
        ) {
          response.proposta.operacao.operacao =
            response.proposta.operacao.prestacao *
            response.proposta.operacao.prazo;
        } else {
          response.proposta.operacao.operacao = 0;
        }

        if (response?.proposta?.operacao?.produtoId === 10) {
          setValorSolicitado(response.proposta.operacao.valorContratado);
          response.proposta.operacao.valorContratado =
            response.proposta.operacao.valorLiberado;
        }

        if (!!response?.proposta?.operacao?.exibeModalTabelaInativa) {
          response.proposta.operacao.tabelaJurosId = null;
        }
        response.proposta.cliente.nome = String(response.proposta.cliente.nome).toUpperCase();

        // Transformação de valor de tipo benefício para inteiro, sempre será enviado Número Benefício e Tipo Benefício, garantindo o Tipo Benefício na posição 1 da listagem orgaoDados
        if (response.proposta?.profissional?.orgaoDados?.length > 0) {
          response.proposta.profissional.orgaoDados[1].valor = parseInt(response.proposta?.profissional?.orgaoDados[1]?.valor);
        }

        const tabelaJurosSelecionada = response?.tabelaJuros?.filter(
          (tabela) => tabela.id === response?.proposta?.operacao.tabelaJurosId,
        )[0];

        //Improvement 9962 - Devido a este improvement, tivemos que atualizar os valores com a tratativa do novo valorAjusteParcela retornado pelo crivo antes do formulário ser carregado.

        if (response?.proposta?.resultadoCrivo?.valorAjusteParcela && response?.proposta?.resultadoCrivo?.valorMaximoParcelaConvenio && (response?.proposta?.operacao?.produtoNome === "Energia" || response?.proposta?.operacao?.produtoNome === "Luz em Dia")) {

          const limiteParcelaAjustado = Math.min(response?.proposta?.resultadoCrivo?.valorAjusteParcela, response?.proposta?.resultadoCrivo?.valorMaximoParcelaConvenio);

          await calcularParcelas(
            tabelaJurosSelecionada,
            response?.proposta?.operacao?.produtoId,
            response?.proposta?.operacao?.convenioId,
            response?.proposta?.operacao?.tabelaJurosId,
            null,
            limiteParcelaAjustado
          ).then(async (value) => {
            setParcelas(value.parcelas)
            setValores(value.valores)

            let valorAjusteParcela = response?.proposta?.resultadoCrivo?.valorAjusteParcela > value.parcelas[0]?.valor ? value.parcelas[0]?.valor : response?.proposta?.resultadoCrivo?.valorAjusteParcela

            if ((response?.proposta?.operacao?.prestacao > valorAjusteParcela &&
              response?.proposta?.operacao?.tipoCalculo === 1) ||
              (((response?.proposta?.operacao?.valorContratado > value.valores[0]?.valor ||
                response?.proposta?.operacao?.prestacao > valorAjusteParcela) &&
                response?.proposta?.operacao?.tipoCalculo === 0))) {

              response.proposta.operacao.valorContratado = null
              response.proposta.operacao.floatValorContratado = 0
              response.proposta.operacao.prestacao = null
              response.proposta.operacao.prazo = null
              response.proposta.operacao.valorLiberado = null
              setLimparValoresLuzEmDia(true)

              setMaiorValorLimiteParcela(response?.proposta?.resultadoCrivo?.valorAjusteParcela)
            }
          });
        }
        // Caso não haja tabelaJurosId e existe apenas uma tabelaJuros
        // Essa tabela é anexada a proposta diretamente.
        if (response.tabelaJuros?.length === 1 && !response.proposta?.operacao?.tabelaJurosId) {
          response.proposta.operacao.tabelaJurosId = response.tabelaJuros[0].id;
        }

        setContas(response.conta);

        let bancosLocal = response.bancos;

        bancosLocal.listaBancosSecundarios = bancosLocal?.listaBancosSecundarios?.filter(
          secundario => {
            return !bancosLocal?.listaBancosPrincipais?.some(
              principal => principal?.codigo === secundario?.codigo
            );
          }
        );

        setBancos(bancosLocal);
        setBlocosVisiveis(response.blocosVisiveis);
        setOperacoes(response.tipoOperacao);
        setTempoConta(response.tempoConta);
        setTempoEmpregoAtual(response.tempoEmpregoAtual);
        setTipoConta(response.tipoConta);
        setTipoReferencia(response.tipoReferencia);
        setEstadoCivil(response.estadoCivil);
        setTabelaJuros(response?.tabelaJuros?.filter(item => item.ativo));
        setOcupacoes(response.ocupacoes);
        setProfissoes(response.profissoes);
        setGrauInstrucao(response.grauInstrucao);
        setTipoTelefone(response.tipoTelefone);
        setTipoDebito(response.tipoDebito);
        setSexo(response.sexo);
        setProposta(response?.proposta);

        setNacionalidadeId(response?.proposta?.cliente.nacionalidadeId);
        setNaturalidadeUfId(response?.proposta?.cliente.naturalidadeUfId);
        setEnderecoUfId(response?.proposta?.endereco.ufId);
        setTelefoneExtra(response?.proposta?.contatos.contato.telefoneExtra);
        setTipoAnexos(response?.tipoAnexos);
        setCamposProposta(response?.itens);

        if (response?.orgao) {
          setDadosOrgao(response?.orgao?.filter(item => item.id === response?.proposta?.operacao?.orgaoId)[0]?.orgaoDados)
        }

        setEtapa({
          nome: response?.proposta?.etapaNome,
          motivo: response?.proposta?.motivo,
          cor: response?.proposta?.etapaCor,
        });
        setModalidadeContrato(response?.proposta?.operacao?.tipoModalidade);

        if (response?.proposta?.operacao?.tipoCalculoVencimento === 5) {
          setDiaRecebimento(response?.orgao?.filter(item => item.id === response?.proposta?.operacao?.orgaoId)[0]?.diaRecebimento)
        }
        else {
          setDiaRecebimento(response.diaRecebimento);
        }

        if (response?.proposta?.anexo.length > 0) {
          setIsSendButtonClickedFirstTime(true)
          setIsSendButtonClicked(true)
          let newArquivos = [];
          await response?.proposta?.anexo.forEach(async (arquivo) => {
            newArquivos.push({
              ...arquivo,
              url: ' ',
              uid: arquivo.id,
              type: 'doc',
              enviado: true
            });
          });
          setArquivos(newArquivos);
        }

        const referencias = response?.proposta?.contatos?.referencia
          ? response.proposta.contatos.referencia
          : [];
        for (let i = referencias.length; i < 2; i++) {
          referencias.push(valuesReferencia);
        }
        setReferencia(referencias);




        setTabelaSelecionada(tabelaJurosSelecionada);


      }
    }
    setShowOverlay(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exibirBloco = (name) =>
    blocosVisiveis.find((item) => item.bloco === name)?.visivel;

  const calcularPrestacao = async (produto) => {
    setShowOverlay(true);
    const response = await calculoPrestacao(produto);
    setShowOverlay(false);
    return response;
  };

  const solicitarSimulacaoProposta = async (payload, tipo) => {
    const { data } =
      tipo === 0
        ? await simulacaoValorSolicitado(payload)
        : await simulacaoValorParcela(payload);
    return data;
  };

  const salvarPropostaCredito = async (data) => {
    const response = await atualizarPropostaCredito(data);
    return response;
  };

  const fetchEstados = async () => {
    const response = await listarUfs();
    setEstados(response);
  };

  const fetchPaises = async () => {
    const response = await listarPaises();
    setNacionalidades(response);
    return response;
  };

  const fetchUfs = async () => {
    const response = await listarUfs();
    return response;
  };

  const fetchCidades = async (ufId) => {
    const response = await listarCidades(ufId);
    return response;
  };

  const fetchEndereco = async (cep) => {
    const response = await buscarEndereco(cep);
    if (response?.success) {
      return response.data;
    }
  };

  const fetchListaCidadesNacionalidade = async (id) => {
    const response = await listarCidades(id);
    if (response) {
      setCidadesNacionalidade(response);
    }
  };

  const fetchListaCidades = async (id) => {
    const response = await listarCidades(id);
    setCidades(response);
  };

  const fetchMensagens = useCallback(async (id) => {
    await getPropostaMensagens(id).then((response) => {
      setRefreshCentralComunicacao(response?.refreshCentralComunicacao);
      setMensagens(response?.centralComunicacao);
      setUltimaMensagem(
        Math.max.apply(
          Math,
          response?.centralComunicacao?.map((mensagem) => {
            return mensagem.id;
          }),
        ),
      );
    });
  }, []);

  const fetchMensagensParcial = async (id) => {
    if (ultimaMensagem > 0) {
      await getPropostaMensagensParcial(id, ultimaMensagem).then((response) => {
        if (response) {
          setMensagens(mensagens.concat(response?.centralComunicacao));
          if (response?.centralComunicacao?.length > 0) {
            setUltimaMensagem(
              Math.max.apply(
                Math,
                response?.centralComunicacao?.map((mensagem) => {
                  return mensagem?.id;
                }),
              ),
            );
          }
        } else {
          fetchMensagens(id);
        }
      });
    }
  };

  const sendMensagem = async (mensagem) => {
    const response = await sendPropostaMensagem(mensagem);
    if (response) {
      setMensagens(mensagens.concat(response));
      setUltimaMensagem(response[0].id);
    }
  };

  const validationField = (value, name, label, type) => {
    let msgError = null;

    if (editableField(name)) {
      if (type && value) {
        value = value.trim();
        switch (type) {
          case 'email':
            if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
              msgError = 'Deve ser informado um e-mail válido';
            }
            break;
          case 'telefone':
            if (!validaPhone(value)) {
              msgError = 'Telefone inválido';
            }
            break;
          case 'cpf':
            if (!validaCPF(value?.replace(/\D/g, ''))) {
              msgError = 'CPF inválido';
            }
            break;
          case 'nome':
            if (!value.match(/(\w.+\s).+/)) {
              msgError = 'Nome incompleto';
            }

            if (value.match(/\d+/)) {
              msgError = 'Nome inválido';
            }
            break;
          default:
        }
      }

      if (!type || msgError === '') {
        const campoObrigatorio = camposProposta?.find(
          (el) => el.tag === name && el.obrigatorio,
        );

        if (name === 'endereco.numero') {
          if (!!value && value <= 0) {
            msgError = 'O número deve ser maior do que 0';
          }
        }

        if (!value && campoObrigatorio) {
          msgError = 'Obrigatório informar ' + label;
        }

        if (
          ((name === 'cliente.sexo' ||
            name === 'cliente.estadoCivil' ||
            name === 'bancario.conta' ||
            name === 'bancario.tipoConta' ||
            name === 'bancario.tempoConta' ||
            name === 'profissional.tempoEmpregoAtual') &&
            value === 0) ||
          (name === 'cliente.pep' && value === false)
        ) {
          msgError = null;
        }
      }

      if (msgError === null && name === 'cliente.rgEmissao') {
        const dataAtual = moment().format('YYYY-MM-DD');
        const dataRGEmissao = moment(value).format('YYYY-MM-DD');
        if (dataRGEmissao > dataAtual) {
          msgError =
            'A data de emissão do RG não pode ser superior a data atual';
        }
      }
    }

    return msgError;
  };

  const editableField = (name) => {
    const campoEditavel = camposProposta?.find((el) => el.tag === name);

    return campoEditavel;
  };

  useEffect(() => {
    fetchEstados();
    fetchPaises();
  }, []);

  useEffect(() => {
    if (naturalidadeUfId) {
      fetchListaCidadesNacionalidade(naturalidadeUfId);
    }
  }, [naturalidadeUfId]);

  useEffect(() => {
    if (enderecoUfId) {
      fetchListaCidades(enderecoUfId);
    }
  }, [enderecoUfId]);

  useEffect(() => {
    if (proposta?.validaContratoRefin?.status === 3) {
      if (proposta?.operacao?.tipoCalculo === 0) {
        setListaModal((prev) => {
          return [
            ...prev,
            `Saldo devedor atualizado. Valores anteriores a atualização:
            • Valor Liberado: ${currencyFormat(
              proposta?.operacao?.valorLiberado,
            )}
            • Prazo/Prestação: ${proposta?.operacao?.prazo
            }x de ${currencyFormat(proposta?.operacao?.prestacao)}
            • Valor Solicitado: ${currencyFormat(valorSolicitado)}
            • Valor Saldo devedor: ${currencyFormat(
              proposta?.operacao?.saldoDevedorAnterior,
            )} `,
          ];
        });
      } else {
        setListaModal((prev) => {
          return [
            ...prev,
            `Saldo devedor atualizado. Valores anteriores a atualização:
            • Valor Parcela: ${currencyFormat(proposta?.operacao?.prestacao)}
            • Prazo/Liberado: ${currencyFormat(
              proposta?.operacao?.valorLiberado,
            )} em ${proposta?.operacao?.prazo}x
        • Valor Solicitado: ${currencyFormat(valorSolicitado)}
        • Valor Saldo devedor: ${currencyFormat(
              proposta?.operacao?.saldoDevedorAnterior,
            )} `,
          ];
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposta?.validaContratoRefin]);

  useEffect(() => {
    if (listaModal.length) {
      setTimeout(() => {
        if (listaModal.length > 1) {
          let message =
            '<textarea cols="40" rows="10" readonly style="font-size: 15px; padding: 5px; resize: none;">';
          listaModal.forEach((item) => {
            message += '  • ' + item + '\n\n';
          });
          message += '</textarea>';
          ModalHtml('', message, 'warning');
        } else if (listaModal.length === 1) {
          ModalComponent('', listaModal[0], 'warning');
        }
      }, 3000);
    }
  }, [listaModal]);

  return (
    <PropostaCreditoContext.Provider
      value={{
        proposta,
        bancos,
        contas,
        tempoConta,
        tempoEmpregoAtual,
        tipoConta,
        tipoReferencia,
        tipoDebito,
        estadoCivil,
        sexo,
        tabelaJuros,
        ocupacoes,
        profissoes,
        grauInstrucao,
        tipoTelefone,
        estados,
        cidades,
        cidadesNacionalidade,
        telefoneExtra,
        nacionalidades,
        showOverlay,
        nacionalidadeId,
        referencia,
        mensagens,
        planos,
        etapa,
        refreshCentralComunicacao,
        ultimaMensagem,
        arquivos,
        arquivosDados,
        operacoes,
        camposProposta,
        avisoArquivosPendentes,
        dadosOrgao,
        setShowOverlay,
        validationField,
        editableField,
        fetchPaises,
        fetchUfs,
        fetchCidades,
        fetchEndereco,
        getPropostaEntradaMesa,
        calcularPrestacao,
        setNacionalidadeId,
        setNaturalidadeUfId,
        setEnderecoUfId,
        setTelefoneExtra,
        setReferencia,
        salvarPropostaCredito,
        fetchProposta,
        fetchMensagens,
        fetchMensagensParcial,
        sendMensagem,
        carregarImagem,
        setArquivos,
        setArquivosDados,
        setPlanos,
        cancelamentoProposta,
        reanaliseProposta,
        setAvisoArquivosPendentes,
        setProposta,
        aprovacaoPromotora,
        modalidadeContrato,
        setModalidadeContrato,
        fetchListaCidades,
        maiorValorLimiteParcela,
        setMaiorValorLimiteParcela,
        maiorValorLimite,
        setMaiorValorLimite,
        menorQtndParcelas,
        setMenorQtndParcelas,
        maiorQtndParcelas,
        setMaiorQtndParcelas,
        solicitarSimulacaoProposta,
        tabelaSelecionada,
        setTabelaSelecionada,
        exibirBloco,
        totalDebitosNaoComprovados,
        setTotalDebitosNaoComprovados,
        calculado,
        setCalculado,
        liberaTelefoneExtraPreencher,
        setLiberaTelefoneExtraPreencher,
        dataRecalculo,
        setDataRecalculo,
        cepInvalido,
        setCepInvalido,
        tipoAnexos,
        reenviarSMS,
        faturasDescontar,
        setFaturasDescontar,
        listaModal,
        setListaModal,
        diaRecebimento,
        menorValorLimite,
        setMenorValorLimite,
        menorValorLimiteParcela,
        setMenorValorLimiteParcela,
        setValores,
        valores,
        setParcelas,
        parcelas,
        limparValoresLuzEmDia,
        setLimparValoresLuzEmDia,
        isSendButtonClickedFirstTime,
        setIsSendButtonClickedFirstTime,
        isSendButtonClicked,
        setIsSendButtonClicked,
        isLoadingDocumentValidation,
        setIsLoadingDocumentValidation,
        setResponseAcertPix,
        responseAcertPix,
        postAcertPixDocOCR,
        postConsultaDocOCR,
        reloadUploadForm,
        setReloadUploadForm
      }}
    >
      {children}
    </PropostaCreditoContext.Provider>
  );
}

export default PropostaCreditoProvider;
