import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Button, Modal as ModalAntd } from 'antd';
import { useFormik, FormikProvider } from 'formik';
import { Form } from 'formik-antd';

import Card from '../../../components/Card';
import ButtonComponent from '../../../components/Button';
import Overlay from '../../../components/Overlay';
import Title from '../../../components/Title';
import Tooltip from '../../../components/tooltip';
import Tag from '../../../components/Tag';
import Modal from '../../../components/Modal';
import ModalConfirmation from '../../../components/ModalConfirmation';
import TagButton from '../../../components/Button';
import Spinner from '../../../components/Spinner';

import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import CalculadoraProvider from '../../../contexts/CalculadoraContext';

import { ReactComponent as IconCadastrar } from '../../../assets/images/icons/cadastrar-proposta-credito.svg';
import { ReactComponent as IconAprovacao } from '../../../assets/images/icons/icon-discador-check.svg';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/remove.svg';
import { ReactComponent as IconRemoveOutline } from '../../../assets/images/icons/negar-outline.svg';
import { ReactComponent as Cellphone } from '../../../assets/images/icons/cellphone.svg';

import { DadosOperacaoForm } from './DadosOperacaoForm';
import { DadosLuzDiaForm } from './DadosLuzDiaForm';
import { DadosPessoaisForm } from './DadosPessoaisForm';
import { DadosContatoForm } from './DadosContatoForm';
import { DadosEnderecoForm } from './DadosEnderecoForm';
import { DadosBancariosForm } from './DadosBancariosForm';
import { DadosReferenciasForm } from './DadosReferenciasForm';
import { DadosProfissionaisForm } from './DadosProfissionaisForm';
import { DadosUploadForm } from './DadosUploadForm';
import { DadosMatrizForm } from './DadosMatrizForm';
import { DadosVendedorForm } from './DadosVendedorForm';

import ModalTabelaInativa from '../../../components/ModalTabelaInativa';

import { initialValues } from './initialValues';

import validaPhone from '../../../utils/validaPhone';

import './styles.scss';
import { TipoCalculoVencimento } from '../../../utils/identificadoresFrontBack';
import swal from 'sweetalert';

function TooltipStatus({ children, color }) {
  return (
    <Tag className="tag-color" color={color}>
      <Tooltip color={color}>{children}</Tooltip>
    </Tag>
  );
}

function PropostaCreditoForm({ propostaId }) {
  const history = useHistory();

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  const {
    proposta,
    showOverlay,
    tipoDebito,
    salvarPropostaCredito,
    fetchProposta,
    fetchMensagens,
    fetchMensagensParcial,
    ultimaMensagem,
    mensagens,
    refreshCentralComunicacao,
    carregarImagem,
    getImagem,
    arquivos,
    cancelamentoProposta,
    tabelaSelecionada,
    setAvisoArquivosPendentes,
    aprovacaoPromotora,
    modalidadeContrato,
    exibirBloco,
    totalDebitosNaoComprovados,
    setTotalDebitosNaoComprovados,
    dataRecalculo,
    cepInvalido,
    tipoAnexos,
    reenviarSMS,
    editableField,
    isSendButtonClickedFirstTime,
    isSendButtonClicked,
    responseAcertPix,
    setResponseAcertPix,
    isLoadingDocumentValidation,
    setArquivos,
    setReloadUploadForm,
    reloadUploadForm,
    postConsultaDocOCR
  } = useContext(PropostaCreditoContext);

  const navigateToAcompanhamentoProposta = () => {
    history.push('/acompanhamento-proposta');
  };

  const containerScrollRef = useRef(null);
  const containerInternoScrollRef = useRef(null);
  const btnFlutuanteRef = useRef(null);
  const [modalAprovacaoVisible, setModalAprovacaoVisible] = useState(false);
  const [tipoModalAprovacao, setTipoModalAprovacao] = useState('');

  const [errorDigitalTelefoneCelular, setErrorDigitalTelefoneCelular] =
    useState(false);
  const isLoadingDocumentValidationRef = useRef(isLoadingDocumentValidation);
  const responseAcertPixRef = useRef(responseAcertPix);

  const [modalAguardeCadastrar, setModalAguardeCadastrar] = useState(false);
  const [exibeBotaoCadastrar, setExibeBotaoCadastrar] = useState(false);
  const [quantidadeTentativasAnexarDoc, setQuantidadeTentativasAnexarDoc] = useState(0);
  const [quantidadeTentativasAnexarFatura, setQuantidadeTentativasAnexarFatura] = useState(0);

  const openModalAprovacao = (tipoModal) => {
    setModalAprovacaoVisible(true);
    setTipoModalAprovacao(tipoModal);
  };

  const formik = useFormik({
    initialValues,
  });

  const [recalculoTabelaJuros, setRecalculoTabelaJuros] = useState(false);

  const [visibleModalTabelaInativa, setVisibleModalTabelaInativa] =
    useState(false);

  const { setValues, setTouched, setFieldValue } = formik;

  const waitForAcertPix = (needWaitResponse) => {
    return new Promise(resolve => {
      const check = () => {
        if (!isLoadingDocumentValidationRef.current ||
          (needWaitResponse && Object.keys(responseAcertPixRef.current).length > 0)
        ) {
          resolve();
        } else {
          setTimeout(check, 100);
        }
      };
      check();
    });
  };

  const hasOCRResult = (value, key) => (
      value?.[key]?.resultadoAnaliseOCR
  )

  const isShowModal = (value, key, attempts) => (
      value?.[key]?.resultadoAnaliseOCR &&
      value?.[key]?.exibeModalDocumento &&
      attempts
  )

  const deleteDocumentByKey = (key, arquivos) => {
    const idDocument = tipoAnexos?.find(arquivo => arquivo.nome === key).id;


    const newArquivos = arquivos?.filter(
      a => !!a.id || a.documentoId !== idDocument
    );

    return newArquivos;
 }

 const updateArquivos = (arquivos) => {
    setArquivos(arquivos)
    setReloadUploadForm(!reloadUploadForm);
 }


  const validaAcertPix = async () => {
    let responseAcertPix;

    await waitForAcertPix(true);

    responseAcertPix = responseAcertPixRef.current;

    const aguardaWebhookAcertPix = Object.keys(responseAcertPix).some(
      (key) => responseAcertPix[key]?.aguardarWebhookAcertpix,
    );

    const analiseOCR = proposta?.analiseOCR;
    const validaDocumentoOCR = Object.keys(analiseOCR).some(
      (key) => analiseOCR[key],
    );

    if (validaDocumentoOCR && aguardaWebhookAcertPix) {
      const payload = {
        propostaId: proposta.id,
        analiseOCR: {
          documentosIdentificacao: responseAcertPix?.documentosIdentificacao
            ?.chaveAcertpix
            ? responseAcertPix?.documentosIdentificacao?.chaveAcertpix
            : null,
          faturaEnergia: responseAcertPix?.faturaEnergia?.chaveAcertpix
            ? responseAcertPix?.faturaEnergia?.chaveAcertpix
            : null,
        },
      };

      let responseConsulta = await postConsultaDocOCR(payload);

      const validacaoEsperaAcertPix =
        !(
          hasOCRResult(responseConsulta, 'documentosIdentificacao') ||
          hasOCRResult(responseConsulta, 'faturaEnergia')
        ) && isLoadingDocumentValidation;

      if (validacaoEsperaAcertPix) {
        await waitForAcertPix();
        responseConsulta = await postConsultaDocOCR(payload);
      }

      const validaTentativasAnexarDoc =
        proposta?.parametrosOCR?.quantidadeTentativasAnexarDoc >
        quantidadeTentativasAnexarDoc;
      const validaTentativasAnexarFatura =
        proposta?.parametrosOCR?.quantidadeTentativasAnexarFatura >
        quantidadeTentativasAnexarFatura;

      if (
        isShowModal(
          responseConsulta,
          'documentosIdentificacao',
          validaTentativasAnexarDoc,
        ) &&
        isShowModal(
          responseConsulta,
          'faturaEnergia',
          validaTentativasAnexarFatura,
        )
      ) {
        const responseModal = await swal(
          '',
          `Os seguintes itens precisam de atenção:


                  1. Não foi possível validar a Fatura de Energia. Verificar e anexar o documento correto;

                  2. Não foi possível validar o documento de identificação. O documento anexado é carteira de trabalho?`,
          'warning',
          {
            buttons: ['Não', 'Sim'],
            closeOnClickOutside: false,
          },
        );

        if (!responseModal) {
          const filtroArquivosIdentificacao = deleteDocumentByKey('DOCUMENTO DE IDENTIFICAÇÃO', arquivos);
          const filtroArquivosTotal = deleteDocumentByKey('FATURA DE ENERGIA', filtroArquivosIdentificacao);
          updateArquivos(filtroArquivosTotal)
          setQuantidadeTentativasAnexarDoc(quantidadeTentativasAnexarDoc + 1);
          setQuantidadeTentativasAnexarFatura(
            quantidadeTentativasAnexarFatura + 1,
          );
        } else {
          const filtroArquivosEnergia = deleteDocumentByKey('FATURA DE ENERGIA', arquivos);
          updateArquivos(filtroArquivosEnergia);
          setQuantidadeTentativasAnexarFatura(
            quantidadeTentativasAnexarFatura + 1,
          );
        }

        setModalAguardeCadastrar(false);
        return false;
      } else if (
        isShowModal(
          responseConsulta,
          'documentosIdentificacao',
          validaTentativasAnexarDoc,
        )
      ) {
        const responseModal = await swal(
          '',
          'Não foi possível validar o documento de identificação. O documento anexado é carteira de trabalho?',
          'warning',
          {
            buttons: ['Não', 'Sim'],
            closeOnClickOutside: false,
          },
        );

        if (!responseModal) {
          Modal(
            '',
            'Favor anexar documento de identificação válido (RG, CNH, CTPS).',
            'warning',
            () => {
              const filtroArquivosIdentificacao = deleteDocumentByKey('DOCUMENTO DE IDENTIFICAÇÃO', arquivos);
              updateArquivos(filtroArquivosIdentificacao);
              setQuantidadeTentativasAnexarDoc(
                quantidadeTentativasAnexarDoc + 1,
              );
            },
          );
          setModalAguardeCadastrar(false);
          return false;
        }
      } else if (
        isShowModal(
          responseConsulta,
          'faturaEnergia',
          validaTentativasAnexarFatura,
        )
      ) {
        Modal(
          '',
          'Não foi possível validar a Fatura de Energia. Verificar e anexar o documento correto.',
          'warning',
          () => {
            const filtroArquivosEnergia = deleteDocumentByKey('FATURA DE ENERGIA', arquivos);
            updateArquivos(filtroArquivosEnergia);
            setQuantidadeTentativasAnexarFatura(
              quantidadeTentativasAnexarFatura + 1,
            );
          },
        );
        setModalAguardeCadastrar(false);
        return false;
      }
    }

    return true;
  };

  const handleEnvioFormulario = async () => {
    if (!isSendButtonClicked && arquivos.some(a => !a.id && !a.enviado)) {
      Modal(
        "",
        `Clique no botão "Enviar Documento" antes de seguir com a proposta!`,
        "warning"
      );
      return;
    }

    if (proposta?.validaContratoRefin?.status === 1) {
      Modal('', proposta?.validaContratoRefin?.mensagem, 'warning');
    } else if (
      tabelaSelecionada &&
      proposta.operacao.tipoCalculoVencimento !== TipoCalculoVencimento.ENERGIA &&
      proposta.operacao.tipoCalculoVencimento !== TipoCalculoVencimento.CONSIGNADO_PRIVADO &&
      (moment(formik?.values?.operacao?.vencimento) <
        moment().add(tabelaSelecionada?.carenciaMinima, 'd').startOf('day') ||
        moment(formik?.values?.operacao?.vencimento) >
        moment().add(tabelaSelecionada?.carenciaMaxima, 'd').startOf('day'))
    ) {
      Modal('', 'Data de vencimento fora do período de carência', 'warning');
    } else if (cepInvalido) {
      Modal('Atenção', 'CEP inválido ou não encontrado.', 'warning');
      formik.setFieldError('endereco.cep', 'CEP inválido ou não encontrado');
    } else {
      const errors = await formik
        .validateForm()
        .then((errors) => setTouched({ ...errors }, true));

      formik.setTouched(errors, true);

      if (!Object.keys(errors).length) {
        const changedData = JSON.parse(JSON.stringify(formik.values));
        const anexo = [];

        changedData?.operacao?.dadosAdicionais?.map((item) => {
          return (item.valor ? item.valor = String(item.valor) : item.valor);
        });

        changedData?.profissional?.orgaoDados?.map((item) => {
          return (item.valor = String(item.valor));
        });

        const arquivosObrigatoriosPendentes = tipoAnexos?.filter((arquivo) => {
          if (
            (arquivo.tipoModalidade === changedData?.operacao?.tipoModalidade ||
              !arquivo.tipoModalidade) &&
            (arquivo.tipoRenda === changedData?.operacao?.tipoRenda ||
              !arquivo.tipoRenda) &&
            arquivo.obrigatorio
          ) {
            if (totalDebitosNaoComprovados > 0 && arquivo.luzEmDia) {
              return (
                arquivo.produtoId === proposta?.operacao?.produtoId &&
                !arquivos.find((item) => item.documentoId === arquivo.id)
              );
            } else if (totalDebitosNaoComprovados === 0 && !arquivo.luzEmDia) {
              return !arquivos.find((item) => item.documentoId === arquivo.id);
            }
          }
          return false;
        });
        setAvisoArquivosPendentes(arquivosObrigatoriosPendentes);

        if (
          changedData?.operacao?.tipoCalculo &&
          changedData?.operacao?.valorSolicitado &&
          parseFloat(changedData?.operacao?.valorSolicitado?.toFixed(2)) < 0
        ) {
          Modal(
            'Atenção',
            'Não é possível cadastrar uma proposta com valor solicitado inferior ao saldo devedor total.',
            'warning',
          );
        } else {
          let erro = false;
          arquivos.forEach((arquivo) => {
            if (arquivo.documentoId === null) {
              erro = true;
            }
            anexo.push({
              id: arquivo.id,
              imagemId: arquivo.imagemId,
              documentoId: arquivo.documentoId,
            });
          });

          let telefoneCelular = 0;
          let debitosAcimaDoLimite = false;
          let faturasSemAnexo = 0;
          let calculoValorLimite;

          if (changedData?.historicoCia?.debitosConveniada !== null) {
            if (changedData.elegivelLuzEmDia === 1 && !!proposta?.exibeDebitos) {
              changedData?.historicoCia?.debitosConveniada.forEach((fatura) => {
                fatura.participa &&
                  !fatura.existeComprovante &&
                  faturasSemAnexo++;
              });
            } else if (
              (changedData.elegivelLuzEmDia === 0 ||
                changedData.elegivelLuzEmDia === 2) &&
              proposta?.operacao?.produtoNome === 'Luz em Dia' &&
              !!proposta?.exibeDebitos
            ) {
              changedData?.historicoCia?.debitosConveniada.forEach((fatura) => {
                fatura.participa &&
                  !fatura.existeComprovante &&
                  !fatura.descontar &&
                  faturasSemAnexo++;
              });
            }

            if(changedData?.resultadoCrivo?.percentualMaxLimite){
              calculoValorLimite =
                (changedData.operacao.tipoCalculo === 0
                  ? changedData.operacao.valorContratado
                  : changedData.operacao.valorSolicitado) *
                (changedData?.resultadoCrivo?.percentualMaxLimite / 100);
              let somaDebitos = changedData.historicoCia?.debitosConveniada
                ?.filter((fatura) => fatura.descontar === true)
                ?.reduce((acc, faturaAtual) => acc + faturaAtual.valor, 0);

              debitosAcimaDoLimite = somaDebitos > calculoValorLimite;
            }
          }

          if (arquivosObrigatoriosPendentes.length > 0) {
            Modal(
              '',
              'Existem documentos obrigatórios que estão pendentes.',
              'warning',
            );
          } else if (faturasSemAnexo) {
            Modal(
              '',
              'É necessário anexar os comprovantes das faturas para prosseguir.',
              'warning',
            );
          } else if (debitosAcimaDoLimite) {
            Modal(
              '',
              `Os débitos atuais do cliente ultrapassaram o limite de R$ ${calculoValorLimite} para prosseguimento da proposta.`,
              'warning',
            );
          } else {
            if (changedData?.contatos?.telefoneAdicional?.length) {
              changedData.contatos.telefoneAdicional.forEach((contato) => {
                changedData.contatos.contato.telefoneExtra = [
                  ...changedData.contatos.contato.telefoneExtra,
                  contato,
                ];
              });
              delete changedData.contatos.telefoneAdicional;
            }

            if (changedData?.contatos?.telefoneAdicionalReferencia?.length) {
              changedData.contatos.telefoneAdicionalReferencia.forEach(
                (contato) => {
                  changedData.contatos.referencia = [
                    ...changedData.contatos.referencia,
                    contato,
                  ];
                },
              );
              delete changedData.contatos.telefoneAdicionalReferencia;
            }

            if (changedData.operacao.tipoModalidade === 2) {
              setErrorDigitalTelefoneCelular(false);

              validaPhone(changedData.contatos.contato.telefone) === 1 &&
                telefoneCelular++;

              if (changedData?.contatos?.contato?.telefoneExtra?.length > 0) {
                changedData.contatos.contato.telefoneExtra.forEach(
                  (telefone) => {
                    validaPhone(telefone.telefone) === 1 && telefoneCelular++;
                  },
                );
              }

              if (telefoneCelular === 0) {
                setErrorDigitalTelefoneCelular(true);
                Modal(
                  '',
                  'Telefone celular obrigatório para a modalidade digital',
                  'warning',
                );
              }
            }

            if (
              changedData.operacao.tipoModalidade !== 2 ||
              telefoneCelular !== 0
            ) {
              if (changedData.unidade.celularVendedor) {
                changedData.unidade.celularVendedor =
                  changedData.unidade.celularVendedor?.replace(/\D/g, '');
              }

              changedData.profissional.renda = changedData.profissional
                .floatRenda
                ? changedData.profissional.floatRenda
                : null;
              changedData.profissional.outrasRendas = changedData.profissional
                .floatOutrasRendas
                ? changedData.profissional.floatOutrasRendas
                : null;

              changedData.tipoModalidade = modalidadeContrato;

              if (erro) {
                Modal(
                  '',
                  'Existem anexos cujo tipo de documento não foi preenchido',
                  'warning',
                );
              } else if (!validaPhone(changedData.unidade.celularVendedor)) {
                Modal('', 'Telefone do vendedor é inválido', 'warning');
              } else if (!arquivosObrigatoriosPendentes.length) {
                setModalAguardeCadastrar(true);

                changedData.anexo = anexo;

                changedData.contatos.contato.telefone =
                  changedData.contatos.contato.telefone?.replace(/\D/g, '');
                changedData.contatos.contato.telefoneFixo =
                  changedData.contatos.contato.telefoneFixo?.replace(/\D/g, '');

                if (
                  changedData.contatos.contato.telefoneExtra &&
                  changedData.contatos.contato.telefoneExtra.length > 0
                ) {
                  changedData.contatos.contato.telefoneExtra =
                    changedData.contatos.contato.telefoneExtra.map(
                      (telefoneExtra) => ({
                        ...telefoneExtra,
                        telefone: telefoneExtra.telefone.replace(/\D/g, ''),
                      }),
                    );
                } else {
                  changedData.contatos.contato.telefoneExtra = null;
                }

                if (
                  changedData.contatos.referencia &&
                  changedData.contatos.referencia.length > 0
                ) {
                  changedData.contatos.referencia =
                    changedData.contatos.referencia.map((referencia) => ({
                      ...referencia,
                      telefone: referencia?.telefone?.replace(/\D/g, ''),
                      nome: referencia.nome ? referencia.nome : '',
                    }));
                }

                if (changedData.unidade.celularVendedor) {
                  changedData.unidade.celularVendedor =
                    changedData.unidade.celularVendedor?.replace(/\D/g, '');
                }

                if (changedData.endereco.cep) {
                  changedData.endereco.cep = changedData.endereco.cep?.replace(
                    /\D/g,
                    '',
                  );
                }

                changedData.profissional.renda =
                  changedData.profissional.floatRenda;
                changedData.profissional.outrasRendas =
                  changedData.profissional.floatOutrasRendas;

                changedData.tipoModalidade = modalidadeContrato;

                if (
                  changedData.operacao.tipoCalculo === 1 &&
                  changedData.operacao.valorSolicitado !== ''
                ) {
                  changedData.operacao.valorContratado =
                    changedData.operacao.valorSolicitado;
                }

                // solução adotada para corrigir o envio de valor contratado undefined
                // quando a proposta é simulada pelo valor de parcela
                if (
                  changedData?.operacao?.valorContratado === undefined &&
                  changedData?.operacao?.tipoCalculo === 1
                ) {
                  changedData.operacao.valorContratado =
                    changedData?.operacao?.valorLiberado;
                }

                const isLuzEmDia = proposta?.operacao?.produtoId === 12 &&
                  totalDebitosNaoComprovados > 0 &&
                  proposta?.elegivelLuzEmDia === 0

                if (
                  !editableField('operacao.valorContratado') &&
                  isLuzEmDia
                ) {
                  changedData.operacao.valorContratado =
                    proposta?.operacao?.valorContratado;
                }

                if (changedData.operacao.produtoId === 10) {
                  changedData.operacao.valorContratado =
                    changedData.operacao.valorLiberado +
                    changedData.operacao.saldoDevedor;

                  if (changedData.operacao.tipoCalculo === 1) {
                    changedData.operacao.valorSolicitado =
                      changedData.operacao.valorContratado;
                  }
                }

                changedData.historicoCia.debitosConveniada = changedData?.historicoCia?.debitosConveniada?.map((fatura) => {
                  if (fatura?.descontar) {
                    delete fatura.comprovanteId;
                  }
                  return fatura;
                });

                setModalAguardeCadastrar(true);

                changedData.operacao.recalculo = dataRecalculo
                  ? moment(dataRecalculo).format('YYYY-MM-DDTHH:mm:ss')
                  : null;

                if (changedData.unidade.cpfVendedor === '')
                  changedData.unidade.cpfVendedor = null;


                // Lógica AcertPix
                const isAcertPixValid = await validaAcertPix(responseAcertPix)
                setResponseAcertPix({})
                if (!isAcertPixValid) return;
                // Encerra Lógica AcertPix

                const response = await salvarPropostaCredito(changedData);

                if (response?.data?.integracao[0]?.response?.Aprovado === false) {
                  Modal(
                    '',
                    response?.data?.integracao[0]?.response?.Mensagem,
                    'error',
                    () =>
                      history.push(`/acompanhamento-proposta`),
                    undefined,
                    false
                  );
                }
                else if (response.success) {
                  setModalAguardeCadastrar(false);
                  Modal(
                    '',
                    'Proposta atualizada com sucesso!',
                    'success',
                    navigateToAcompanhamentoProposta,
                  );
                } else {
                  setModalAguardeCadastrar(false);
                  Modal('', response.errors.join(' \n \n '), 'warning');
                }
              }
            }
          }
        }
      } else {
        Modal(
          '',
          'Existem campos obrigatórios que ainda não foram preenchidos. Favor verificar e tentar novamente.',
          'warning',
        );
      }
    }
  };

  const scrollContainerForm = () => {
    var a = document.activeElement;
    if (
      a.parentElement.classList.value === 'ant-form-item-control-input-content'
    ) {
      a.blur();
    }
    if (btnFlutuanteRef.current) {
      if (
        parseInt(containerScrollRef.current.scrollTop) + parseInt(550) >=
        containerInternoScrollRef.current.offsetHeight
      ) {
        btnFlutuanteRef.current.style.display = 'none';
      } else {
        btnFlutuanteRef.current.style.display = 'block';
      }
    }
  };

  const openModalCancelarProposta = () => {
    ModalConfirmation(
      'Atenção!',
      `Confirmar o cancelamento da proposta ${proposta.id}?`,
      'warning',
      () => handleCancelarProposta(),
    );
  };

  const handleCancelarProposta = async () => {
    const response = await cancelamentoProposta(proposta.id);
    if (response?.success) {
      Modal('', `Proposta cancelada com sucesso`, 'success', () =>
        window.location.reload(),
      );
    }
  };

  const handleAprovarNegarProposta = async (status) => {
    const payload = {
      codigoStatus: String(status),
      propostas: [
        {
          codproposta: proposta?.id,
        },
      ],
    };

    setModalAprovacaoVisible(false);

    const data = await aprovacaoPromotora(payload);

    if (data?.proposta) {
      Modal(
        '',
        status === 11
          ? `Proposta aprovada com sucesso`
          : `Proposta negada com sucesso`,
        'success',
        () => navigateToAcompanhamentoProposta(),
      );
    }
  };

  useEffect(() => {
    // Atualiza a referencia do loading do acionamento da acertpix e da resposta.
    isLoadingDocumentValidationRef.current = isLoadingDocumentValidation;
    responseAcertPixRef.current = responseAcertPix
  }, [isLoadingDocumentValidation, responseAcertPix]);

  useEffect(() => {
    fetchProposta(propostaId);
    fetchMensagens(propostaId);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [propostaId]);

  useEffect(() => {
    const refreshMessages = setInterval(async () => {
      await fetchMensagensParcial(propostaId);
    }, refreshCentralComunicacao * 1000);

    return () => clearInterval(refreshMessages);
  }, [mensagens, ultimaMensagem]);

  useEffect(() => {
    if (proposta) {
      setValues({
        ...proposta,
        operacao: {
          ...proposta?.operacao,
          valorContratado:
            proposta?.operacao?.tipoCalculo === 0
              ? proposta?.operacao?.valorContratado
              : proposta?.operacao?.prestacao,
        },
      });
    }
    if ((checarPermissao(
      'botao',
      'botao.propostaCredito.cadastrarProposta',
      'Visualizar') && Object.keys(proposta).length !== 0)) {
      setExibeBotaoCadastrar(true)
    }

  }, [proposta, setValues]);

  const handleReenvioSMS = async () => {
    await reenviarSMS(proposta?.id).then((response) => {
      if (response.success) {
        Modal('', 'SMS reenviado com sucesso', 'success');
      }
    });
  };

  useEffect(() => {
    if (!!proposta?.operacao?.exibeModalTabelaInativa) {
      setVisibleModalTabelaInativa(true);
    }
  }, [proposta]);

  useEffect(() => {
    const debitosConveniada = proposta?.historicoCia?.debitosConveniada;
    const somaDebitosNaoComprovados = debitosConveniada?.reduce(
      (acc, faturaAtual) =>
        acc +
        (!faturaAtual.descontar && faturaAtual.existeComprovante
          ? 0
          : faturaAtual.valor),
      0,
    );
    setTotalDebitosNaoComprovados(somaDebitosNaoComprovados || 0);
  }, [proposta?.historicoCia?.debitosConveniada]);

  return (
    <>
      <FormikProvider value={formik}>
        <ModalTabelaInativa
          visible={visibleModalTabelaInativa}
          setVisible={setVisibleModalTabelaInativa}
          setFormValue={setFieldValue}
          setRecalculoTabelaJuros={setRecalculoTabelaJuros}
          values={{
            valorLiberado: proposta?.operacao?.valorLiberado,
            prazo: proposta?.operacao?.prazo,
            parcela: proposta?.operacao?.prestacao,
            valorSolicitado: proposta?.operacao?.valorContratado,
            saldoDevedor: proposta?.operacao?.saldoDevedor,
          }}
        />
        <Overlay active={showOverlay} className="overlay-loading">
          <Card
            title={<Title label="Proposta de Crédito" />}
            rightBar={
              <>
                <div className="d-flex justify-content-end align-items-center">
                  {proposta.situacaoId !== 14 ? (
                    <>
                      {checarPermissao(
                        'botao',
                        'botao.propostaCredito.cancelarProposta',
                        'Visualizar',
                      ) &&
                        !!proposta?.permiteCancelamento && (
                          <>
                            <TagButton
                              className="tag-btn"
                              variant="red"
                              disabled={
                                !checarPermissao(
                                  'botao',
                                  'botao.propostaCredito.cancelarProposta',
                                  'Editar',
                                )
                              }
                              onClick={() => openModalCancelarProposta()}
                            >
                              Cancelar
                            </TagButton>
                          </>
                        )}

                      <TooltipStatus color={proposta.situacaoCor}>
                        {proposta.situacaoDescricao}
                      </TooltipStatus>
                    </>
                  ) : (
                    <>
                      {checarPermissao(
                        'botao',
                        'botao.propostaCredito.aprovarProposta',
                        'Visualizar',
                      ) && (
                          <>
                            <ButtonComponent
                              type="button"
                              className="btn-aprovar-proposta"
                              onClick={() => openModalAprovacao('aprovacao')}
                            >
                              <IconAprovacao className="mr-2" width={15} />
                              Aprovar Proposta
                            </ButtonComponent>
                          </>
                        )}

                      <ButtonComponent
                        type="button"
                        className="btn-negar-proposta"
                        onClick={() => openModalAprovacao('negacao')}
                      >
                        <IconRemove width={15} className="mr-2" />
                        Negar Proposta
                      </ButtonComponent>
                    </>
                  )}
                  {checarPermissao(
                    'botao',
                    'botao.propostaCredito.reenviarSMS',
                    'Visualizar',
                  ) &&
                    proposta?.permiteReenvioSMS && (
                      <Button
                        className="btnReenvio"
                        type="button"
                        variant="white"
                        onClick={handleReenvioSMS}
                      >
                        <Cellphone />
                        Reenviar SMS
                      </Button>
                    )}
                </div>
              </>
            }
          >
            <Form layout="vertical" autoComplete="off" disabled>
              <div
                className="container-scroll-form"
                onScroll={scrollContainerForm}
                ref={containerScrollRef}
              >
                <div
                  className="container-interno-scroll-form"
                  ref={containerInternoScrollRef}
                >

                  {exibirBloco('anexo') && <DadosUploadForm formik={formik} />}

                  <CalculadoraProvider>
                    {exibirBloco('operacao') && (
                      <DadosOperacaoForm
                        formik={formik}
                        recalculoTabelaJuros={recalculoTabelaJuros}
                        disabledFields={!isSendButtonClickedFirstTime}
                      />
                    )}
                    {!!proposta?.exibeDebitos &&
                      proposta?.historicoCia?.debitosConveniada?.length > 0 &&
                      proposta?.elegivelLuzEmDia <= 1 && (
                        <DadosLuzDiaForm
                          formik={formik}
                          carregarImagem={carregarImagem}
                          getImagem={getImagem}
                          tipoDebito={tipoDebito}
                          totalDebitosNaoComprovados={
                            totalDebitosNaoComprovados
                          }
                          setTotalDebitosNaoComprovados={
                            setTotalDebitosNaoComprovados
                          }
                        />
                      )}
                  </CalculadoraProvider>

                  {exibirBloco('cliente') && (
                    <DadosPessoaisForm
                      formik={formik}
                      disabledFields={!isSendButtonClickedFirstTime}
                    />
                  )}

                  {exibirBloco('contatos') && (
                    <DadosContatoForm
                      formik={formik}
                      errorDigitalTelefoneCelular={errorDigitalTelefoneCelular}
                      disabledFields={!isSendButtonClickedFirstTime}
                    />
                  )}

                  {exibirBloco('endereco') && (
                    <DadosEnderecoForm
                      formik={formik}
                      disabledFields={!isSendButtonClickedFirstTime}
                    />
                  )}

                  {exibirBloco('bancario') && (
                    <DadosBancariosForm
                      formik={formik}
                      disabledFields={!isSendButtonClickedFirstTime}
                    />
                  )}

                  {exibirBloco('contatos') && (
                    <DadosReferenciasForm
                      formik={formik}
                      disabledFields={!isSendButtonClickedFirstTime}
                    />
                  )}

                  {exibirBloco('profissional') && (
                    <DadosProfissionaisForm
                      formik={formik}
                      disabledFields={!isSendButtonClickedFirstTime}
                    />
                  )}

                  {exibirBloco('unidade') && (
                    <>
                      <DadosMatrizForm
                        disabledFields={!isSendButtonClickedFirstTime}
                      />
                      <DadosVendedorForm
                        formik={formik}
                        disabledFields={!isSendButtonClickedFirstTime}
                      />
                    </>
                  )}

                  {exibeBotaoCadastrar && (
                    <div className="form-row pt-3">
                      <div className="col-lg-12 text-right">
                        <Button
                          className="btn-cadastrar"
                          type="primary"
                          disabled={!acesso || !isSendButtonClickedFirstTime}
                          onClick={() => {
                            handleEnvioFormulario();
                          }}
                        >
                          <IconCadastrar /> CADASTRAR
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {exibeBotaoCadastrar && (
                <Button
                  className="btn-cadastrar btn-flutuante"
                  onClick={() => {
                    handleEnvioFormulario();
                  }}
                  type="primary"
                  disabled={!acesso || !isSendButtonClickedFirstTime}
                  ref={btnFlutuanteRef}
                >
                  <IconCadastrar />
                </Button>
              )}
            </Form>
          </Card>
          <ModalAntd
            visible={modalAprovacaoVisible}
            onCancel={() => setModalAprovacaoVisible(false)}
            centered
            footer={null}
            width={475}
          >
            <div className="modal-aprovacao">
              {tipoModalAprovacao === 'aprovacao' ? (
                <>
                  <div className="icon-aprovacao">
                    <IconAprovacao width={75} />
                  </div>
                  <p>Tem certeza que deseja aprovar a proposta?</p>
                </>
              ) : (
                <>
                  <div className="icon-negacao">
                    <IconRemoveOutline width={75} />
                  </div>
                  <p>Tem certeza que deseja negar a proposta?</p>
                </>
              )}

              <div className="modal-aprovacao-footer">
                <Button
                  variant="green"
                  className="btn btn-cancelar"
                  onClick={() => setModalAprovacaoVisible(false)}
                >
                  CANCELAR
                </Button>
                <Button
                  variant="green"
                  className="btn btn-aprovar"
                  onClick={() =>
                    handleAprovarNegarProposta(
                      tipoModalAprovacao === 'aprovacao' ? 11 : 10,
                    )
                  }
                >
                  SIM
                </Button>
              </div>
            </div>
          </ModalAntd>

          <ModalAntd
            visible={modalAguardeCadastrar}
            centered
            footer={null}
            closable={false}
            width={475}
          >
            <div className="modal-proposta-credito-aguarde">
              <p>
                Já recebemos sua proposta e estamos realizando os procedimentos
                iniciais.
                <br />
                Aguarde alguns segundos.
              </p>
              <Spinner />
            </div>
          </ModalAntd>
        </Overlay>
      </FormikProvider>
    </>
  );
}

export default PropostaCreditoForm;
