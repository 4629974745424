import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';

import { Field } from 'formik';
import { Form, Select } from 'formik-antd';
import Input from '../../../components/SimpleInput';

import InputPhone from '../../../components/InputPhone';
import InputMoney from 'react-number-format';
import validaPhone from '../../../utils/validaPhone';

import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import SelectSearch from '../../../components/SearchSelect';
import InputMask from 'react-text-mask';
import { numeroBeneficioMask } from '../../../utils/masks';
import { TipoCalculoVencimento } from '../../../utils/identificadoresFrontBack';
import TooltipComponent from '../../../components/tooltip';

const { Option } = Select;

function DadosProfissionaisForm({ formik, disabledFields }) {
  const {
    ocupacoes,
    profissoes,
    tempoEmpregoAtual,
    validationField,
    editableField,
    dadosOrgao,
    proposta
  } = useContext(PropostaCreditoContext);

  const { setFieldValue, values } = formik;

  const [acesso, setAcesso] = useState(false);
  const [showTooltipEmpregoAtual, setShowTooltipEmpregoAtual] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);
  const empregoAtualInputRef = useRef();

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));

  }, [checarPermissao]);

  const handlePis = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    setFieldValue('profissional.pisPasep', newValue);
  };

  const isOverflowing = () => {
    if (empregoAtualInputRef.current) {
      const width = empregoAtualInputRef.current.offsetWidth;
      const contentWidth = empregoAtualInputRef.current.scrollWidth;
      return contentWidth > width;
    }
  };

  useEffect(() => {
    const handleShowTooltip = () => {
      if (isOverflowing()) {
        setShowTooltipEmpregoAtual(true);
      } else {
        setShowTooltipEmpregoAtual(false);
      }
    }
    window.addEventListener('resize', handleShowTooltip);

    return () => window.removeEventListener('resize', handleShowTooltip);
  }, [formik?.values?.profissional]);

  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">
          Dados de Referências Profissionais do Cliente
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-4 col-lg-4 col-xl-3">
          <Form.Item
            name="profissional.empresa"
            label="Empresa ou Órgão"
            validate={(value) =>
              validationField(value, 'profissional.empresa', 'Empresa ou Órgão')
            }
            hasFeedback
          >
            <Input
              name="profissional.empresa"
              placeholder=""
              disabled={
                (acesso ? !editableField('profissional.empresa') : true)
                || disabledFields
              }
              value={formik?.values?.profissional?.empresa}
              onBlur={(e) => {
                setFieldValue('profissional.empresa', e.target.value);
              }}
            />
          </Form.Item>
        </div>

        <div className="col-md-4 col-md-4 col-lg-4 col-xl-3">
          <Form.Item
            name="profissional.ocupacaoId"
            label="Classificação Profissional"
            validate={(value) =>
              validationField(
                value,
                'profissional.ocupacaoId',
                'Classificação Profissional',
              )
            }
            hasFeedback
          >
            <Select
              name="profissional.ocupacaoId"
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownAlign={{
                overflow: { adjustY: 0 },
              }}
              disabled={
                (acesso ? !editableField('profissional.ocupacaoId') : true)
                || disabledFields
              }
              suffixIcon={<IconArrowSelect />}
            >
              {ocupacoes.length > 0 &&
                ocupacoes.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>

        <div className="col-md-5 col-lg-5 col-xl-3">
          <Form.Item
            name="profissional.profissaoId"
            label="Profissão"
            validate={(value) =>
              validationField(value, 'profissional.profissaoId', 'Profissão')
            }
            hasFeedback
          >
            <Field name="profissional.profissaoId">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) =>
                    setFieldValue('profissional.profissaoId', value)
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  disabled={
                    (acesso ? !editableField('profissional.profissaoId') : true)
                    || disabledFields
                  }
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  showSearch
                >
                  {profissoes.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name="profissional.tempoEmpregoAtual"
            label="Tempo no Emprego Atual"
            validate={(value) =>
              validationField(
                value,
                proposta?.operacao?.tipoCalculoVencimento ===
                TipoCalculoVencimento.CONSIGNADO_PRIVADO ? 'profissional.tempoEmpregoAtualCalculado' :
              'profissional.tempoEmpregoAtual',
                'Tempo no Emprego Atual',
              )
            }
            hasFeedback
          >
            <Field name="profissional.tempoEmpregoAtual">
              {({ field }) => {
                if (proposta?.operacao?.tipoCalculoVencimento ===
                  TipoCalculoVencimento.CONSIGNADO_PRIVADO
                ) {
                  return <TooltipComponent
                    title={showTooltipEmpregoAtual && formik?.values?.profissional?.tempoEmpregoAtualCalculado}
                    placement="bottom"
                    className="tooltip-info"
                    overlayClassName="tooltip-info"
                    color="#435361"
                  >
                    <div>
                      <input
                        ref={empregoAtualInputRef}
                        className={'ant-input'}
                        name="profissional.tempoEmpregoAtualCalculado"
                        disabled={true}
                        value={formik?.values?.profissional?.tempoEmpregoAtualCalculado}
                        onBlur={(e) => {
                          setFieldValue('profissional.tempoEmpregoAtualCalculado', e.target.value);
                        }}
                      />
                    </div>
                  </TooltipComponent>

                }

                return <SelectSearch
                  {...field}
                  onChange={(value) =>
                    setFieldValue('profissional.tempoEmpregoAtual', value)
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  disabled={
                    (acesso
                      ? !editableField('profissional.tempoEmpregoAtual')
                      : true)
                    || disabledFields
                  }
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  showSearch
                >
                  {tempoEmpregoAtual.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              }}
            </Field>
          </Form.Item>
        </div>

        <div className="col-md-4 col-lg-4 col-xl-3">
          <Form.Item
            name="profissional.telefoneRH"
            label="Telefone RH da Empresa"
            hasFeedback
          >
            <Field name="profissional.telefoneRH">
              {({ field }) => (
                <InputPhone
                  {...field}
                  validate={(value) => {
                    let msgError = '';

                    msgError = validationField(
                      value,
                      'profissional.telefoneRH',
                      'Telefone RH da Empresa',
                    );

                    if (value && !validaPhone(value)) {
                      msgError = 'Telefone inválido';
                    }

                    return msgError;
                  }}
                  placeholder="(00) 0000-0000"
                  disabled={
                    (acesso ? !editableField('profissional.telefoneRH') : true)
                    || disabledFields
                  }
                ></InputPhone>
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-md-4 col-lg-4 col-xl-2">
          <Form.Item
            name="profissional.pisPasep"
            label="PIS/PASEP"
            validate={(value) =>
              validationField(value, 'profissional.pisPasep', 'PIS/PASEP')
            }
            hasFeedback
          >
            <Input
              name="profissional.pisPasep"
              maxLength={20}
              disabled={
                (acesso ? !editableField('profissional.pisPasep') : true)
                || disabledFields
              }
              value={formik?.values?.profissional?.pisPasep}
              onBlur={(e) => {
                handlePis(e);
                setFieldValue('profissional.pisPasep', e.target.value);
              }}
            />
          </Form.Item>
        </div>

        <div className="col-md-4 col-lg-4 col-xl-2">
          <Form.Item
            name="profissional.renda"
            label="Renda"
            validate={(value) =>
              validationField(value, 'profissional.renda', 'Renda')
            }
            hasFeedback
          >
            <Field name="profissional.renda">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  maxLength={12}
                  style={{ textAlign: 'right' }}
                  disabled={
                    (acesso ? !editableField('profissional.renda') : true)
                    || disabledFields
                  }
                  onValueChange={(values) => {
                    setFieldValue('profissional.floatRenda', values.floatValue);
                  }}
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-md-4 col-lg-4 col-xl-2">
          <Form.Item
            name="profissional.outrasRendas"
            label="Outras Rendas"
            validate={(value) =>
              validationField(
                value,
                'profissional.outrasRendas',
                'Outras Rendas',
              )
            }
            hasFeedback
          >
            <Field name="profissional.outrasRendas">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  maxLength={12}
                  style={{ textAlign: 'right' }}
                  disabled={
                    (acesso ? !editableField('profissional.outrasRendas') : true)
                    || disabledFields
                  }
                  onValueChange={(values) => {
                    setFieldValue(
                      'profissional.floatOutrasRendas',
                      values.floatValue,
                    );
                  }}
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-md-4 col-lg-4 col-xl-3">
          <Form.Item
            name="profissional.tipoOutrasRendas"
            label="Tipo Outras Rendas"
            validate={(value) => {
              let msgError = null;
              if (!value && values?.profissional?.outrasRendas) {
                msgError = 'Obrigatório informar Tipos Outras Rendas';
              }
              return msgError;
            }}
            hasFeedback
          >
            <Input
              name="profissional.tipoOutrasRendas"
              disabled={
                (acesso ? !editableField('profissional.tipoOutrasRendas') : true)
                || disabledFields
              }
              value={formik?.values?.profissional?.tipoOutrasRendas}
              onBlur={(e) => {
                setFieldValue('profissional.tipoOutrasRendas', e.target.value);
              }}
            />
          </Form.Item>
        </div>

        {dadosOrgao?.length > 0 && acesso && proposta?.operacao?.tipoCalculoVencimento === 5 && (
          <div className="col-md-12 col-lg-12 col-xl-12">

            <div className="form-row">
              {
                (dadosOrgao).map(
                  (dadoAdicional, i) => (
                    <div className={
                      dadoAdicional?.tipo === 1
                        ? `col-md-4 col-lg-4 col-xl-9`
                        : `col-md-3 col-lg-3 col-xl-3`
                    } key={i}>
                      <Form.Item
                        name={`profissional.orgaoDados.${i}.valor`}
                        label={dadoAdicional?.nome}
                        hasFeedback
                      >
                        {dadoAdicional?.tipo === 1 ? (
                          <Field
                            name={`profissional.orgaoDados.${i}.valor`}
                            validate={(value) => {
                              if (value == null) return dadoAdicional?.mensagem;
                            }}
                          >
                            {({ field }) => (

                              <SelectSearch
                                {...field}
                                disabled={
                                  (acesso ? !editableField('profissional.orgaoDados') : true)
                                  || disabledFields
                                }
                                getPopupContainer={(trigger) => trigger.parentNode}
                                dropdownAlign={{
                                  overflow: { adjustY: 0 },
                                }}
                                className="select-dados-convenio"
                                optionFilterProp="children"
                                suffixIcon={<IconArrowSelect />}
                                placeholder="Selecione"
                                showSearch
                                onChange={(value) => {
                                  setFieldValue(
                                    `profissional.orgaoDados.${i}.valor`,
                                    value,
                                  );
                                  setFieldValue(
                                    `profissional.orgaoDados.${i}.orgaoDadosId`,
                                    dadoAdicional.id,
                                  );
                                  setFieldValue(
                                    `profissional.orgaoDados.${i}.id`,
                                    proposta?.profissional?.orgaoDados[i]?.id
                                  );
                                }}
                              >
                                {dadoAdicional.contexto?.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.nome}
                                  </Option>
                                ))}
                              </SelectSearch>
                            )}
                          </Field>
                        ) : (
                          <Field
                            name={`profissional.orgaoDados.${i}.valor`}
                            validate={(value) => {
                              if (!RegExp(dadoAdicional?.formato).test(value)) return dadoAdicional?.mensagem;
                            }}
                          >
                            {({ field }) => (


                              <InputMask
                                {...field}
                                disabled={
                                  (acesso ? !editableField('profissional.orgaoDados') : true)
                                  || disabledFields
                                }
                                placeholder=""
                                className="ant-input"
                                mask={numeroBeneficioMask}
                                defaultValue={proposta?.profissional?.orgaoDados[i]?.valor}
                                onChange={(value) => {
                                  setFieldValue(
                                    `profissional.orgaoDados.${i}.valor`,
                                    value.target.value,
                                  );
                                  setFieldValue(
                                    `profissional.orgaoDados.${i}.orgaoDadosId`,
                                    dadoAdicional.id,
                                  );
                                  setFieldValue(
                                    `profissional.orgaoDados.${i}.id`,
                                    proposta?.profissional?.orgaoDados[i]?.id
                                  );
                                }}
                              ></InputMask>
                            )}
                          </Field>
                        )}

                      </Form.Item>
                    </div>
                  ),
                )}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export { DadosProfissionaisForm };
